import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Grid,
} from "@material-ui/core";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { IconButton } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import Textarea from "@mui/joy/Textarea";
import CheckIcon from "@mui/icons-material/Check";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import ContentLoader from "react-content-loader";
import Stack from "@mui/material/Stack";
import "react-image-lightbox/style.css";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { IMS_FORM_IMG_PLACEHOLDER } from "../../../Constants";
import InfoIcon from "@mui/icons-material/Info";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";

interface ImsFormFieldsProps {
  loadForm: boolean;
  selectedCategory: any; // You can define the exact type if available
  successAlert: boolean;
  handleSaveSuccessClose: () => void;
  imsForm: any; // Define the type if available
  error: string | null;
  loading: boolean;
  ims_forms_data: any; // Define the exact type if available
  titleStyle?: {
    fontSize: string;
    marginBottom: string;
    color: string;
  };
  internaUseValues: React.CSSProperties;
  internaUseValues2: React.CSSProperties;
  evaluation: any; // Define the exact type if available
  nominationValue: string | number;
  handleNominationChange: (
    event: React.ChangeEvent<{ value: unknown }>
  ) => void;
  fieldValues: { [key: string]: any }; // Define more strictly if needed
  handleFieldChange: (
    groupId: string,
    componentId: string,
    value: any,
    datatype: string
  ) => void;
  newIMSData: any; // Define the type if available
}

const ImsFormFields: React.FC<ImsFormFieldsProps> = ({
  loadForm,
  selectedCategory,
  successAlert,
  handleSaveSuccessClose,
  imsForm,
  error,
  loading,
  ims_forms_data,
  titleStyle,
  internaUseValues,
  internaUseValues2,
  evaluation,
  nominationValue,
  handleNominationChange,
  fieldValues,
  handleFieldChange,
  newIMSData,
}) => {
  const [lightboxImage, setLightboxImage] = useState<string | null>(null);
  const [lightboxOpen, setLightboxOpen] = useState<boolean>(false);

  useEffect(() => {
    console.log("Check Check Data", selectedCategory);
    
  }, [ims_forms_data, selectedCategory])
  

  if (!loadForm && selectedCategory === null) {
    return (
      <div
        style={{
          padding: "20px",
          border: "1px solid #ccc",
          borderRadius: "5px",
          background: "#f9f9f9",
          maxHeight: "95%",
          overflowY: "auto",
          marginLeft: "20px",
          marginRight: "20px",
        }}
      >
        <div>
          <Snackbar
            open={successAlert}
            autoHideDuration={6000}
            onClose={handleSaveSuccessClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Alert
              onClose={handleSaveSuccessClose}
              severity="success"
              variant="filled"
              sx={{ width: "100%" }}
            >
              IMS form is saved successfully!
            </Alert>
          </Snackbar>
        </div>

        <h2>LOAD FORM FOR PREVIEW</h2>
      </div>
    );
  } else if (
    !imsForm?.item?.ims_forms_data?.id ||
    Object.keys(imsForm?.item?.ims_forms_data).length === 0
  ) {
    return (
      <div
        style={{
          padding: "20px",
          border: "1px solid #ccc",
          borderRadius: "5px",
          background: "#f9f9f9",
          maxHeight: "95%",
          overflowY: "auto",
          marginLeft: "20px",
          marginRight: "20px",
        }}
      >
        <div>
          <Snackbar
            open={successAlert}
            autoHideDuration={6000}
            onClose={handleSaveSuccessClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Alert
              onClose={handleSaveSuccessClose}
              severity="success"
              variant="filled"
              sx={{ width: "100%" }}
            >
              IMS form is saved successfully!
            </Alert>
          </Snackbar>
        </div>
        {loading ? (
          <ContentLoader
            speed={2}
            width={400}
            height={150}
            viewBox="0 0 400 150"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="35" y="10" rx="5" ry="5" width="150" height="10" />
            <rect x="35" y="45" rx="5" ry="5" width="150" height="10" />
            <rect x="35" y="80" rx="5" ry="5" width="150" height="10" />
            <rect x="35" y="115" rx="5" ry="5" width="150" height="10" />
            <rect x="3" y="5" rx="4" ry="4" width="20" height="20" />
            <rect x="3" y="40" rx="4" ry="4" width="20" height="20" />
            <rect x="3" y="75" rx="4" ry="4" width="20" height="20" />
            <rect x="3" y="110" rx="4" ry="4" width="20" height="20" />
          </ContentLoader>
        ) : error ? (
          <div>Error: {error}</div>
        ) : (
          <div>
            <div key={ims_forms_data?.id} style={{ marginBottom: "20px" }}>
              <div>
                <Typography style={titleStyle}>IMS Form Category</Typography>
                <h2>{ims_forms_data?.category}</h2>
                <div
                  style={{
                    margin: "20px 0",
                  }}
                >
                  <Divider />
                </div>
                <Grid container spacing={3} alignItems="center">
                  <Grid container item xs={6} direction="column">
                    <Grid item>
                      <Typography style={titleStyle}>Fee Code</Typography>
                      <h4 style={internaUseValues}>
                        {evaluation?.evaluation?.fee_code || "--------"}
                      </h4>
                    </Grid>
                    <Grid item>
                      <Typography style={titleStyle}>Location</Typography>
                      <h4 style={internaUseValues}>
                        {imsForm?.item?.location || "--------"}
                      </h4>
                    </Grid>
                    <Grid item>
                      <Typography style={titleStyle}>DC Service</Typography>
                      <h4 style={internaUseValues}>
                        {evaluation?.evaluation?.primary_contact || "--------"}
                      </h4>
                    </Grid>
                    <Grid item>
                      <Typography style={titleStyle}>
                        Copart Lot Number
                      </Typography>
                      <h4 style={internaUseValues}>
                        {imsForm?.lot_number || "--------"}
                      </h4>
                    </Grid>
                  </Grid>
                  <Grid container item xs={6} direction="column">
                    <Grid item>
                      <Typography style={titleStyle}>Form Status</Typography>
                      <div style={internaUseValues2}>
                        {imsForm?.item?.form_sub ? (
                          <Chip
                            color="success"
                            icon={<CheckIcon />}
                            label="Form Submited to IMS"
                            size="small"
                          />
                        ) : (
                          <Chip
                            icon={<InfoIcon />}
                            label="Form is not Submited"
                            size="small"
                          />
                        )}
                      </div>
                    </Grid>
                    <Grid item>
                      <Typography style={titleStyle}>Opportunity</Typography>
                      <h4 style={internaUseValues}>
                        {imsForm?.item?.ims_forms_data?.opportunity_id ||
                          "--------"}
                      </h4>
                    </Grid>
                    <Grid item>
                      <Typography style={titleStyle}>Job Code</Typography>
                      <h4 style={internaUseValues}>
                        {imsForm?.jobcode || "--------"}
                      </h4>
                    </Grid>
                    <Grid item>
                      <Typography style={titleStyle}>Nomination</Typography>
                      <FormControl
                        style={{ position: "relative", width: "100%" }}
                      >
                        <Select
                          labelId="nomination-label"
                          id="nomination-select"
                          value={nominationValue}
                          onChange={handleNominationChange}
                          style={{
                            width: "70%",
                            display: "flex",
                            alignItems: "center",
                          }}
                          MenuProps={{
                            PaperProps: { style: { maxHeight: 200 } },
                          }}
                        >
                          {[...Array(11)].map((_, index) => (
                            <MenuItem key={index} value={index}>
                              {index}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </div>

              <div style={{ margin: "12px 0" }}></div>

              <div style={{ maxHeight: "650px", overflowY: "auto" }}>
                {ims_forms_data?.groups?.map(
                  (group: {
                    id: React.Key | null | undefined;
                    display_name: any;
                    title: any;
                    components: any[];
                  }) => (
                    <Accordion
                      key={group.id}
                      style={{
                        marginBottom: "10px",
                        background: "#fff",
                        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ArrowDownwardIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography style={{ color: "#4f2682" }}>
                          <h3>{group?.display_name || group.title}</h3>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails
                        style={{ padding: "10px", background: "#f9f9f9" }}
                      >
                        <form>
                          {group?.components?.map(
                            (component: {
                              id: React.Key | null | undefined;
                              control_type: string;
                              datatype: string;
                              label_display: any;
                              label: any;
                              prefix:
                                | string
                                | number
                                | boolean
                                | React.ReactElement<
                                    any,
                                    string | React.JSXElementConstructor<any>
                                  >
                                | React.ReactFragment
                                | React.ReactPortal
                                | null
                                | undefined;
                              suffix:
                                | string
                                | number
                                | boolean
                                | React.ReactElement<
                                    any,
                                    string | React.JSXElementConstructor<any>
                                  >
                                | React.ReactFragment
                                | React.ReactPortal
                                | null
                                | undefined;
                              component_response: { photo: any };
                              values: any[];
                              component_form: string | any[];
                              helper_text:
                                | string
                                | number
                                | boolean
                                | React.ReactElement<
                                    any,
                                    string | React.JSXElementConstructor<any>
                                  >
                                | React.ReactFragment
                                | React.ReactPortal
                                | null
                                | undefined;
                            }) => (
                              <div
                                key={component.id}
                                style={{
                                  marginBottom: "25px",
                                  width: "100%",
                                  marginLeft: "20px",
                                }}
                              >
                                {component.control_type === "checkbox" ? (
                                  <>
                                    <input
                                      type="checkbox"
                                      style={{ marginRight: "5px" }}
                                      checked={
                                        component.id != null &&
                                        (fieldValues[component.id] === true ||
                                          fieldValues[component.id] === "true")
                                      }
                                      onChange={(event) =>
                                        component.id != null &&
                                        group.id != null &&
                                        handleFieldChange(
                                          String(group.id),
                                          String(component.id),
                                          event.target.checked,
                                          component.datatype
                                        )
                                      }
                                    />
                                  </>
                                ) : null}
                                {component.control_type !== "textfield" &&
                                  component.control_type !== "checkboxgroup" &&
                                  component.control_type !== "heading" &&
                                  component.control_type !== "subheading" && (
                                    <label style={{ marginBottom: "12px" }}>
                                      {component.label_display ||
                                        component.label}
                                    </label>
                                  )}
                                {component.control_type === "textfield" ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      maxWidth: "80%",
                                    }}
                                  >
                                    {component.prefix && (
                                      <span style={{ marginRight: "5px" }}>
                                        {component.prefix}
                                      </span>
                                    )}
                                    <TextField
                                      id={
                                        component.id != null
                                          ? String(component.id)
                                          : undefined
                                      }
                                      variant="standard"
                                      sx={{ width: "300px" }}
                                      label={
                                        component.label_display ||
                                        component.label
                                      }
                                      // Check if the value is an object and then access the `value` field
                                      value={
                                        component.id != null
                                          ? typeof fieldValues[component.id] ===
                                            "object"
                                            ? fieldValues[component.id]
                                                ?.value || ""
                                            : fieldValues[component.id] || ""
                                          : ""
                                      }
                                      onChange={(event) => {
                                        // Ensure both group.id and component.id are strings
                                        if (group.id && component.id) {
                                          handleFieldChange(
                                            String(group.id),
                                            String(component.id),
                                            event.target.value,
                                            component.datatype
                                          );
                                        }
                                      }}
                                    />

                                    {component.suffix && (
                                      <span style={{ marginLeft: "5px" }}>
                                        {component.suffix}
                                      </span>
                                    )}
                                  </div>
                                ) : component.control_type === "numberfield" ? (
                                  <input
                                    type="number"
                                    style={{
                                      width: "100%",
                                      padding: "5px",
                                      border: "1px solid #ccc",
                                      borderRadius: "3px",
                                      margin: "10px 0",
                                    }}
                                    value={
                                      typeof component.component_response ===
                                        "number" ||
                                      typeof component.component_response ===
                                        "string"
                                        ? component.component_response
                                        : ""
                                    }
                                    onChange={(event) => {
                                      if (
                                        group.id != null &&
                                        component.id != null
                                      ) {
                                        handleFieldChange(
                                          String(group.id),
                                          String(component.id),
                                          event.target.value,
                                          component.datatype
                                        ); // Casting to string
                                      }
                                    }}
                                  />
                                ) : component.control_type === "textarea" ? (
                                  // <textarea style={{ width: '100%', padding: '5px', border: '1px solid #ccc', borderRadius: '3px', margin: '10px 0' }} />
                                  <Textarea
                                    id={
                                      component.id != null
                                        ? String(component.id)
                                        : undefined
                                    }
                                    minRows={2}
                                    size="md"
                                    style={{
                                      padding: "5px",
                                      border: "1px solid #ccc",
                                      borderRadius: "3px",
                                      maxWidth: "300px",
                                    }}
                                    value={
                                      component.id != null
                                        ? fieldValues[String(component.id)] ||
                                          ""
                                        : ""
                                    }
                                    onChange={(event) => {
                                      if (component.id != null) {
                                        handleFieldChange(
                                          String(group.id),
                                          String(component.id),
                                          event.target.value,
                                          component.datatype
                                        ); // Ensure valid id
                                      }
                                    }}
                                  />
                                ) : component.control_type === "select" ? (
                                  <select
                                    style={{
                                      width: "100%",
                                      padding: "5px",
                                      border: "1px solid #ccc",
                                      borderRadius: "3px",
                                      margin: "10px 0",
                                    }}
                                  >
                                    {component.values?.map(
                                      (option: {
                                        id: React.Key | null | undefined;
                                        component_response: any;
                                        value:
                                          | string
                                          | number
                                          | boolean
                                          | React.ReactElement<
                                              any,
                                              | string
                                              | React.JSXElementConstructor<any>
                                            >
                                          | React.ReactFragment
                                          | React.ReactPortal
                                          | null
                                          | undefined;
                                      }) => (
                                        <option
                                          key={option.id}
                                          value={
                                            option.component_response || ""
                                          }
                                          onChange={(event) =>
                                            handleFieldChange(
                                              String(group.id), // Ensure group.id is a string
                                              String(component.id), // Ensure component.id is a string
                                              (
                                                event.target as HTMLSelectElement
                                              ).value, // Pass the selected value
                                              component.datatype // Pass the datatype as the 4th argument
                                            )
                                          }
                                        >
                                          {option.value}
                                        </option>
                                      )
                                    )}
                                  </select>
                                ) : component.control_type ===
                                  "checkboxgroup" ? (
                                  <div
                                    style={{
                                      marginTop: "30px",
                                      marginBottom: "30px",
                                    }}
                                  >
                                    <div style={{ marginBottom: "5px" }}>
                                      <label style={{ margin: "0" }}>
                                        {component.label_display ||
                                          component.label}
                                      </label>
                                    </div>
                                    <Grid container alignItems="center">
                                      {component.values?.map(
                                        (option: {
                                          id: React.Key | null | undefined;
                                          value:
                                            | string
                                            | number
                                            | boolean
                                            | React.ReactElement<
                                                any,
                                                | string
                                                | React.JSXElementConstructor<any>
                                              >
                                            | React.ReactFragment
                                            | null
                                            | undefined;
                                        }) => (
                                          <div key={option.id}>
                                            <Grid container alignItems="center">
                                              <input
                                                type="checkbox"
                                                id={
                                                  option.id != null
                                                    ? String(option.id)
                                                    : undefined
                                                }
                                                // Ensure the value is checked when true or "true"
                                                checked={
                                                  (option.id != null &&
                                                    fieldValues[
                                                      String(option.id)
                                                    ] === true) ||
                                                  fieldValues[
                                                    String(option.id)
                                                  ] === "true"
                                                }
                                                style={{
                                                  color: "#4f2682",
                                                  marginLeft: "5px",
                                                }}
                                                onChange={(event) =>
                                                  option.id != null &&
                                                  handleFieldChange(
                                                    String(group.id), // Ensure group.id is a string
                                                    String(option.id), // Ensure option.id is a string
                                                    event.target.checked, // Pass the checked value
                                                    component.datatype // Pass the component's datatype
                                                  )
                                                }
                                              />
                                              <label
                                                htmlFor={
                                                  typeof option.value ===
                                                  "string"
                                                    ? option.value
                                                    : undefined
                                                }
                                                style={{
                                                  color: "#4f2682",
                                                  marginLeft: "5px",
                                                  marginBottom: "0",
                                                }}
                                              >
                                                {option.value}
                                              </label>
                                            </Grid>
                                          </div>
                                        )
                                      )}
                                    </Grid>
                                  </div>
                                ) : component.control_type === "subheading" ? (
                                  <h4>
                                    {console.log(
                                      "Photo URL 1:",
                                      component.component_response?.photo
                                    )}
                                    {component.label_display || component.label}
                                  </h4>
                                ) : null}

                                {component.component_form &&
                                  component.component_form.length > 0 && (
                                    <IconButton
                                      aria-label="Help"
                                      onClick={() => {
                                        window.open(
                                          component.component_form[0].help_link,
                                          "_blank"
                                        );
                                      }}
                                    >
                                      <OpenInNewIcon
                                        style={{
                                          fontSize: "15px",
                                          color: "#4f2682",
                                        }}
                                      />
                                    </IconButton>
                                  )}

                                {/* Render helper text */}
                                {component.helper_text && (
                                  <div style={{ width: "70%" }}>
                                    <Typography
                                      variant="caption"
                                      style={{
                                        fontSize: "small",
                                        color: "#666",
                                      }}
                                    >
                                      {component.helper_text}
                                    </Typography>
                                  </div>
                                )}
                              </div>
                            )
                          )}
                        </form>
                      </AccordionDetails>
                    </Accordion>
                  )
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  } else if (
    imsForm?.item?.ims_forms_data?.id ||
    Object.keys(imsForm?.item?.ims_forms_data).length !== 0
  ) {
    return (
      <div
        style={{
          padding: "20px",
          border: "1px solid #ccc",
          borderRadius: "5px",
          background: "#f9f9f9",
          maxHeight: "95%",
          overflowY: "auto",
          marginLeft: "20px",
          marginRight: "20px",
        }}
      >
        <div>
          <Snackbar
            open={successAlert}
            autoHideDuration={6000}
            onClose={handleSaveSuccessClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Alert
              onClose={handleSaveSuccessClose}
              severity="success"
              variant="filled"
              sx={{ width: "100%" }}
            >
              IMS form is saved successfully!
            </Alert>
          </Snackbar>
        </div>

        {loading ? (
          <ContentLoader
            speed={2}
            width={400}
            height={150}
            viewBox="0 0 400 150"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="35" y="10" rx="5" ry="5" width="150" height="10" />
            <rect x="35" y="45" rx="5" ry="5" width="150" height="10" />
            <rect x="35" y="80" rx="5" ry="5" width="150" height="10" />
            <rect x="35" y="115" rx="5" ry="5" width="150" height="10" />
            <rect x="3" y="5" rx="4" ry="4" width="20" height="20" />
            <rect x="3" y="40" rx="4" ry="4" width="20" height="20" />
            <rect x="3" y="75" rx="4" ry="4" width="20" height="20" />
            <rect x="3" y="110" rx="4" ry="4" width="20" height="20" />
          </ContentLoader>
        ) : error ? (
          <div>Error: {error}</div>
        ) : (
          <div>
            <div key={newIMSData?.id} style={{ marginBottom: "20px" }}>
              <div>
                <Typography style={titleStyle}>IMS Form Category</Typography>
                <h2>{newIMSData?.category}</h2>
                <div
                  style={{
                    backgroundColor: "#8f8c8c",
                    height: "1px",
                    margin: "20px 0",
                  }}
                ></div>
                <Grid container spacing={3} alignItems="center">
                  <Grid container item xs={6} direction="column">
                    <Grid item>
                      <Typography style={titleStyle}>Fee Code</Typography>
                      <h4 style={internaUseValues}>
                        {evaluation?.evaluation?.fee_code || "--------"}
                      </h4>
                    </Grid>
                    <Grid item>
                      <Typography style={titleStyle}>Location</Typography>
                      <h4 style={internaUseValues}>
                        {imsForm?.item?.location || "--------"}
                      </h4>
                    </Grid>
                    <Grid item>
                      <Typography style={titleStyle}>DC Service</Typography>
                      <h4 style={internaUseValues}>
                        {evaluation?.evaluation?.primary_contact || "--------"}
                      </h4>
                    </Grid>
                    <Grid item>
                      <Typography style={titleStyle}>
                        Copart Lot Number
                      </Typography>
                      <h4 style={internaUseValues}>
                        {imsForm?.lot_number || "--------"}
                      </h4>
                    </Grid>
                  </Grid>
                  <Grid container item xs={6} direction="column">
                    <Grid item>
                      <Typography style={titleStyle}>Form Status</Typography>
                      <h4 style={internaUseValues}>Sent to IMS</h4>
                    </Grid>
                    <Grid item>
                      <Typography style={titleStyle}>Opportunity</Typography>
                      <h4 style={internaUseValues}>
                        {imsForm?.item?.ims_forms_data?.opportunity_id ||
                          "--------"}
                      </h4>
                    </Grid>
                    <Grid item>
                      <Typography style={titleStyle}>Job Code</Typography>
                      <h4 style={internaUseValues}>
                        {imsForm?.jobcode || "--------"}
                      </h4>
                    </Grid>
                    <Grid item>
                      <Typography style={titleStyle}>Nomination</Typography>
                      <FormControl
                        style={{ position: "relative", width: "100%" }}
                      >
                        <Select
                          labelId="nomination-label"
                          id="nomination-select"
                          value={nominationValue}
                          onChange={handleNominationChange}
                          style={{
                            width: "70%",
                            display: "flex",
                            alignItems: "center",
                          }}
                          MenuProps={{
                            PaperProps: { style: { maxHeight: 200 } },
                          }}
                        >
                          {[...Array(11)].map((_, index) => (
                            <MenuItem key={index} value={index}>
                              {index}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
              <div style={{ margin: "12px 0" }}></div>

              <div style={{ maxHeight: "650px", overflowY: "auto" }}>
                {newIMSData?.groups?.map(
                  (group: {
                    id: React.Key | null | undefined;
                    display_name: any;
                    title: any;
                    components: any[];
                  }) => (
                    <Accordion
                      key={group.id}
                      style={{
                        marginBottom: "10px",
                        background: "#fff",
                        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ArrowDownwardIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography style={{ color: "#4f2682" }}>
                          <h3>{group?.display_name || group.title}</h3>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails
                        style={{ padding: "10px", background: "#f9f9f9" }}
                      >
                        <form>
                          {group?.components?.map(
                            (component: {
                              id: React.Key | null | undefined;
                              control_type: string;
                              datatype: string;
                              label_display: any;
                              label: any;
                              photo_capture: number;
                              prefix:
                                | string
                                | number
                                | boolean
                                | React.ReactElement<
                                    any,
                                    string | React.JSXElementConstructor<any>
                                  >
                                | React.ReactFragment
                                | null
                                | undefined;
                              suffix:
                                | string
                                | number
                                | boolean
                                | React.ReactElement<
                                    any,
                                    string | React.JSXElementConstructor<any>
                                  >
                                | React.ReactFragment
                                | null
                                | undefined;
                              component_response: {
                                photo: React.SetStateAction<string | null>;
                              };
                              values: any[];
                              component_form: string | any[];
                              helper_text:
                                | string
                                | number
                                | boolean
                                | React.ReactElement<
                                    any,
                                    string | React.JSXElementConstructor<any>
                                  >
                                | React.ReactFragment
                                | React.ReactPortal
                                | null
                                | undefined;
                            }) => (
                              <div
                                key={component.id}
                                style={{
                                  marginBottom: "25px",
                                  width: "100%",
                                  marginLeft: "20px",
                                }}
                              >
                                {component.control_type === "checkbox" ? (
                                  <input
                                    type="checkbox"
                                    style={{ marginRight: "5px" }}
                                    checked={
                                      fieldValues[
                                        component.id != null
                                          ? String(component.id)
                                          : ""
                                      ] || false
                                    }
                                    onChange={(event) =>
                                      handleFieldChange(
                                        group.id != null
                                          ? String(group.id)
                                          : "",
                                        String(component.id),
                                        event.target.checked,
                                        component.datatype
                                      )
                                    }
                                  />
                                ) : null}
                                {component.control_type !== "textfield" &&
                                  component.control_type !== "checkboxgroup" &&
                                  component.control_type !== "heading" &&
                                  component.control_type !== "subheading" && (
                                    <label style={{ marginBottom: "12px" }}>
                                      {component.label_display ||
                                        component.label}
                                    </label>
                                  )}
                                {component.control_type === "textfield" ? (
                                  component.photo_capture === 1 ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        maxWidth: "80%",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      {component.prefix && (
                                        <span style={{ marginRight: "5px" }}>
                                          {component.prefix}
                                        </span>
                                      )}
                                      <TextField
                                        id={
                                          component.id != null
                                            ? String(component.id)
                                            : undefined
                                        }
                                        variant="standard"
                                        sx={{ width: "300px" }}
                                        label={
                                          component.label_display ||
                                          component.label
                                        }
                                        // Check if the value is an object and then access the `value` field
                                        value={
                                          component.id != null
                                            ? typeof fieldValues[
                                                component.id
                                              ] === "object"
                                              ? fieldValues[component.id]
                                                  ?.value || ""
                                              : fieldValues[component.id] || ""
                                            : ""
                                        }
                                        onChange={(event) => {
                                          // Ensure both group.id and component.id are strings
                                          if (group.id && component.id) {
                                            handleFieldChange(
                                              String(group.id),
                                              String(component.id),
                                              event.target.value,
                                              component.datatype
                                            );
                                          }
                                        }}
                                      />

                                      {component.suffix && (
                                        <span
                                          style={{
                                            marginLeft: "5px",
                                            fontSize: "2px ",
                                          }}
                                        >
                                          {component.suffix}
                                        </span>
                                      )}
                                      <Stack direction="row" spacing={2}>
                                        <img
                                          src={
                                            typeof component.component_response
                                              ?.photo === "string"
                                              ? component.component_response
                                                  .photo
                                              : IMS_FORM_IMG_PLACEHOLDER
                                          }
                                          alt="Thumbnail"
                                          style={{
                                            width: 56,
                                            height: 56,
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            setLightboxImage(
                                              component.component_response
                                                ?.photo
                                            );
                                            setLightboxOpen(true);
                                          }}
                                        />
                                        {lightboxOpen && lightboxImage && (
                                          <Dialog
                                            open={lightboxOpen}
                                            onClose={() =>
                                              setLightboxOpen(false)
                                            }
                                            maxWidth="lg"
                                            fullWidth
                                            style={{ margin: 0 }}
                                          >
                                            <DialogTitle>
                                              <IconButton
                                                edge="end"
                                                color="inherit"
                                                onClick={() =>
                                                  setLightboxOpen(false)
                                                }
                                                aria-label="close"
                                              >
                                                <CloseIcon />
                                              </IconButton>
                                            </DialogTitle>
                                            <DialogContent
                                              style={{ padding: 0 }}
                                            >
                                              <img
                                                src={lightboxImage}
                                                alt="Full size"
                                                style={{
                                                  width: "100%",
                                                  height: "auto",
                                                }}
                                              />
                                            </DialogContent>
                                          </Dialog>
                                        )}
                                      </Stack>
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        maxWidth: "80%",
                                      }}
                                    >
                                      {component.prefix && (
                                        <span style={{ marginRight: "5px" }}>
                                          {component.prefix}
                                        </span>
                                      )}
                                      <TextField
                                        id={
                                          component.id != null
                                            ? String(component.id)
                                            : undefined
                                        }
                                        variant="standard"
                                        sx={{ width: "300px" }}
                                        label={
                                          component.label_display ||
                                          component.label
                                        }
                                        // Check if the value is an object and then access the `value` field
                                        value={
                                          component.id != null
                                            ? typeof fieldValues[
                                                component.id
                                              ] === "object"
                                              ? fieldValues[component.id]
                                                  ?.value || ""
                                              : fieldValues[component.id] || ""
                                            : ""
                                        }
                                        onChange={(event) => {
                                          // Ensure both group.id and component.id are strings
                                          if (group.id && component.id) {
                                            handleFieldChange(
                                              String(group.id),
                                              String(component.id),
                                              event.target.value,
                                              component.datatype
                                            );
                                          }
                                        }}
                                      />

                                      {component.suffix && (
                                        <span style={{ marginLeft: "5px" }}>
                                          {component.suffix}
                                        </span>
                                      )}
                                    </div>
                                  )
                                ) : component.control_type === "numberfield" ? (
                                  <input
                                    type="number"
                                    style={{
                                      width: "100%",
                                      padding: "5px",
                                      border: "1px solid #ccc",
                                      borderRadius: "3px",
                                      margin: "10px 0",
                                    }}
                                    value={
                                      typeof component.component_response ===
                                        "string" ||
                                      typeof component.component_response ===
                                        "number"
                                        ? component.component_response
                                        : ""
                                    }
                                    onChange={(event) =>
                                      handleFieldChange(
                                        group.id != null
                                          ? String(group.id)
                                          : "",
                                        String(component.id),
                                        event.target.checked,
                                        component.datatype
                                      )
                                    }
                                  />
                                ) : component.control_type === "textarea" ? (
                                  <Textarea
                                    id={
                                      component.id != null
                                        ? String(component.id)
                                        : undefined
                                    }
                                    minRows={2}
                                    size="md"
                                    style={{
                                      padding: "5px",
                                      border: "1px solid #ccc",
                                      borderRadius: "3px",
                                      maxWidth: "300px",
                                    }}
                                    value={
                                      component.id != null
                                        ? fieldValues[String(component.id)] ||
                                          ""
                                        : ""
                                    }
                                    onChange={(event) => {
                                      if (component.id != null) {
                                        handleFieldChange(
                                          String(group.id),
                                          String(component.id),
                                          event.target.value,
                                          component.datatype
                                        ); // Ensure valid id
                                      }
                                    }}
                                  />
                                ) : component.control_type === "select" ? (
                                  <select
                                    style={{
                                      width: "100%",
                                      padding: "5px",
                                      border: "1px solid #ccc",
                                      borderRadius: "3px",
                                      margin: "10px 0",
                                    }}
                                    onChange={(event) => {
                                      const target =
                                        event.target as HTMLSelectElement; // Type assertion
                                      if (group.id != null) {
                                        // Check if group.id is not null or undefined
                                        handleFieldChange(
                                          String(group.id), // Convert to string
                                          String(component.id),
                                          target.value, // Use the asserted type here
                                          component.datatype
                                        );
                                      }
                                    }}
                                  >
                                    {component.values?.map((option) => (
                                      <option
                                        key={option.id}
                                        value={option.component_response || ""}
                                      >
                                        {option.value}
                                      </option>
                                    ))}
                                  </select>
                                ) : component.control_type ===
                                  "checkboxgroup" ? (
                                  <div
                                    style={{
                                      marginTop: "30px",
                                      marginBottom: "30px",
                                    }}
                                  >
                                    <div style={{ marginBottom: "5px" }}>
                                      <label style={{ margin: "0" }}>
                                        {component.label_display ||
                                          component.label}
                                      </label>
                                    </div>
                                    <Grid container alignItems="center">
                                      {component.values?.map(
                                        (option: {
                                          id: React.Key | null | undefined;
                                          value:
                                            | string
                                            | number
                                            | boolean
                                            | React.ReactElement<
                                                any,
                                                | string
                                                | React.JSXElementConstructor<any>
                                              >
                                            | React.ReactFragment
                                            | null
                                            | undefined;
                                        }) => (
                                          <div key={option.id}>
                                            <Grid container alignItems="center">
                                              {/* <input type="checkbox" id={option.id}  checked={fieldValues[option.id] || false}  style={{ color: '#4f2682', marginLeft: '5px' }} value={fieldValues[option.id] || 'true'}  onChange={(event) => handleFieldChange(group.id, option.id, event.target.checked ? 'true' : 'false')}/> */}
                                              <input
                                                type="checkbox"
                                                id={
                                                  option.id != null
                                                    ? String(option.id)
                                                    : undefined
                                                }
                                                checked={
                                                  option.id != null
                                                    ? fieldValues[option.id] ===
                                                        true ||
                                                      fieldValues[option.id] ===
                                                        "true"
                                                    : false
                                                }
                                                // Use fieldValues directly
                                                style={{
                                                  color: "#4f2682",
                                                  marginLeft: "5px",
                                                }}
                                                onChange={(event) => {
                                                  if (group.id != null) {
                                                    // Check if group.id is not null or undefined
                                                    handleFieldChange(
                                                      String(group.id), // Convert to string
                                                      option.id != null
                                                        ? String(option.id)
                                                        : "", // Convert option.id to string, provide a default if null
                                                      event.target.checked, // Checkbox value
                                                      component.datatype // Pass datatype here
                                                    );
                                                  }
                                                }}
                                              />
                                              <label
                                                htmlFor={
                                                  typeof option.value ===
                                                  "string"
                                                    ? option.value
                                                    : ""
                                                } // Ensure option.value is a string
                                                style={{
                                                  color: "#4f2682",
                                                  marginLeft: "5px",
                                                  marginBottom: "0",
                                                }}
                                              >
                                                {option.value}
                                              </label>
                                            </Grid>
                                          </div>
                                        )
                                      )}
                                    </Grid>
                                  </div>
                                ) : component.control_type === "heading" ? (
                                  <h3 style={{ color: "#4f2682" }}>
                                    {component.label_display || component.label}
                                  </h3>
                                ) : component.control_type === "subheading" ? (
                                  component.photo_capture === 1 ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                      }}
                                    >
                                      <h4>
                                        {component.label_display ||
                                          component.label}
                                      </h4>
                                      <Stack direction="row" spacing={2}>
                                        <img
                                          src={
                                            typeof component.component_response
                                              ?.photo === "string" &&
                                            component.component_response.photo
                                              .length > 0
                                              ? component.component_response
                                                  .photo
                                              : IMS_FORM_IMG_PLACEHOLDER
                                          }
                                          alt="Thumbnail"
                                          style={{
                                            width: 56,
                                            height: 56,
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            setLightboxImage(
                                              component.component_response
                                                ?.photo
                                            );
                                            setLightboxOpen(true);
                                          }}
                                        />
                                        {lightboxOpen && lightboxImage && (
                                          <Dialog
                                            open={lightboxOpen}
                                            onClose={() =>
                                              setLightboxOpen(false)
                                            }
                                            maxWidth="lg"
                                            fullWidth
                                            style={{ margin: 0 }}
                                          >
                                            <DialogTitle>
                                              <IconButton
                                                edge="end"
                                                color="inherit"
                                                onClick={() =>
                                                  setLightboxOpen(false)
                                                }
                                                aria-label="close"
                                              >
                                                <CloseIcon />
                                              </IconButton>
                                            </DialogTitle>
                                            <DialogContent
                                              style={{ padding: 0 }}
                                            >
                                              <img
                                                src={lightboxImage}
                                                alt="Full size"
                                                style={{
                                                  width: "100%",
                                                  height: "auto",
                                                }}
                                              />
                                            </DialogContent>
                                          </Dialog>
                                        )}
                                      </Stack>
                                    </div>
                                  ) : (
                                    <h4>
                                      {component.label_display ||
                                        component.label}
                                    </h4>
                                  )
                                ) : null}

                                {component.component_form &&
                                  component.component_form.length > 0 && (
                                    <IconButton
                                      aria-label="Help"
                                      onClick={() => {
                                        window.open(
                                          component.component_form[0].help_link,
                                          "_blank"
                                        );
                                      }}
                                    >
                                      <OpenInNewIcon
                                        style={{
                                          fontSize: "15px",
                                          color: "#4f2682",
                                        }}
                                      />
                                    </IconButton>
                                  )}

                                {/* Render helper text */}
                                {component.helper_text && (
                                  <div style={{ width: "70%" }}>
                                    <Typography
                                      variant="caption"
                                      style={{
                                        fontSize: "small",
                                        color: "#666",
                                      }}
                                    >
                                      {component.helper_text}
                                    </Typography>
                                  </div>
                                )}
                              </div>
                            )
                          )}
                        </form>
                      </AccordionDetails>
                    </Accordion>
                  )
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  } else {
    return null;
  }
};

export default ImsFormFields;
