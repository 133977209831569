import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { sortBy, find } from "lodash";
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import { useCallback } from "react";
import { GET_ORGANIZATIONS } from "../../../Constants";
import DisplayError from "../../../fhg/components/DisplayError";
import FHGTypography from "../../../fhg/components/Typography";
import { requestForServer } from "../../../Utils/ServerUtil";
//import { FTDropdown } from "../../dropdowns";

const useStyles = makeStyles(
  (theme) => ({
    formControl: {
      color: "inherit",
      backgroundColor: "transparent",
      "&:focus": {
        backgroundColor: "transparent",
      },
    },
    titleStyle: {
      fontSize: "14px !important",
      fontWeight: 500,
      lineHeight: "17px",
      textAlign: "center",
      color: theme.palette.secondary.contrastText,
      textTransform: "none",
      whiteSpace: "nowrap",
      [theme.breakpoints.down("xs")]: {
        whiteSpace: "wrap",
      },
    },
    selectStyle: {
      color: theme.palette.environment.light.level3.base, //'#E6E3DC',
      "& svg": {
        fill: theme.palette.environment.light.level3.base, //'#E6E3DC'
      },
    },
    menuText: {
      display: "inline",
      whiteSpace: "normal",
    },
  }),
  "OrganizationStyles"
);

/**
 * Select component to select an organization to show data from that organization.
 *
 * @param onChange The callback when the organization changes.
 * @param userOrganizations list of user organization memberships (Optional)
 */
export default function OrganizationSelect({ onChange, userOrganizations }) {
  const classes = useStyles();

  const [showError, setShowError] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState();

  const [status, setStatus] = useState();

  /**
   * Fetch the organizations for the select component. Set the selected organization to the option set in localStorage.
   * the key is 'hw.' added to 'organization' encoded.
   */
  useEffect(() => {
    const fetch = async () => {
      try {
        console.log('userOrganizations: ',userOrganizations)
        if (userOrganizations) {
          const defaultOrganization = userOrganizations[0];
          setOrganizations(userOrganizations);

          const organization =
            localStorage["hw." + window.btoa("organization")];
          if (organization) {
            const organizationId = window.atob(organization);
            const foundOrganization = find(userOrganizations, {
              organization_id: organizationId,
            });
            if (foundOrganization) {
              setSelectedOrganization(foundOrganization);

              handlesetOraganizationLocalstorage(
                JSON.stringify(foundOrganization)
              );
            } else {
              localStorage.removeItem("hw." + window.btoa("organization"));
              setSelectedOrganization(defaultOrganization);
            }
          } else {
            setSelectedOrganization(defaultOrganization);
          }
        } else {
          const result = await requestForServer(GET_ORGANIZATIONS);
          if (result.error) {
            setShowError(true);
          } else {
            if (result && result.data) {
              const defaultOrganization = {
                organization_id: undefined,
                name: "All Organizations",
              };
              const sortedOrganizations = [
                defaultOrganization,
                ...sortBy(result.data, ["name"]),
              ];
              console.log('sortedOrganizations: ',sortedOrganizations);
              setOrganizations(sortedOrganizations);

              const organization =
                localStorage["hw." + window.btoa("organization")];
              if (organization) {
                const organizationId = window.atob(organization);
                setSelectedOrganization(
                  find(sortedOrganizations, { organization_id: organizationId })
                );

                handlesetOraganizationLocalstorage(
                  JSON.stringify(
                    find(sortedOrganizations, {
                      organization_id: organizationId,
                    })
                  )
                );
              } else {
                setSelectedOrganization(defaultOrganization);

                handlesetOraganizationLocalstorage(
                  JSON.stringify(defaultOrganization)
                );
              }
            } else {
              setOrganizations([]);
            }
          }
        }
      } catch (e) {
        setShowError(true);
      }
    };
    fetch();
  }, []);

  const handlesetOraganizationLocalstorage = useCallback((organization) => {
    window.Intercom("shutdown");

    let selectedOrgObject = JSON.parse(organization);

    let APPID = "fl2igt9h";
    localStorage.setItem("selectedOrg", organization);
    localStorage.setItem("intercomID", APPID);

    setTimeout(() => {
      window.Intercom("boot", {
        app_id: APPID,
      });
    }, 1000);
  }, []);
  //   const handlesetOraganizationLocalstorage = (organization) => {

  //     // var appId = orgList.includes(selectedOrgObject.name) ? "fl2igt9h" : "gkkugy4w";
  //     //  window.location.reload()
  //   };

  // Function to extract parameter value from URL query string
  const getUrlParameter = (name) => {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    const results = regex.exec(window.location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  // Retrieve parameter value from URL
  const userOrganizationParam = getUrlParameter("userOrganization");

  useEffect(() => {
    handleChangeOrganisationByParams();
  }, [userOrganizationParam, organizations]);

  const handleChangeOrganisationByParams = () => {
    // Update selected organization based on the parameter value
    if (userOrganizationParam) {
      const selectedOrg = find(organizations, {
        organization_id: userOrganizationParam,
      });
      if (selectedOrg) {
        if (userOrganizationParam) {
          localStorage["hw." + window.btoa("organization")] = window.btoa(
            userOrganizationParam
          );
          setSelectedOrganization(selectedOrg);
          localStorage.setItem(
            "selectedOrg",
            JSON.stringify(selectedOrganization)
          );
        }
      }
    }
  };
  // useEffect(() => {
  //   //  window.location.reload();
  //   console.log(selectedOrganization);
  //   // window.localStorage.setItem("selectedOrg", selectedOrganization);
  // }, [selectedOrganization]);

  useEffect(() => {
    // http://localhost:3000/evaluations/91f6f694-571e-46ec-a47a-bf4840185905?userOrganization=5fe85c45-1a5d-4099-88a4-9c5c38da8a87
    let storedData = localStorage.getItem("selectedOrg");
    if (
      window !== "undefined" &&
      userOrganizationParam &&
      storedData &&
      selectedOrganization
    ) {
      let jsoned = JSON.parse(storedData);
      let existingOrganisationId = jsoned["organization_id"];

      let isEqualOrganisation =
        existingOrganisationId === userOrganizationParam;

      if (!isEqualOrganisation) {
        setTimeout(() => {
         // Reload the browser after changing organization
          window.location.href = window.location.href;
        }, 1000);
      } else {
        handleChangeOrganisationByParams();
      }
    }
  }, [selectedOrganization]);

  /**
   * Handle changes to the organization.
   * @param event The change event.
   */
  const handleChange = (event) => {
    const value = event.target.value;
    setStatus(value);
    setSelectedOrganization(find(organizations, { organization_id: value }));
    if (value) {
      localStorage["hw." + window.btoa("organization")] = window.btoa(value);
    } else {
      localStorage.removeItem("hw." + window.btoa("organization"));
    }
    onChange && onChange();
  };

  return (
    <Fragment>
      {showError && (
        <DisplayError
          errorId={"evalDashboard.loadOrganizations.error"}
          error={true}
          enableRefresh={true}
          onClose={() => setShowError(false)}
        />
      )}
      <FormControl className={classes.formControl}>
        <Select
          value={status || ""}
          className={classes.selectStyle}
          displayEmpty={true}
          renderValue={() => (
            <Grid
              container
              wrap={"nowrap"}
              direction={"row"}
              alignItems={"center"}
            >
              <FHGTypography variant={"button"} className={classes.titleStyle}>
                {selectedOrganization
                  ? selectedOrganization.name
                  : "All Organizations"}
              </FHGTypography>
            </Grid>
          )}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          onChange={handleChange}
          input={
            <Input
              name="status"
              disableUnderline={true}
              style={{ marginRight: 8, fontSize: 18 }}
            />
          }
        >
          {organizations.map((organization, index) => (
            <MenuItem
              key={"Organization " + index}
              value={organization.organization_id}
            >
              <FHGTypography
                className={classes.menuText}
                color="inherit"
                variant="body1"
              >
                {organization.name}
              </FHGTypography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Fragment>
  );
}

OrganizationSelect.propTypes = {
  onChange: PropTypes.func,
};
