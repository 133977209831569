import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import classNames from 'classnames';
import { clone } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import NumberFormatCustom from '../../../fhg/components/NumberFormat';


const styles = theme => ({
   textFieldRoot: {
      padding: 0,
      'label + &': {
         marginTop: `0 !important`,
      },
   },
   textFieldInput: {
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.divider} !important`,
      borderRadius: 4,
      padding: '6px !important',
      marginTop: `${theme.spacing(1)}px !important`,
      width: `calc(100% - ${theme.spacing(3)}px)`,
      height: 36,
      color: `${theme.palette.text.primary} !important`, //#423313
      '&:focus': {
         border: `1px solid ${theme.palette.environment.dark.divider} !important`,
      },
      '&:disabled': {
         color: `${theme.palette.text.disabled} !important`,
         border: `1px solid ${theme.palette.text.disabled} !important`,
      },
      '@media print': {
         border: `none !important`,
      },
   },
   textFieldInputError: {
      border: `1px solid ${theme.palette.error.main} !important`,
      color: `${theme.palette.error.main} !important`,
      '&:focus': {
         border: `1px solid ${theme.palette.error.main} !important`,
      },
   },
   formControl: {
      transform: 'none',
      position: 'inherit',
   },
   deleteButton: {
      padding: 0,
      width: theme.spacing(4),
      height: theme.spacing(4),
      minWidth: theme.spacing(4),
      minHeight: theme.spacing(4),

      float: 'right',
      alignSelf: 'center',
   },
   submitButton: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      float: 'right',
      alignSelf: 'center',
   },
   input: {
      alignSelf: 'center',
   },
});

/**
 * The TextField with preset formats.
 */
class FormattedTextField extends Component {

   static propTypes = {
      format: PropTypes.oneOf(['text', 'currency', 'number']), //Format for the text field.
      thousandSeparator: PropTypes.oneOfType([ PropTypes.string, PropTypes.oneOf([ true ]) ]), //Number thousands
                                                                                               // separator.
      isDeletable: PropTypes.bool,                             // Indicates if the text field can be deleted.
      isSubmittable: PropTypes.bool,                           // Indicates if the text field has a button to submit.
      onSubmit: PropTypes.func,                                // Called when the user submits the value.
   };

   static defaultProps = {
      format: 'text',
      thousandSeparator: true,
      isDeletable: false,
      isSubmittable: false,
   };

   render() {
      const {classes, isDeletable, isSubmittable, onSubmit, format, onDelete, disabled, thousandSeparator, InputLabelProps: otherInputLabelProps = {}, InputProps: otherInputProps, inputProps, error, formProps, ...textFieldProps} = this.props;

      let inputPropsGroup = clone(inputProps);
      if (format !== 'text') {
         if (!inputPropsGroup) {
            inputPropsGroup = {};
         }
         inputPropsGroup.format = format;
         inputPropsGroup.thousandSeparator = thousandSeparator;
      }
      const InputProps = {
         // inputComponent: format !== 'text' ? NumberFormatCustom : undefined,
         disableUnderline: true,
         classes: {
            root: classes.textFieldRoot,
            input: classNames({'error': error}, {[classes.textFieldInputError]: error}, classes.textFieldInput),
         },
         endAdornment: (isDeletable || isSubmittable) && (
            <InputAdornment position='end' className={classes.input}>
               {isSubmittable && (
                  <IconButton
                     aria-label='Submit'
                     color='inherit'
                     className={classes.submitButton}
                     onClick={onSubmit}
                     disabled={disabled}
                  >
                     <DoneIcon/>
                  </IconButton>
               )}
               {isDeletable && (
                  <IconButton
                     aria-label='Delete'
                     color='inherit'
                     className={classes.deleteButton}
                     onClick={onDelete}
                     disabled={disabled}
                     tabIndex={-1}
                  >
                     <DeleteIcon/>
                  </IconButton>
               )}
            </InputAdornment>
         ),
         ...otherInputProps,
      };
      if (format !== 'text') {
         InputProps.inputComponent = NumberFormatCustom;
      }

      return (
         <TextField
            {...textFieldProps}
            disabled={disabled}
            error={error}
            inputProps={inputPropsGroup}
            // eslint-disable-next-line
            InputProps={InputProps}
            InputLabelProps={{
               ...otherInputLabelProps,
               shrink: true,
               classes: {
                  formControl: classes.formControl,
               },
            }}
         />
      );
   }
}

export default withStyles(styles)(FormattedTextField);
