import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 1000,
    margin: '0 auto',
  },
  header: {
    textAlign: 'center',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  contactOption: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.8),
  },
  icon: {
    fontSize: '2rem',
    marginLeft: '3rem',
    marginRight: '1rem',
    color: '#4f2682'
  },
  card: {
    maxWidth: 400,
    borderRadius: '5px !important', // Increase border radius
    boxShadow: '0 0 10px 4px rgba(0, 0, 0, 0.1) !important', // Apply box shadow with blur effect
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  text: {
    marginLeft: theme.spacing(1), // Add margin to separate icon and text
  },
  emailLink: {
    textDecoration: 'none',
    color: "#4f2682",
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

export default function Card_1() {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardActionArea>
        <CardContent className={classes.cardContent}>
          <div className={classes.contactOption}>
            <PhoneInTalkIcon className={classes.icon} />
             <a href="tel:785-776-1552" className={classes.emailLink}>
            <Typography gutterBottom variant="h6" component="div" className={classes.text}>
              785-776-1552
            </Typography>
          </a>
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
