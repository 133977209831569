import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import PropTypes from "prop-types";
import { stringify, parse } from "query-string";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  STATUS_ORDER,
  DEFAULT_STATUS_ORDER,
  HEADER_HEIGHT_EVAL_TABLE,
  HEADER_ICON_WIDTH_EVAL_TABLE,
} from "../../../Constants";
import SearchWithButton from "../../../fhg/components/SearchWithButton";
import NotFeature from "../../../fhg/security/NotFeature";
import { CUSTOMER_ENV } from "../../../Utils/ServerUtil";
import "./EvalDashboard.scss";
import EvalDashboardTable from "./EvalDashboardTable";
import SearchPastCatalogs from "./SearchPastCatalogs";
import StatusFilterMenu from "./StatusFilterMenu";
import eventCapture from "../../../eventList";
import { posthogCapture } from "../../../PostHogFunction";
import { Auth } from "aws-amplify";
import { isAdminOnly } from "../../../Utils/ServerUtil";
import { isMultipleOrganization } from "../../../Utils/ServerUtil";
// import { List, Divider } from "@mui/material";
// import Menu from "@material-ui/core/Menu";
import { FTButton, FTButtonIconText } from "../../buttons";
import { FTFilterIcon } from "../../icons";
// import { FTCheckbox } from "../../FTCheckbox";
import EmployeesFilterMenu from "./EmployeesFilterMenu";
import { useEmployeesStore } from "../../zustandState/ZustandStore";
import { useMenuStore } from "../../zustandState/ZustandStore";

const styles = (theme) => ({
  root: {
    height: "100%",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    "@media print": {
      display: "block !important",
      position: "relative",
    },
  },
  content: {
    maxWidth: "90%",
    overflow: "hidden",
    maxHeight: "100%",
    "@media print": {
      display: "block !important",
      position: "relative",
    },
  },
  table: {
    marginTop: "30px",
    marginBottom: "30px",
    backgroundColor: "white",
    paddingTop: "20px",
    flex: "1 1 !important",
    overflow: "hidden",
    //border: '1px solid #ccc', // Add border
    borderRadius: "10px", // Add rounded border
    "@media print": {
      display: "block",
    },
    "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
      display: "-ms-flexbox",
    },
  },

  heading: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(6),
  },
  list: {
    height: "100%",
    width: "100%",
  },
  searchList: {
    "& eval-table": {
      maxHeight: "100%",
    },
  },
  search: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    "@media print": {
      display: "none !important",
    },
  },
  themeUnderline: {
    "&:hover:not(:disabled):before,&:before": {
      borderColor: `${theme.palette.divider} !important`,
    },
    "&:after": {
      borderColor: `${theme.palette.environment.dark.divider} !important`,
    },
  },
  secondaryColor: {
    color: theme.palette.button.standard.secondary,
  },
  textFieldInput: {
    color: `${theme.palette.text.primary} !important`,
    "&::-ms-clear": {
      display: "none",
    },
  },
  print: {
    "@media print": {
      display: "block !important",
    },
  },
  emailCardStyle: {
    margin: theme.spacing(2, 0),
    boxShadow: "none",
  },
  buttonStyle: {
    alignSelf: "flex-end",
  },
  filterIcon: {
    display: "inline-block",
    marginRight: theme.spacing(0.5),
    height: 22,
    width: 22,
    color: "#4f2682 !important",
  },
  activeFilterIcon: {
    height: HEADER_HEIGHT_EVAL_TABLE,
    width: HEADER_ICON_WIDTH_EVAL_TABLE,
    marginTop: 0,
    padding: "1px 1px 0 1px",
    paddingRight: 1,
    paddingTop: 1,
    borderRadius: "50%",
    backgroundColor: "#b7b8b7 !important",
    color: "#4f2682 !important",
    //color: "#ffff !important"
  },
  iconRoot: {
    fontSize: 14,
  },
  filterButtonStyle: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(2),
    color: "#4f2682",
  },
  searchButtonStyle: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  progress: {
    position: "absolute",
    top: "calc(50% - 20px)",
    left: "calc(50% - 20px)",
    zIndex: 1001,
  },
  mouseTracker: {
    "&:focus": {
      outline: "none",
    },
  },
  menuText: {
    display: "inline-block",
  },
  image: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(2) - 4,
  },
  clear: {
    borderTop: `1px solid ${theme.palette.environment.light.level1.accent}`,
    backgroundColor: theme.palette.environment.light.level1.base,
    marginBottom: theme.spacing(-1),
  },
  statusCheckbox: {
    color: "#4f2682 !important",
  },
});

/**
 * The evaluators dashboard will show a list of evaluations grouped by owner.
 *
 * Last Reviewed: 11/12/18
 */
class EvalDashboard extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired, // The component styles.
    history: PropTypes.object.isRequired, // The react routes history object.
  };

  constructor(props) {
    super(props);

    const { SelectedEmployeesState } = useEmployeesStore.getState();
    const { MenuClosedState } = useMenuStore.getState();

    const evalTableLocal = localStorage.evalTable;
    const filter = evalTableLocal
      ? JSON.parse(evalTableLocal).filter || DEFAULT_STATUS_ORDER
      : DEFAULT_STATUS_ORDER;
    const searchParams = parse(props.location.search, {
      parseBooleans: false,
      parseNumbers: false,
    });
    const search = !!searchParams.search ? searchParams.search : "";

    this.state = {
      selectedEmployees: SelectedEmployeesState,
      search,
      showClose: false,
      filter,
      isFiltering: false,
      showSearchPast: !!search,
      searchType: searchParams.type || "Catalog",
      employeesFilterClose: MenuClosedState,
    };
  }

  componentDidMount() {
    // Subscribe to Zustand store updates
    this.unsubscribe = useEmployeesStore.subscribe((state) => {
      this.setState({ selectedEmployees: state.SelectedEmployeesState });
    });

    this.unsubscribe = useMenuStore.subscribe((state) => {
      this.setState({ employeesFilterClose: state.MenuClosedState });
    });
  }

  componentWillUnmount() {
    // Unsubscribe when the component is unmounted to avoid memory leaks
    if (this.unsubscribe) this.unsubscribe();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!isEqual(prevProps.location.search, this.props.location.search)) {
      const searchParams = parse(this.props.location.search, {
        parseBooleans: false,
        parseNumbers: false,
      });
      const search = !!searchParams.search ? searchParams.search : "";
      this.setState({ showSearchPast: !!search, search });
    }

    // Compare the previous state with the current state to check for changes
    if (prevState.employeesFilterClose !== this.state.employeesFilterClose) {
      console.log(
        "Selected Employees updated:",
        this.state.employeesFilterClose
      );
    }
  }

  /**
   * When user selects to create a new evaluation.
   */
  onAdd = () => {
    //window?.posthog?.capture(eventCapture.SAVE_NEW_ORGANIZATION);
    posthogCapture(eventCapture.SAVE_NEW_ORGANIZATION);
    this.props.history.push("/evaluations/", {
      ...this.props.location.state,
      isNewEval2: true,
    });
  };

  /**
   * When search data has changed.
   * @param target The change event.
   */
  onSearchChange = ({ target }) => {
    this.setState({ search: target.value }, this.handleSearchChange);
  };

  /**
   * After debounce, perform the actual search of data for the search string the user entered.
   * @type {Function} the debounced function.
   */
  handleSearchChange = async (search, type) => {
    // window?.posthog?.capture(eventCapture.SEARCHES);
    posthogCapture(eventCapture.SEARCHES);
    const state = { search, type };
    const searchState = stringify(state);
    // sessionStorage.evalDashboard = JSON.stringify(state);
    this.props.history.push(
      { pathname: this.props.location.pathname, search: searchState },
      this.props.location.state
    );
    this.setState({ search, searchType: type });
  };

  /**
   * When the filter menu is closed, filter the data.
   *
   * @param filter The new filter array.
   */
  onFilterClose = (filter) => {
    localStorage.evalTable = JSON.stringify({ filter });
    this.setState({ anchorFilterEl: null, isFiltering: true }, () => {
      setTimeout(() => {
        this.setState({ filter, isFiltering: false });
        if (!isEqual(this.trackFilter, filter)) {
          //window?.posthog?.capture(eventCapture.FILTER_CHANGED);
          posthogCapture(eventCapture.FILTER_CHANGED);
          this.trackFilter = null;
        }
      }, 500);
    });
  };

  /**
   * Handle the user click on the filter button.
   *
   * @param event The click event.
   */

  handleFilterClick = (event) => {
    event.stopPropagation();
    event.preventDefault();

    this.trackFilter = this.state.filter;
    this.setState({ anchorFilterEl: event.currentTarget });
    //console.log("EMPLOYEES STATE GLOBAL", this.state.selectedEmployees);
  };

  /**
   * Handle closing the search past catalogs dialog.
   */
  handleCloseSearchCatalog = () => {
    this.props.history.replace(this.props.location.pathname);
  };

  /**
   * When the Radio changes, set the new value and remove the search from the URI.
   * @param target The target of the radio change event.
   */
  handleRadioChange = ({ target }) => {
    this.setState({ [target.name]: target.value, isChanged: true });
    this.handleCloseSearchCatalog();
  };

  fetchEmployeesData = async () => {
    let jwtToken;
    let tokenRefreshError = false;

    try {
      const session = await Auth.currentSession();
      jwtToken = session.idToken.jwtToken;
    } catch (e) {
      try {
        const session = await Auth.currentSession();
        jwtToken = session.idToken.jwtToken;
      } catch (e) {
        console.log("Error refreshing token", e);
        tokenRefreshError = true;
        // Allow to go through without jwtToken for server requests not requiring an authenticated user.
      }
    }

    const headers = {
      authorization: jwtToken,
      accept: "application/json",
    };
    // This adds a header for selected organization_ID or _Key which is stored in local storage
    if (isAdminOnly() || isMultipleOrganization()) {
      const organization = localStorage["hw." + window.btoa("organization")];
      if (organization && window.atob(organization)) {
        headers["HW-Organization"] = window.atob(organization);
      }
    }
    const options = {
      method: "GET",
      headers: headers,
    };

    const url = `${process.env.REACT_APP_API_ENDPOINT_V3}/employees`;

    try {
      // Make the API call
      const response = await fetch(url, options);

      // Check if the response is successful (status code 2xx)
      if (response.ok) {
        // Parse the JSON response
        const employeeData = await response.json();
        //console.log("Employeeeees API response:", employeeData);
        // Handle the data as needed

        // Create an empty array to store names
        const EMPLOYEE_NAME = [];

        // Loop through the employeesData and extract names
        employeeData.forEach((employee) => {
          EMPLOYEE_NAME.push(employee.name);
        });

        //  console.log(
        //    "EMPLOYEES_NAMES ================================++>>>>>>>>>>>>:",
        //    EMPLOYEE_NAME
        //  );

        // Set the EMPLOYEES_NAMES filter in the state
        this.setState({ EMPLOYEES_NAMES: EMPLOYEE_NAME });
      } else {
        console.error("API request failed with status:", response.status);
        // Handle the error as needed
      }
    } catch (error) {
      console.error("Error occurred during API request:", error);
      // Handle the error as needed
    }
  };

  render() {
    const { classes } = this.props;
    const {
      search,
      filter,
      isFiltering,
      showSearchPast,
      selectedEmployees,
      employeesFilterClose,
    } = this.state;

    const catalogsInPlaceSearch =
      localStorage.searchCatalogInPlace === "true" &&
      this.state.searchType === "Catalog"
        ? search
        : undefined;

    return (
      <Grid
        container
        className={classes.root}
        spacing={0}
        justifyContent={"center"}
      >
        <Grid
          container
          className={classes.content}
          direction={"column"}
          wrap={"nowrap"}
          spacing={0}
        >
          <Grid container className={classes.print} direction={"column"}>
            <Grid
              item
              container
              direction={"row"}
              justifyContent={"space-between"}
            >
              <Grid
                item
                style={{
                  width: "20%",
                  justifyContent: "start",
                  alignItems: "end",
                  display: "flex",
                }}
              >
                <FTButton
                  title="evalDashboard.new.label"
                  buttonAction={this.onAdd}
                />
              </Grid>
              <Grid
                item
                container
                direction={"row"}
                style={{ width: "auto" }}
                alignItems={"center"}
              >
                <EmployeesFilterMenu />
                <NotFeature feature={CUSTOMER_ENV}>
                  {isFiltering && (
                    <CircularProgress className={classes.progress} />
                  )}
                  <FTButtonIconText
                    id="status-filter-button"
                    title="evalDashboard.status.column"
                    buttonAction={this.handleFilterClick}
                    icon={
                      <FTFilterIcon
                        activeFilter={
                          get(this.state, "filter", []).length <
                          STATUS_ORDER.length
                        }
                      />
                    }
                  />
                  <StatusFilterMenu
                    isOpen={this.state && !!this.state.anchorFilterEl}
                    filters={this.state && this.state.filter}
                    anchorEl={this.state && this.state.anchorFilterEl}
                    onClose={this.onFilterClose}
                  />
                </NotFeature>
                <Grid
                  container
                  item
                  direction={"row"}
                  spacing={3}
                  alignItems={"center"}
                  style={{ width: "auto" }}
                >
                  <Grid item>
                    <SearchWithButton
                      onChange={this.handleSearchChange}
                      search={search}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.table}>
            <EvalDashboardTable
              className={classes.list}
              filter={filter}
              search={catalogsInPlaceSearch}
              selectedEmployees={selectedEmployees}
              employeesFilterClose={employeesFilterClose}
            />
          </Grid>
        </Grid>
        {showSearchPast && !catalogsInPlaceSearch && (
          <SearchPastCatalogs
            open={showSearchPast}
            onClose={this.handleCloseSearchCatalog}
          />
        )}
      </Grid>
    );
  }
}

export default withRouter(withStyles(styles)(EvalDashboard));
