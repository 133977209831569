import React, { useContext } from 'react';
import { IMSContext } from './IMSformCategtorySelect';

const withSelectedCategory = (WrappedComponent) => {
  return (props) => {
    const { selectedCategory } = useContext(IMSContext);
    return <WrappedComponent {...props} selectedCategory={selectedCategory} />;
  };
};

export default withSelectedCategory;
