import Amplify, { Storage } from 'aws-amplify';
import { ConfirmSignIn, ConfirmSignUp, SignUp, VerifyContact, withAuthenticator } from 'aws-amplify-react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {Switch, Route, Redirect} from 'react-router-dom'
import EvalMain from './components/eval/EvalMain';
import MySignIn from './components/security/MySignIn';
import ResetPassword from './components/security/ResetPassword';
import {DEFAULT_LOCATION} from './Constants';
import {setUserData, isFilesOnly, getUserData} from './Utils/ServerUtil';
import * as Sentry from '@sentry/react';
import { getIntercomHashFromUser } from './Utils/GenerateUserIntercomHash';

console.log("USER POOL: " + process.env.REACT_APP_USER_POOL);
console.log("IDENTITY POOL: " + process.env.REACT_APP_IDENTITY_POOL);
console.log("REGION: " + process.env.REACT_APP_REGION);
console.log("WEB CLIENT: " + process.env.REACT_APP_WEB_CLIENT);
console.log("INGEST POINT: " + process.env.REACT_APP_INGEST_POINT);


     
if (!process.env.REACT_APP_API) {

  Amplify.configure({
    Auth: {
      // REQUIRED - Amazon Cognito Identity Pool ID
      identityPoolId: "us-east-1:e839f475-0266-4f7c-ba54-c6fb378169c7",
      // REQUIRED - Amazon Cognito Region
      region: "us-east-1",
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: "us-east-1_azTJlZeRb",
      // OPTIONAL - Amazon Cognito Web Client ID
      userPoolWebClientId: "7onkvofvfuirh2ak2t86hdv5cb",
    },
    Storage: {
      bucket: "dev-heavyworth-ingestion", //REQUIRED -  Amazon S3 bucket
      region: "us-east-1",
      customPrefix: {
        public: "",
        protected: "",
        private: "",
      },
    },
    API: {
      endpoints: [
        {
          name: "api",
          endpoint: "https://dev-api.heavyworth.com/v1",
        },
      ],
    },
  });
} else {

  /*
   Identity pool id: us-east-1:e839f475-0266-4f7c-ba54-c6fb378169c7
   User Pool Id: us-east-1_azTJlZeRb
   User Poot ARN: arn:aws:cognito-idp:us-east-1:019868025223:userpool/us-east-1_azTJlZeRb
   App Id: 4cdtf6udj7bp23ulo90vicbs67
    */
  Amplify.configure({
    Auth: {
      identityPoolId:
        process.env.REACT_APP_IDENTITY_POOL ||
        "us-east-1:e839f475-0266-4f7c-ba54-c6fb378169c7",
      region: process.env.REACT_APP_REGION || "us-east-1",
      userPoolId: process.env.REACT_APP_USER_POOL || "us-east-1_azTJlZeRb",
      userPoolWebClientId:
        process.env.REACT_APP_WEB_CLIENT || "7onkvofvfuirh2ak2t86hdv5cb",
      authenticationFlowType:
        process.env.REACT_APP_AUTH_TYPE || "USER_SRP_AUTH",
    },
    Storage: {
      bucket: process.env.REACT_APP_INGEST_POINT || "prd-heavyworth-ingestion",
      region: process.env.REACT_APP_REGION || "us-east-1",
      customPrefix: {
        public: "",
        protected: "",
        private: "",
      },
    },
    API: {
      endpoints: [
        {
          name: "api",
          endpoint:
            process.env.REACT_APP_API_ENDPOINT ||
            "https://api.heavyworth.com/v1",
        },
      ],
    },
  });
}
Storage.configure({ level: "private" });

class Main extends Component {
  constructor(props, context) {
    super(props, context);

    setUserData(get(props, "authData"));
   
    const userData = getUserData();

    // idenify intercom user

    let hash =  getIntercomHashFromUser(userData.email)
         
    window.Intercom('update', {
       hide_default_launcher: false,
       name: userData.name, // Full name
       email: userData.email, // Email address
       user_hash: hash,
       created_at: Date.now()
    });

    let currentOrganization = JSON.parse(localStorage.getItem("selectedOrg"));

    //   idenify posthog user
    window.posthog.identify(userData?.email);
    window.posthog.people.set({
      email: userData?.email,
      "Organization Name": currentOrganization?.name,
      "Organization Key": currentOrganization?.organization_id,
    });

    Sentry.configureScope(function (scope) {
      scope.setUser({
        id: props.authData.signInUserSession.idToken.payload.sub,
        email: userData.email,
      });
    });
  }

  static childContextTypes = {
    authData: PropTypes.object,
  };

  getChildContext() {
    return {
      authData: this.props.authData,
    };
  }

  render() {
    const redirectPath = isFilesOnly() ? "/valuation" : DEFAULT_LOCATION;

    return (
      <div className={"main-style"}>
        <Switch>
          <Route exact path="/" render={() => <Redirect to={redirectPath} />} />
          <Route exact path="/login" 
            render={(props) => (
              <MySignIn
                {...props}
                authState={this.props.authState}
                authData={this.props.authData}
                hide={this.props.hide}
                onAuthEvent={this.props.onAuthEvent}
                onStateChange={this.props.onStateChange}
              />
            )}
          />
          <Route path="/*" component={EvalMain} />
        </Switch>
      </div>
    );
  }
}

export default withAuthenticator(Main, false, [
  <MySignIn />,
  <ConfirmSignIn />,
  <VerifyContact />,
  <SignUp />,
  <ConfirmSignUp />,
  <ResetPassword />,
]);
