import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import numberFormatter from 'number-formatter';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import Typography from '../../../fhg/components/Typography';
import {getIdDescription, getEquipmentDescription} from '../EquipmentUtil';
import classNames from 'classnames';

const styles = theme => ({
   description: {
      whiteSpace: 'normal',
   },
   gridContainer: {
      display: 'flex',
      cursor: 'default',
      flexDirection: 'column',
      flex: '1 1 auto',
   },
   icon: {
      color: `${theme.palette.content.subtle} !important`,
      paddingRight: theme.spacing(0.5),
   },
   location: {
      marginBottom: theme.spacing(1),
   },
});

/**
 * The single list item component.
 */
class EquipmentListItemDescription extends Component {

   static propTypes = {
      intl: PropTypes.any.isRequired,  // Localization object.
      classes: PropTypes.object,       // Styles for the component.
      theme: PropTypes.object,         // Theme for the styles.
      evalItem: PropTypes.object,      // The equipment item from an evaluation.
      eval: PropTypes.object,          // The evaluation.
   };

   static defaultProps = {
      evalItem: {},
   };

   getIdDescription = (classes, evalItem) => {

      return (
         <div>
            {evalItem.serial && evalItem.serial !== 'unknown' && (
               <div>
                  <Typography className={'nonessential-item-title'} variant='inherit'
                              id={'equipmentList.serialNo.label'} style={{ display: 'inline-block' }}/>
                  <Typography className={'numbers-subtle'}
                              style={{ display: 'inline-block' }}>&nbsp;{evalItem.serial}</Typography>
               </div>
            )}
            {evalItem.vin && evalItem.vin !== 'unknown' && ([
               <div key={'vinKey'}>
                  <Typography className={'nonessential-item-title'} variant='inherit' id={'equipmentList.vin.label'}
                              style={{ display: 'inline-block' }}/>
                  <Typography className={'numbers-subtle'}
                              style={{ display: 'inline-block' }}>&nbsp;{evalItem.vin}</Typography>
               </div>
            ])}
            {evalItem.personal_id && evalItem.personal_id !== 'unknown' && ([
               <div key={'inventoryKey'}>
                  <Typography className={'nonessential-item-title'} variant='inherit'
                              id={'equipmentList.inventoryId.label'} style={{ display: 'inline-block' }}/>
                  <Typography className={'numbers-subtle'}
                              style={{ display: 'inline-block' }}>&nbsp;{evalItem.personal_id}</Typography>
               </div>
            ])}
            {evalItem.sticker_id && evalItem.sticker_id !== 'unknown' && ([
               <div key={'stickerKey'}>
                  <Typography className={'nonessential-item-title'} variant='inherit'
                              id={'equipmentList.stickerId.label'} style={{ display: 'inline-block' }}/>
                  <Typography className={'numbers-subtle'}
                              style={{ display: 'inline-block' }}>&nbsp;{evalItem.sticker_id}</Typography>
               </div>
            ])}
         </div>
      );
   };

   render() {
      const { intl, classes, evalItem } = this.props;

      const meters = (typeof evalItem.meters === 'string' ? JSON.parse(evalItem.meters) : evalItem.meters) || [];
      const ids = getIdDescription(intl, evalItem);

      return (
         <Grid container className={classes.gridContainer} spacing={0}>
            <Grid item>
               <Typography className={classNames(classes.description, 'default-text')}>
                  {getEquipmentDescription(evalItem)}
               </Typography>
            </Grid>
            <Grid item className={classes.location}>
               <Typography className={'subtle-text-small'} values={evalItem}>
                  {evalItem.location}
               </Typography>
            </Grid>
            <Grid item container direction={'row'} spacing={1} alignItems={'center'}>
               <Grid item>
                  <Typography className={'nonessential-item-title'} variant='inherit'
                              id={'equipmentList.valuationType.column'} display={'inline'}/>
               </Grid>
               <Grid item>
                  <Typography className={classes.defaultTextStyle} noWrap showFullOnHover display={'inline'}>
                     {evalItem.valuation_type}
                  </Typography>
               </Grid>
            </Grid>
            <Grid item>
               <Grid container direction={'column'} spacing={0} alignItems={'flex-start'} style={{marginBottom: 6}}>
                  {meters.map(meter => {
                        return meter.value && meter.value !== '0' ? (
                           <Grid item key={meter.name || meter.units}>
                              <Grid container direction={'row'} wrap={'nowrap'} justify={'center'}>
                                 <Grid item style={{paddingRight: 4}}>
                                    <Typography className={'hours'}>{numberFormatter('#,###.',
                                       meter.value)}</Typography>
                                 </Grid>
                                 <Grid item>
                                    <Typography className={'hours'}>
                                       {meter.name || meter.units}
                                    </Typography>
                                 </Grid>
                              </Grid>
                           </Grid>
                        ) : undefined
                     }
                  )}
               </Grid>
            </Grid>
            {ids.length > 0 && (
               <Grid item style={{margin: 4}}>
                  <Grid container direction={'column'} spacing={1}>
                     {this.getIdDescription(classes, evalItem)}
                  </Grid>
               </Grid>
            )}
            <Grid item container direction={'row'} spacing={1} alignItems={'center'}
                  style={{display: evalItem.pw_contract_price ? undefined : 'none'}}>
               <Grid item>
                  <Typography className={'nonessential-item-title'} variant='inherit'
                              id={'equipmentList.contract.column'} display={'inline'}/>
               </Grid>
               <Grid item>
                  <a href={this.props.eval.purplewave_url}>
                     <Typography className={classes.defaultTextStyle} noWrap showFullOnHover>
                        {numberFormatter('$#,###,##0.', evalItem.pw_contract_price) || 'N/A'}
                     </Typography>
                  </a>
               </Grid>
            </Grid>
            <Grid item container direction={'row'} spacing={1} alignItems={'center'}
                  style={{display: evalItem.latest_auction_price ? undefined : 'none'}}>
               <Grid item>
                  <Typography className={'nonessential-item-title'} variant='inherit'
                              id={'equipmentList.current.column'} display={'inline'}/>
               </Grid>
               <Grid item>
                  <a href={this.props.eval.purplewave_url}>
                     <Typography className={classes.defaultTextStyle} noWrap showFullOnHover>
                        {numberFormatter('$#,###,##0.', evalItem.latest_auction_price) || 'N/A'}
                     </Typography>
                  </a>
               </Grid>
            </Grid>
         </Grid>
      );
   }
}

export default injectIntl(withStyles(styles, { withTheme: true })(EquipmentListItemDescription));
