import { isWidthDown } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Link from "@material-ui/core/Link";
import { withStyles } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import Switch from "@material-ui/core/Switch";
import withWidth from "@material-ui/core/withWidth";
import classNames from "classnames";
import { sortBy, isEqual, defer, difference, memoize, get } from "lodash";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import ReactTable from "react-table";
import {
  PHOTO_SUMMARY_PAGE_SIZE,
  THUMBNAIL_SIZE_SMALL,
  THUMBNAIL_SIZE_LARGE,
  IMAGE_VIEW_KEYS,
  IMAGE_VIEW_CONVERT,
} from "../../../Constants";
import Typography from "../../../fhg/components/Typography";
import {
  createThumbnails,
  removeThumbnails,
  FileSummary,
  PhotoPlaceHolder,
} from "../../../fhg/utils/Utils";

const THUMBNAIL_SIZE = 70;

export function SuccessCheck(props) {
  return (
    <SvgIcon {...props} style={{ width: "1.1em", height: "1.1em" }}>
      <g
        id="Components/Icons/Success-Check-Active"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M12,24 C5.372583,24 0,18.627417 0,12 C0,5.372583 5.372583,0 12,0 C18.627417,0 24,5.372583 24,12 C24,18.627417 18.627417,24 12,24 Z"
          id="Combined-Shape"
          fill="#2DA970"
        ></path>
        <polygon
          id="Path-2"
          fill="#FFFFFF"
          fillRule="nonzero"
          points="9.90190232 13.853925 17.9800504 6.8655828 19.9428011 9.1344172 9.55177246 18.1236141 4.84692535 12.4689061 7.15307465 10.5501369"
        ></polygon>
      </g>
    </SvgIcon>
  );
}

const styles = (theme) => ({
  root: {
    width: "100%",
    maxHeight: "fit-content",
    marginBottom: theme.spacing(2),
    overflow: "hidden",
    [theme.breakpoints.down("xs")]: {
      marginBottom: 0,
    },

    "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
      width: "100%",
      height: "100%",
      display: "inline-flex",
      bottom: "inherit", //align to left
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  containerStyle: {
    //remove height of expansion summary
    maxHeight: "calc(100% - 58px)",
    overflow: "auto",
  },
  listTextStyle: {
    color: "$color-content-default",
  },
  statusTextStyle: {
    color: theme.palette.content.subtle3, //'rgba(72, 50, 0, 0.65)',
  },
  warningStyle: {
    color: theme.palette.button.standard.warn,
  },
  table2: {
    flex: "1 1 auto",
    maxHeight: "100%",
    border: "none",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
    overflow: "hidden",
    "& .rt-table": {
      overflow: "hidden",
    },
    "& .rt-tbody": {
      overflowY: "auto",
    },
    "& .rt-tbody .rt-tr-group": {
      borderBottom: "none",
      display: "inline-table",
      width: "100%",
    },
    "@media print": {
      display: "table",
    },
    "& .rt-thead.-header": {
      borderBottom: "none !important",
      [theme.breakpoints.down("xs")]: {
        height: 0,
      },
    },
    "& .rt-tr": {
      width: "100%",
      marginLeft: "4px !important",
      marginTop: 1,
      marginBottom: 1,
      flex: "0 0 auto",
    },
    "& .selected": {
      "@media screen": {
        "&:before": {
          content: '""',
          position: "absolute",
          top: -2,
          left: -4,
          right: 0,
          bottom: -2,
          borderLeft: `4px solid ${theme.palette.action.selectedDark}`,
          borderTop: `1px solid ${theme.palette.environment.light.divider} !important`,
          borderBottom: `1px solid ${theme.palette.environment.light.divider} !important`,
        },
      },
    },
    "& .rt-tbody .rt-tr": {
      // Needed to make the :after 'absolute' position work for the selection borders.
      position: "relative",
    },
    "& .rt-resizable-header-content": {
      height: "100%",
      paddingBottom: theme.spacing(1),
    },
  },
  emptyHeight: {
    minHeight: 400,
    height: "100%",
  },
  noDataStyle: {
    width: "fit-content",
  },
  progressStyle: {
    position: "absolute",
    marginLeft: "47%",
    marginTop: 25,
  },
  headerStyle: {
    textAlign: "right",
    top: 0,
    right: 0,
    backgroundColor: "white",
    marginRight: theme.spacing(2),
    // zIndex: 1002,
  },
  switchStyle: {
    color: `${theme.palette.content.default} !important`,
  },
  standardDefaultStyle: {
    color: `${theme.palette.content.default} !important`,
    fontSize: 34.5,
  },
  chipStyle: {
    composes: "contrast-text-small",
    // composes: 'no-print',
    zIndex: 1001,
    position: "absolute",
    right: theme.spacing(2),
    bottom: theme.spacing(2),
    backgroundColor: "rgba(255,255,255,0.8)",
    height: 29,
    "-webkit-filter": "drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2))",
    filter: "drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2))",
    "& > svg": {
      height: "calc(100% - 6px)",
      left: 5,
      position: "relative",
    },
  },
  chipStyle1: {
    composes: "contrast-text-small",
    // composes: 'no-print',
    zIndex: 1001,
    fontSize: 12,
    position: "absolute",
    right: theme.spacing(1),
    bottom: theme.spacing(1),
    backgroundColor: "rgba(255,255,255,0.8)",
    height: 24,
    "-webkit-filter": "drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2))",
    filter: "drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2))",
    "& > svg": {
      height: "calc(100% - 6px)",
      left: 5,
      position: "relative",
    },
  },
  chipLabelSmall: {
    paddingLeft: 4,
    paddingRight: 4,
  },
  divStyle: {
    "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
      height: "calc(100% - 24px)",
    },
    width: "100%",
    display: "flex",
    position: "relative",
    maxHeight: "calc(100% - 24px)",
    overflow: "hidden",
    [theme.breakpoints.down("xs")]: {
      height: "100%",
    },
  },
});

class UploadPhotoSummary extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    photos: PropTypes.array, //The list of all the photos to summarize
    uploadedPhotos: PropTypes.array, //The list of all the photos to summarize
    supportLargeThumbnails: PropTypes.bool, // If false, only small thumbnails are shown and no option for large.
    type: PropTypes.oneOf(["images", "files"]),
  };

  static defaultProps = {
    photos: [],
    uploadedPhotos: [],
    supportLargeThumbnails: true,
    type: "images",
  };

  constructor(props) {
    super(props);

    const sortedPhotos = this.getPhotoObjects(props.photos);
    const sortedUploadedPhotos = this.getPhotoObjects(props.uploadedPhotos);
    this.state = {
      sortedPhotos,
      sortedUploadedPhotos,
      page: 0,
      pagePhotos: [],
      largePhotos:
        props.supportLargeThumbnails &&
        localStorage.largeUploadPhotos === "true",
    };
    defer(() => {
      this.initializePage(0, sortedPhotos, sortedUploadedPhotos).then(
        (pagePhotos) => {
          this.setState({ pagePhotos });
        }
      );
    });
  }

  componentWillReceiveProps(nextProps, nextContext) {
    let sortedPhotos;
    if (!isEqual(nextProps.photos, this.props.photos)) {
      if (nextProps.photos.length > 0) {
        sortedPhotos = this.state.sortedPhotos.slice(0);
        const differenceArray = difference(nextProps.photos, this.props.photos);
        sortedPhotos = sortedPhotos.concat(
          this.getPhotoObjects(differenceArray)
        );

        this.initializePage(
          0,
          sortedPhotos,
          this.state.sortedUploadedPhotos
        ).then((pagePhotos) => {
          this.setState({ pagePhotos });
        });
      } else {
        sortedPhotos = [];
      }
      this.setState({ sortedPhotos });
    }
    if (!isEqual(nextProps.uploadedPhotos, this.props.uploadedPhotos)) {
      let sortedUploadedPhotos;

      if (nextProps.uploadedPhotos.length > 0) {
        sortedUploadedPhotos = this.state.sortedUploadedPhotos.slice(0);
        const differenceArray = difference(
          nextProps.uploadedPhotos,
          this.props.uploadedPhotos
        );
        sortedUploadedPhotos = sortedUploadedPhotos.concat(
          this.getPhotoObjects(differenceArray)
        );

        this.initializePage(
          0,
          this.state.sortedPhotos,
          sortedUploadedPhotos
        ).then((pagePhotos) => {
          this.setState({ pagePhotos });
        });
      } else {
        sortedUploadedPhotos = [];
        if (this.state.sortedPhotos.length > 0) {
          this.initializePage(
            0,
            this.state.sortedPhotos,
            sortedUploadedPhotos
          ).then((pagePhotos) => {
            this.setState({ pagePhotos });
          });
        }
      }
      this.setState({ sortedUploadedPhotos });
    }
  }

  getPhotoObjects = (photos) => {
    let sortedPhotos = [];

    for (const photo of photos) {
      const photoObject = FileSummary.getFileSummary(photo);
      if (!(photoObject instanceof PhotoPlaceHolder)) {
        photoObject && sortedPhotos.push(photoObject);
      }
    }

    //Photos without names will be sorted to the end so that the new photos added will be before those.
    return sortBy(sortedPhotos, (photo) => photo.getName() || "ZZZZZZZ");
  };

  getColumns = () => {
    const isNarrowView = isWidthDown("xs", this.props.width);

    if (!isNarrowView && this.state.largePhotos) {
      return [
        {
          id: "image",
          maxWidth: THUMBNAIL_SIZE_LARGE,
          // width: 200,
          Cell: (row) => {
            const thumbnail = row.original.getThumbnail();
            if (thumbnail) {
              const image_view =
                get(row, "original.photo.image_view") ||
                get(row, "original.file.__category") ||
                get(row, "original.photo.__category") ||
                "BEXT";
              const imageOriginal = get(row, "original.photo.sizes.original");
              const LinkOption = imageOriginal ? Link : Fragment;
              return (
                <Grid container direction={"column"} spacing={1}>
                  <Hidden xsDown>
                    <Grid
                      item
                      style={{
                        height: "100%",
                        minHeight: THUMBNAIL_SIZE_SMALL,
                        position: "relative",
                      }}
                    >
                      <Chip
                        className={this.props.classes.chipStyle}
                        label={
                          <Typography
                            id={
                              IMAGE_VIEW_KEYS[image_view] ||
                              IMAGE_VIEW_KEYS[IMAGE_VIEW_CONVERT[image_view]] ||
                              image_view
                            }
                          />
                        }
                      />
                      <LinkOption
                        href={imageOriginal}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <img
                          key={"img" + row.name}
                          style={{
                            maxHeight: "100%",
                            maxWidth: "100%",
                          }}
                          src={row.original.getThumbnail()}
                          alt={"Thumbnail Preview"}
                        />
                      </LinkOption>
                    </Grid>
                  </Hidden>
                  <Grid item>
                    <Grid container direction={"row"} spacing={2}>
                      <Grid item>
                        <LinkOption
                          href={imageOriginal}
                          rel="noreferrer"
                          target="_blank"
                          underline={"always"}
                        >
                          <Typography className={`normal-default-text`}>
                            {row.original.getName()}
                          </Typography>
                        </LinkOption>
                      </Grid>
                      <Grid item>{row.original.getStatus()}</Grid>
                    </Grid>
                  </Grid>
                </Grid>
              );
            }
            return null;
          },
        },
      ];
    } else {
      return [
        {
          id: "image",
          width: THUMBNAIL_SIZE + 18,
          show: !isNarrowView,
          Cell: (row) => {
            const { classes } = this.props;
            const thumbnail = row.original.getThumbnail();
            if (thumbnail) {
              const imageOriginal = get(row, "original.photo.sizes.original");
              const LinkOption = imageOriginal ? Link : Fragment;
              const image_view =
                get(row, "original.photo.image_view") ||
                get(row, "original.file.__category") ||
                get(row, "original.photo.__category") ||
                "BEXT";

              return (
                // <Grid item style={{height: '100%', position: 'relative'}}>
                //    <Chip className={classes.chipStyle1} size='small'
                //          classes={{labelSmall: classes.chipLabelSmall}} label={(
                //       <Typography id={IMAGE_VIEW_KEYS[image_view] ||
                //       IMAGE_VIEW_KEYS[IMAGE_VIEW_CONVERT[image_view]] ||
                //       image_view}
                //       />
                //    )}/>
                //    <LinkOption href={imageOriginal} target='_blank' rel='noreferrer'>
                //       <img key={'img' + row.name} width={THUMBNAIL_SIZE_SMALL} height={THUMBNAIL_SIZE_SMALL}
                //            src={row.original.getThumbnail()} alt={'Thumbnail Preview'}/>
                //    </LinkOption>
                // </Grid>

                <Grid item style={{ height: "100%", position: "relative" }}>
                  <Chip
                    className={classes.chipStyle1}
                    size="small"
                    classes={{ labelSmall: classes.chipLabelSmall }}
                    label={
                      <Typography
                        id={
                          IMAGE_VIEW_KEYS[image_view] ||
                          IMAGE_VIEW_KEYS[IMAGE_VIEW_CONVERT[image_view]] ||
                          image_view
                        }
                      />
                    }
                  />
                  <a href={imageOriginal} target="_blank" rel="noreferrer">
                    <img
                      key={"img" + row.name}
                      width={THUMBNAIL_SIZE_SMALL}
                      height={THUMBNAIL_SIZE_SMALL}
                      src={row.original.getThumbnail()}
                      alt={"Thumbnail Preview"}
                    />
                  </a>
                </Grid>
              );
            }
            return null;
          },
        },
        {
          id: "item",
          minWidth: 120,
          // maxWidth: 260,
          Cell: (row) => {
            const imageOriginal = get(row, "original.photo.sizes.original");
            const LinkOption = imageOriginal ? Link : Fragment;

            return (
              //   <div
              //     style={{
              //       width: "100%",
              //       height: "100%",
              //       display: "table",
              //       textAlign: "left",
              //     }}
              //   >
              //     <div style={{ verticalAlign: "middle", display: "table-cell" }}>
              //       <LinkOption
              //         href={imageOriginal}
              //         target="_blank"
              //         rel="noreferrer"
              //         underline={"always"}
              //       >
              //         <Typography className={`normal-default-text`}>
              //           {row.original.getName()}
              //         </Typography>
              //       </LinkOption>
              //     </div>
              //   </div>

              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "table",
                  textAlign: "left",
                }}
              >
                <div style={{ verticalAlign: "middle", display: "table-cell" }}>
                  <a
                    href={imageOriginal}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "underline" }} // This replaces `underline={"always"}` from MUI
                  >
                    <Typography className="normal-default-text">
                      {row.original.getName()}
                    </Typography>
                  </a>
                </div>
              </div>
            );
          },
        },
        {
          id: "status",
          minWidth: 40,
          maxWidth: "fit-content",
          Cell: (row) => (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "table",
                textAlign: "right",
              }}
            >
              <div style={{ verticalAlign: "middle", display: "table-cell" }}>
                {row.original.getStatus()}
              </div>
            </div>
          ),
        },
      ];
    }
  };

  handleShowNext = async () => {
    let { page } = this.state;
    page += 1;
    const pagePhotos = await this.initializePage(
      page,
      this.state.sortedPhotos,
      this.state.sortedUploadedPhotos
    );
    this.setState({ page, pagePhotos });
  };

  async initializePage(page, sortedPhotos = [], sortedUploadedPhotos = []) {
    const { pagePhotos } = this.state;
    removeThumbnails(pagePhotos);

    const startIndex = PHOTO_SUMMARY_PAGE_SIZE * page;
    const sortedAllPhotos = sortedUploadedPhotos.concat(sortedPhotos);
    const newPagePhotos = sortedAllPhotos.slice(
      startIndex,
      startIndex + PHOTO_SUMMARY_PAGE_SIZE
    );
    await createThumbnails(newPagePhotos);
    return this.getNonPlaceholderPhotos(newPagePhotos);
  }

  getNonPlaceholderPhotos = memoize((photos = [], uploadedPhotos = []) =>
    uploadedPhotos
      .concat(photos)
      .filter((photo) => !(photo instanceof PhotoPlaceHolder))
  );

  handleShowPrevious = async () => {
    let { page } = this.state;
    page -= 1;
    const pagePhotos = await this.initializePage(
      page,
      this.state.sortedPhotos,
      this.state.sortedUploadedPhotos
    );
    this.setState({ page, pagePhotos });
  };

  toggleChecked = (event) => {
    if (event && event.target) {
      event.target.focus();
      event.target.blur();
    }
    localStorage.largeUploadPhotos = !this.state.largePhotos;
    this.setState({ largePhotos: !this.state.largePhotos });
  };

  render() {
    const { classes, total, remaining, supportLargeThumbnails, type } =
      this.props;
    const {
      sortedPhotos,
      sortedUploadedPhotos,
      pagePhotos,
      page,
      largePhotos,
    } = this.state;
    const allPhotosCount = sortedPhotos.length + sortedUploadedPhotos.length;
    const isLoading = total > 0 && allPhotosCount > 0 && pagePhotos <= 0;

    return (
      <Fragment>
        <Hidden xsDown>
          <Grid
            container
            direction={"row"}
            justify={"space-between"}
            alignItems={"center"}
            wrap={"nowrap"}
          >
            {supportLargeThumbnails && (
              <Grid item>
                <FormControlLabel
                  style={{ marginLeft: 16 }}
                  control={
                    <Switch
                      size="small"
                      checked={largePhotos}
                      className={classes.switchStyle}
                      onChange={this.toggleChecked}
                    />
                  }
                  label={
                    <Typography
                      className={`nonessential-item-title ${classes.standardDefaultStyle}`}
                      id={"newItem.largeThumbnail.label"}
                    />
                  }
                />
              </Grid>
            )}
            <Grid item>
              <Typography
                className={`nonessential-item-title ${classes.headerStyle}`}
                id={
                  total > 0
                    ? "newItem.photoSummary.label"
                    : "newItem.photoComplete.label"
                }
                values={{
                  type,
                  uploaded: remaining,
                  total: total || allPhotosCount,
                }}
              />
            </Grid>
          </Grid>
        </Hidden>
        <div className={classes.divStyle}>
          <Grid
            container
            className={classes.root}
            style={{ display: allPhotosCount > 0 ? undefined : "none" }}
            direction={"column"}
            wrap={"nowrap"}
          >
            {page > 0 && (
              <Button
                className={"secondary-minimal-button-small no-print"}
                variant={"text"}
                style={{ width: 100, height: 36 }}
                onClick={this.handleShowPrevious}
                disableRipple={true}
                disabled={page <= 0}
              >
                <Typography color="inherit" variant="button">
                  Show More
                </Typography>
              </Button>
            )}
            <ReactTable
              className={classNames(this.props.classes.table2, "photo-list", {
                [this.props.classes.emptyHeight]: pagePhotos.length <= 0,
              })}
              defaultPageSize={PHOTO_SUMMARY_PAGE_SIZE}
              LoadingComponent={() =>
                isLoading && (
                  <CircularProgress className={classes.progressStyle} />
                )
              }
              minRows={0}
              sortable={false}
              resizable={false}
              multiSort={false}
              noDataText={""}
              data={pagePhotos}
              showPagination={false}
              getTrProps={this.props.trProps}
              style={{ height: pagePhotos.length > 0 ? undefined : "100%" }}
              columns={this.getColumns()}
            />
            {allPhotosCount > PHOTO_SUMMARY_PAGE_SIZE &&
              pagePhotos.length > 0 && (
                <Button
                  className={"secondary-minimal-button-small no-print"}
                  variant={"text"}
                  style={{ width: 100, height: 36 }}
                  onClick={this.handleShowNext}
                  disableRipple={true}
                  disabled={
                    (page + 1) * PHOTO_SUMMARY_PAGE_SIZE >= allPhotosCount
                  }
                >
                  <Typography color="inherit" variant="button">
                    Show More
                  </Typography>
                </Button>
              )}
          </Grid>
        </div>
      </Fragment>
    );
  }
}

export default withWidth()(withStyles(styles)(UploadPhotoSummary));
