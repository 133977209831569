
import { MuiThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {IntlProvider} from 'react-intl';

const CURRENT_LOCALE = 'en-US';

function normalizeLocale(locale) {
   let locales = locale.replace(/_/g, '-').split('-');
   let normalizedLocale = locales[ 0 ];
   if (locales.length > 1) {
      normalizedLocale += '-' + locales[ 1 ].toUpperCase();
   }

   return normalizedLocale;
}

export function getCurrentLocale() {
   try {
      let locale = window.navigator.languages ? window.navigator.languages[ 0 ] :
         window.navigator.language || window.navigator.userLanguage;
      return normalizeLocale(locale);
   } catch (e) {
      return CURRENT_LOCALE;
   }
}

const formats = {
   number: {
      USD: {
         style: 'currency',
         currency: 'USD'
      }
   }
};

/**
 * The main entry point for the application.
 */
export default class BasicApp extends Component {
   static propTypes = {
      locale: PropTypes.string,        // The locale to be used. Default is set to the browser's locale.
      messages: PropTypes.object,      // Application message file.
      theme: PropTypes.object,         // Overall application theme.
      apolloUri: PropTypes.string,     // Required for application using Apollo. Defaults to production: '/api/graphql/'
                                       // development: 'http://localhost:8081/api/graphql/
      children: PropTypes.any.isRequired, // Usually the routes that the application supports.
   };

   static defaultProps = {
      locale: getCurrentLocale(),
      messages: {},
      theme: {},
      apolloUri: process.env.NODE_ENV === 'production' ? '/api/graphql/' : 'http://localhost:8081/api/graphql/'
   };

   constructor(props) {
      super(props);

      this.messages = BasicApp.getMessages(props.messages, props.locale);
      props.theme.spacing = undefined;
      this.theme = createTheme(props.theme);
   }

   static getMessages = (messages, locale) => {
      let appMessages;
      try {
         appMessages = require('./' + locale);
      } catch (e) {
         appMessages = {};
      }

      return { locale, messages: { ...appMessages, ...messages } };
   };

   render() {
      const { locale, children } = this.props;

      // Wrapper HOCs that are always used.
      return (
         <IntlProvider locale={locale} messages={this.messages.messages} formats={formats} textComponent='span'>
            <MuiThemeProvider theme={this.theme}>
               {children}
            </MuiThemeProvider>
         </IntlProvider>
      );
   }
}
