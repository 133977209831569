import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import Typography from '../../fhg/components/Typography';
import { FTResetPasswordForm, FTResetPasswordRequestForm } from '../forms';
import { MainAppBar } from '../MainAppBar';

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    height: '100vh',
    backgroundColor: theme.palette.brand.gray,
    margin: 'auto'
  },
  mainContainer: {
    width: '100%',
    height: 'calc(100% - 80px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  content: {
    width: '30%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.environment.light.level1.base,
    borderRadius: '8px',
    padding: theme.spacing(4)
  },
  titleStyle: {
    textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
}));

interface Props{
  onStateChange: (state: string, data: any) => void;
  authState: string;
}
const ResetPassword: React.FC<Props> = ({ onStateChange,authState }) => {
  const classes = useStyles();
  const [showCode, setShowCode] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");

  const handleSetShowCode = (show: boolean, val: string) => {
    setEmail(val);
    setShowCode(show);
  }

  if (authState !== 'forgotPassword') { return null; }

  return (
    <div className={classes.root}>
      <MainAppBar />
      <div className={classes.mainContainer}>
        <div className={classes.content}>
          <Typography
            className={`${classes.titleStyle} level-2-heading`}
            variant={'h4'}
            id={'reset.title'}
          >Reset Your Password</Typography>
          {showCode ? <FTResetPasswordForm email={email} onStateChange={onStateChange} /> : <FTResetPasswordRequestForm handleSetShowCode={handleSetShowCode} handleGoBack={() => { }} />}
        </div>
      </div>
    </div>
  )
}

export default ResetPassword;
