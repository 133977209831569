import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import Multiselect from 'multiselect-react-dropdown';
import Container from "@mui/material/Container";
import Typography from '../../fhg/components/Typography';


const useStyles:any = makeStyles(theme => ({
    container:{
        padding:'0px !important',
        marginTop:'8px !important'
    },
    fullWidth:{
        width:'100%'
    },
    select:{
        '&.searchBox':{
            height:'45px !important'
        }
    }
}));


interface Props{
    label?:string;
    selectedValues:any[];
    onSelect:()=>void;
    onRemove:()=>void;
    options: any[];
    isObject?: boolean;
    displayValue?:string;
    disabled?:boolean;
    singleSelect?:boolean;
}
const FTDropdown:React.FC<Props> = ({selectedValues,options,label,displayValue,isObject,disabled,singleSelect,onSelect,onRemove}) => {
    const classes = useStyles();
    return(
        <Container className={classNames(classes.container, classes.fullWidth)}>
            {label && <Typography id={label} className={classNames(classes.mediumTopPad, classes.fullWidth, 'label-text')}/>}
            <Multiselect 
                isObject={isObject}
                options={options}
                selectedValues={selectedValues}
                onSelect={onSelect}
                onRemove={onRemove}
                displayValue={displayValue}
                disable={disabled}
                singleSelect={singleSelect}
                style={{
                    searchBox: {
                       minHeight:'45px'
                      }
                }}
            />
        </Container>
    )
}

export default FTDropdown;