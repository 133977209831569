import { withStyles } from "@material-ui/core/styles";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ErrorSnackbar from "../components/eval/components/ErrorSnackbar";
import FormattedTextField from "../components/eval/components/FormattedTextField";
import { EDIT_USER} from "../Constants";
import ModalDialog from "../fhg/components/dialog/ModalDialog";
import Typography from "../fhg/components/Typography";
import { requestForServer } from "../Utils/ServerUtil";
import PropTypes from "prop-types";
import eventCapture from "../eventList";
import { posthogCapture } from "../PostHogFunction";

const styles = (theme) => ({
  fullWidthInput: {
    marginTop: `${theme.spacing(0.5)}px !important`,
    height: 30,
    width: "100% !important",
  },
  mediumTopPad: {
    marginTop: theme.spacing(1.5),
  },
});

/**
 * The New edit dialog which edit a new user.
 */
class EditUserDialog extends Component {
  static propTypes = {
    editUserSelected: PropTypes.string,
    userDetails: PropTypes.object
    //group: PropTypes.object.isRequired, // The group to add the user to.
  };

  state = {
    name: "",
    isSaving: false,
    isChanged: false,
  };

  /**
   * Handle changes to the user.
   * @param target The target of the event that triggered the change.
   */
  handleChange = ({ target }) => {
    this.setState({ [target.name]: target.value, isChanged: true });
  };

  /**
   * Submits the changes to the user.
   */
  onSubmit = async (event) => {
    event.preventDefault();
    let error;
    let errorValues;
    let user = {};
    posthogCapture(eventCapture.SAVE_NEW_USER);

    const { isChanged, isSaving } = this.state;
    const { onClose, onSubmit,  editUserSelected } = this.props;

    if (isChanged && !isSaving) {
      this.setState({ isSaving: true });
      try {
        user.name = this.state.name || undefined;
        user.email = this.state.email || undefined;
       // user.password = this.state.password || undefined;

        const resultUser = await requestForServer(
            EDIT_USER.format({
                userId: editUserSelected
                })
            , "patch", user);
        if (!resultUser.error) {
          //window?.posthog?.capture(eventCapture.SAVE_NEW_USER);
          posthogCapture(eventCapture.SAVE_NEW_USER)
          user = resultUser.data;

          window.location.reload();
       
        } else {
          error = resultUser.error;
          errorValues = resultUser.errorValues;
        }
      } catch (err) {
        error = err;
      }

      if (error) {
        if (errorValues) {
          this.setState({
            isSaving: false,
            showError: true,
            message: errorValues.message,
          });
        } else {
          this.setState({
            isSaving: false,
            showError: true,
            message: error.message,
          });
        }
      } else {
        this.setState({ isSaving: false, isChanged: false }, () => {
          onSubmit && onSubmit(user);
        });
        onClose();
      }
    }
  };

  /**
   * Close the error snackbar.
   */
  handleErrorClose = () => {
    this.setState({ showError: false });
  };

  render() {
    const { classes, open, onClose, userDetails } = this.props;
    const { isSaving, showError, message } = this.state;

    return (
      <ModalDialog
        open={open}
        onClose={onClose}
        onSubmit={this.onSubmit}
        titleKey={"editUser.main.title"}
        isSaving={isSaving}
        submitKey={"editUser.submit.label"}
        isForm
      >
        {showError && (
          <ErrorSnackbar
            open={showError}
            onClose={this.handleErrorClose}
            errorId={"editUser.save.error"}
            values={{ message }}
          />
        )}
        <Typography
          id="editUser.name.label"
          className={`${classes.mediumTopPad} label-text`}
        />
        <FormattedTextField
          name="name"
          //required
          autoFocus
          fullWidth
          value={this.state.name}
          onChange={this.handleChange}
          margin="none"
          placeholder={userDetails.name ? userDetails.name : "Doug Jones"}
          inputProps={{ className: classes.fullWidthInput }}
          disabled={isSaving}
        />
        <Typography
          id="editUser.email.label"
          className={`${classes.mediumTopPad} label-text`}
        />
        <FormattedTextField
          name="email"
          type="email"
          //required
          fullWidth
          value={this.state.email}
          onChange={this.handleChange}
          margin="none"
          placeholder={userDetails.name ? userDetails.email : "doug@acme.com"}
          inputProps={{ className: classes.fullWidthInput }}
          disabled={isSaving}
        />
        {/* <Typography
          id="editUser.password.label"
          className={`${classes.mediumTopPad} label-text`}
        /> */}
        {/* <FormattedTextField
          name="password"
          // type={'password'}
          fullWidth
          //required
          disabled={isSaving}
          autoComplete="current-password"
          onChange={this.handleChange}
          value={this.state.password}
          inputProps={{ className: classes.fullWidthInput }}
        /> */}
      </ModalDialog>
    );
  }
}

export default withRouter(withStyles(styles)(EditUserDialog));
