export default {
  typography: {
    fontFamily: "\"Helvetica Neue\", \"Helvetica\", \"Arial\", \"sans-serif\"",
    fontSize: 16,
    lineHeight: "1.5",
    display2: {
      fontSize: "2.5rem",
      fontWeight: 300,
      lineHeight: "2.5rem",
      color: "#302C22"
    },
    display1: {
      fontSize: "1.875rem",
      fontWeight: 300,
      lineHeight: "2.125rem",
      color: "#302C22"
    },
    body2: {
      fontSize: "1rem",
      lineHeight: "1.5",
      letterSpacing: "0",
      fontWeight: 400
    },
    body1: {
      fontSize: "1rem",
      lineHeight: "1.125",
      letterSpacing: "0",
      fontWeight: 400
    },
    button: {
      textTransform: "none",
      fontSize: "0.875rem",
      fontWeight: "bold",
      lineHeight: "1.25",
      letterSpacing: "0"
    }
  },
  palette: {
    text: {
      primary: "#262527",
      secondary: "rgba(38,37,39,0.80)",
      disabled: "rgba(164, 167, 168, 0.3)",
      hint: "rgba(0, 0, 0, 0.38)"
    },
    divider: "#B1AFB3",
    secondary: {
      main: "#262527",
      contrastText: "#E6E3DC"
    },
    error: {
      light: "#c66",
      main: "#CE4543",
      backgroundColor: "#EEE"
    },
    action: {
      hover: "rgba(194,227,255,0.60)",
      selected: "#C2E3FF",
      selectedDark: "#75bfff",
      disabled: "rgba(164, 167, 168, 0.3)"
    },
    brand: {
      yellow: "#FFDD50",
      blue: "#4986B3",
      gray: "#59585A",
      tan: "#FDFBFF",
      brown: "#262527"
    },
    button: {
      standard: {
        label: {
          default: "#FFFFFF",
          subtle: "#2C526E",
          disabled: "rgba(29, 36, 38, 0.2)"
        },
        primary: "#4986B3",
        secondary: "#165380",
        subtle: "rgba(73,124,179,0.25)",
        destroy: "#CE4543",
        success: "#2DA970",
        warn: "#FF8E1C",
        disabled: "rgba(164, 167, 168, 0.3)"
      },
      minimal: {
        primary: "#4986B3",
        secondary: "#165380",
        subtle: "rgba(73,133,179,0.70)",
        destroy: "#CA371D",
        success: "#218556",
        warn: "#DB7916",
        disabled: "rgba(97,130,142,0.25)",
        cancel: "#878e94"
      },
      checkbox: {
        checked: "#11A1FF"
      },
      pin: "#B0A99B"
    },
    content: {
      contrast: "#262527",
      default: "rgba(38,37,39,0.80)",
      subtle: "rgba(38,37,39,0.60)",
      nonessential: "rgba(38,37,39,0.40)",
      subtle2: "rgba(38,37,39,0.30)",
      subtle3: "rgba(93, 120, 143, 0.65)",
      dark: "rgba(93, 120, 143, 0.8)"
    },
    selection: {
      hover: "rgba(194,227,255,0.60)",
      select: "#C2E3FF",
      dark: "#75bfff"
    },
    environment: {
      light: {
        level0: {
          base: "#FFFFFF",
          accent: "#E4E2E6"
        },
        level1: {
          base: "#FDFBFF",
          accent: "#CAC8CC"
        },
        level2: {
          base: "#FDFBFF",
          accent: "#B1AFB3"
        },
        level3: {
          base: "#CAC8CC",
          accent: "#979599"
        },
        level4: {
          base: "#DAE4EB",
          accent: "#7E7C80",
          accent2: "#646266"
        },
        divider: "#B1AFB3"
      },
      dark: {
        level0: {
          base: "#262527",
          accent: "#424143"
        },
        level1: {
          base: "#424143",
          accent: "#5E5D5F"
        },
        level2: {
          base: "#5E5D5F",
          accent: "#7A797B"
        },
        level3: {
          base: "#7A797B",
          accent: "#969597"
        },
        divider: "#5E5D5F"
      }
    },
    status: {
      captureInProgress: "#7ED321",
      evalRequested: "#F8CE1C",
      evalInProgress: "#4A90E2",
      inReview: "#F57A23",
      finalized: "#406370",
      archived: "#D8D8D8"
    }
  },
  spacing: {
    unit: 8
  },
  size: {
    font: {
      text: "1",
      "text-tight": "1",
      "text-small": "0.875",
      "text-micro": "0.75",
      numbers: "1",
      label: "1",
      "label-small": "0.875",
      "item-title": "0.75",
      "item-title-small": "0.625",
      "item-title-caps": "0.75",
      navigation: "1.125"
    },
    letterspacing: {
      text: "0",
      "text-tight": "0",
      "text-small": "0",
      "text-micro": "0",
      numbers: "0.5",
      label: "0",
      "label-small": "0",
      "item-title": "0",
      "item-title-small": "0.8",
      "item-title-caps": "0",
      navigation: "0"
    },
    lineheight: {
      text: "1.5",
      "text-tight": "1.125",
      "text-small": "1.25",
      "text-micro": "1",
      numbers: "1.5",
      label: "1.125",
      "label-small": "1.25",
      "item-title": "0.75",
      "item-title-small": "0.625",
      "item-title-caps": "1",
      navigation: "1.375"
    },
    space: {
      eighth: "0.125",
      quarter: "0.25",
      half: "0.5",
      one: "1",
      "one-and-half": "1.5",
      two: "2",
      three: "3",
      four: "4"
    }
  }
}
