import {compact, mean, groupBy, filter} from 'lodash';
import numberFormatter from 'number-formatter';
import PropTypes from 'prop-types';
import React, {Fragment, useMemo} from 'react';
import Typography from '../../../fhg/components/Typography';
import '../../../otherStyles.scss';
import {getEstimateLabel, getWorkflowEnabled} from '../../../Utils/ServerUtil';

/**
 * The component to display other estimates inside another panel.  Similar to OtherEstimatesDialog, but contains
 * different styling.
 */
export default function OtherEstimates({estimates}) {

   const groupedEstimates = useMemo(() => {
      if (estimates && estimates.length > 0) {
         const filteredEstimates = filter(estimates, getWorkflowEnabled);
         return groupBy(filteredEstimates, estimate => estimate.estimate_type_name);
      } else {
         return {};
      }
   })
   // const values = estimates.map(estimate => estimate.value);
   // const meanValue = mean(compact(values)) || '';

   return (
      <div className={'table'}>
         {Object.entries(groupedEstimates).map(([key, estimates]) => {
            const values = estimates.map(estimate => estimate.value);
            const meanValue = mean(compact(values)) || '';
            return (
            <Fragment>
               <Typography className={'nonessential-item-title'} style={{marginTop: 8}}>
                  {getEstimateLabel(key)}
               </Typography>
               {estimates && estimates.map((estimate) => {
                  if (getWorkflowEnabled(estimate) && estimate.value >= 0) {
                     const name = estimate.user_name || estimate.user_email || estimate.user;
                     return (
                        <div className={'table-row'}>
                           <div className={'table-cell'}>
                              <Typography className={'normal-default-text'}>
                                 {name}
                              </Typography>
                           </div>
                           <div className={'table-cell'}>
                              <Typography className={'normal-default-text right-align'}>
                                 {numberFormatter('$#,###,##0.', estimate.value)}
                              </Typography>
                           </div>
                        </div>
                     )
                  } else {
                     return undefined;
                  }
               })}
               <div className={'table-row'}>
                  <div className={'table-cell'}>
                     <Typography className={'bold-default-text'}>
                        Estimate Average
                     </Typography>
                  </div>
                  <div className={'table-cell'}>
                     <Typography className={'bold-default-text right-align'}>
                        {numberFormatter('$#,###,##0.', meanValue)}
                     </Typography>
                  </div>
               </div>
            </Fragment>
         )})}
      </div>
   );
}

OtherEstimates.propTypes = {
   estimates: PropTypes.any,
}

OtherEstimates.defaultProps = {
   estimates: [],
}

