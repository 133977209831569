import { Checkbox } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { withRouter } from "react-router-dom";
import Typography from "../../../fhg/components/Typography";
import { ImageList } from '@material-ui/core'
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from "classnames";
import get from "lodash/get";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import {
  THUMBNAIL_SIZE,
  PLACEHOLDER_IMAGE,
  IMAGE_VIEW_KEYS,
  IMAGE_VIEW_CONVERT,
} from "../../../Constants";
import { CheckboxBackground, CheckboxSelected } from "../../../Icons";
import PhotoMoreMenu from "../components/PhotoMoreMenu";
import { Modal } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Auth } from "aws-amplify";
import { isAdminOnly, isMultipleOrganization } from "../../../Utils/ServerUtil";

const checkboxStyles = (theme) => ({
  root: {
    color: `${theme.palette.environment.light.divider} !important`,
    "&$checked": {
      color: `${theme.palette.brand.blue} !important`, //#489AC7
    },
  },
  checked: {},
});

function CustomCheckbox4(props) {
  return (
    <Checkbox
      color="default"
      checkedIcon={<CheckboxSelected />}
      icon={
        <CheckboxBackground
          stroke={props.theme.palette.environment.light.divider}
        />
      }
      {...props}
    />
  );
}

export const CustomCheckboxSolid = withStyles(checkboxStyles, {
  withTheme: true,
})(CustomCheckbox4);

const styles = (theme) => ({
  verticalThumbs: {
    height: "100%",
    width: THUMBNAIL_SIZE + 8,
    display: "block",
    padding: "0 4px",
    marginLeft: "4px !important",
    marginTop: "0px !important",
    borderLeft: `1px solid ${theme.palette.divider}`,
  },
  horizontalThumbs: {
    height: THUMBNAIL_SIZE,
    width: "100%",
    flexWrap: "nowrap",
    flex: "0 0 auto",
    paddingTop: 4,
    marginLeft: "0px !important",
    marginTop: "4px !important",
    marginBottom: "7px !important",
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  titleBar: {
    background:
      "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  moreTitleBar: {
    background: "transparent",
    width: "100%",
  },
  titleText: {
    fontSize: `${theme.size.font["item-title"]}rem`,
    lineHeight: `${theme.size.lineheight["item-title"]}rem`,
    letterSpacing: `${theme.size.letterspacing["item-title"]}rem`,
  },
  actionIconStyle: {
    width: "100%",
  },
  titleWrap: {
    display: "none",
  },
  imageType: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
    bottom: 0,
    position: "absolute",
    color: "white",
    textAlign: "center",
    width: "100%",
    paddingBottom: 4,
    marginLeft: 4,
  },
});

class ThumbnailList extends PureComponent {
  static propTypes = {
    classes: PropTypes.any,
    thumbColumns: PropTypes.number,
    images: PropTypes.array,
    selectedImage: PropTypes.object,
    onDeletePhoto: PropTypes.func,
    onRefresh: PropTypes.func,
    onSelect: PropTypes.func,
    onSelectThumbnail: PropTypes.func,
    onMovePhoto: PropTypes.func,
    selected: PropTypes.array,
    onSortAllPhotos: PropTypes.func,
    evalItem: PropTypes.object,
  };

  state = {
    currentVideos: this.props.evalItem.attachment_data || [],
    isVideoModalOpen: false,
    selectedVideo: null,
    thumnailImages: [],
    selectedThumbnailIndex: 0, // New state to track the selected index
  };

  thumbnailRefs = [];

  componentDidMount = () => {
    this.imageShuffle();
    this.latestImageData();

    // Add event listener for keyboard navigation
    document.addEventListener("keydown", this.handleKeyDown);
  };

  componentWillUnmount = () => {
    // Remove event listener when the component is unmounted
    document.removeEventListener("keydown", this.handleKeyDown);
  };

  handleKeyDown = (event) => {
    const { selectedThumbnailIndex, thumnailImages, currentVideos } =
      this.state;
    const totalItems = thumnailImages.length + currentVideos.length;

    if (event.key === "ArrowRight") {
      // Move to the next thumbnail
      const nextIndex = (selectedThumbnailIndex + 1) % totalItems;
      this.setState({ selectedThumbnailIndex: nextIndex }, () => {
        this.scrollThumbnailIntoView(nextIndex); // Scroll into view
      });
    } else if (event.key === "ArrowLeft") {
      // Move to the previous thumbnail
      const prevIndex = (selectedThumbnailIndex - 1 + totalItems) % totalItems;
      this.setState({ selectedThumbnailIndex: prevIndex }, () => {
        this.scrollThumbnailIntoView(prevIndex); // Scroll into view
      });
    }
  };

  scrollThumbnailIntoView = (index) => {
    // Use the ref to scroll the selected thumbnail into view
    if (this.thumbnailRefs[index]) {
      this.thumbnailRefs[index].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };

  latestImageData = async () => {
    let jwtToken;
    let tokenRefreshError = false;
    try {
      const session = await Auth.currentSession();
      jwtToken = session.idToken.jwtToken;
    } catch (e) {
      try {
        const session = await Auth.currentSession();
        jwtToken = session.idToken.jwtToken;
      } catch (e) {
        //console.log("Error refreshing token", e);
        tokenRefreshError = true;
        // Allow to go through without jwtToken for server requests not requiring an authenticated user.
      }
    }

    const headers = {
      authorization: jwtToken,
      accept: "application/json",
    };
    // This adds a header for selected organization_ID or _Key which is stored in local storage
    if (isAdminOnly() || isMultipleOrganization()) {
      const organization = localStorage["hw." + window.btoa("organization")];
      if (organization && window.atob(organization)) {
        headers["HW-Organization"] = window.atob(organization);
      }
    }
    const options = {
      method: "GET",
      headers: headers,
    };

    const url = `${process.env.REACT_APP_API_ENDPOINT}/items/${this.props.evalItem.item_id}`;

    // console.log("url====> ",url);
    try {
      const response = await fetch(url, options);
      if (response.ok) {
        const itemData = await response.json();
        console.log("Check Image Data ========>", itemData.image_data);
        this.setState({ thumnailImages: itemData.image_data });
      } else {
        // Handle the error if the response is not ok
        console.log("Request failed with status:", response.status);
        return null;
      }
    } catch (error) {
      // Handle any network or other errors
      console.error("Error:", error);
    }
  };

  imageShuffle = () => {
    // Clone the currentImages array
    const clonedImages = [...this.state.thumnailImages];

    // Custom sorting function to place "AMKT" first
    const customSort = (a, b) => {
      if (a.image_view === "AMKT") {
        return -1; // "AMKT" comes first
      }
      if (b.image_view === "AMKT") {
        return 1; // "AMKT" comes first
      }
      return 0; // Keep the original order for other items
    };

    // Sort the clonedImages array using the customSort function
    clonedImages.sort(customSort);

    // Update the component state with the sorted array
    this.setState({ thumnailImages: clonedImages });
    //console.log("IMAGES===========================>>>>>>>" ,this.state.currentImages);
  };

  handleSelect = (index) => (event) => {
    const { onSelectThumbnail, selected } = this.props;
    event.stopPropagation();
    event.preventDefault();

    const selectedArray = selected.slice(0);
    selectedArray[index] = event.target.checked;
    onSelectThumbnail && onSelectThumbnail(selectedArray);
  };

  handleVideoThumbnailClick = (video) => {
    this.setState({
      isVideoModalOpen: true,
      selectedVideo: video,
    });
  };

  handleVideoModalClose = () => {
    this.setState({
      isVideoModalOpen: false,
      selectedVideo: null,
    });
  };

  render() {
    const {
      classes,
      location,
      thumbColumns,
      selected,
      selectedImage,
      onDeletePhoto,
      onSelect,
      onRefresh,
      evalItem,
      onMovePhoto,
      onSortAllPhotos,
    } = this.props;

    const { thumnailImages, currentVideos, selectedThumbnailIndex } =
      this.state;

    const isEdit = get(location, "state.isEdit", false);

    return (
      <>
        <ImageList
          id="thumbnailListId"
          className={classes.horizontalThumbs}
          cols={5}
          cellHeight={THUMBNAIL_SIZE}
        >
          {thumnailImages.concat(currentVideos).map((item, index) => {
            const isVideo = Boolean(item.url);
            const thumbnail = isVideo
              ? get(item, "url", PLACEHOLDER_IMAGE)
              : get(item, "sizes.thumbnail", PLACEHOLDER_IMAGE);

            const imageViewKey =
              IMAGE_VIEW_KEYS[item.image_view] ||
              IMAGE_VIEW_KEYS[IMAGE_VIEW_CONVERT[item.image_view]] ||
              item.image_view;

            return (
              <GridListTile
                key={index}
                className={classNames({
                  "selected-thumbnail": selectedThumbnailIndex === index,
                  [classes.zoomThumb]: true,
                })}
                style={{ position: "relative" }}
                ref={(ref) => (this.thumbnailRefs[index] = ref)} // Assign ref to each thumbnail
                onClick={() => {
                  this.setState({ selectedThumbnailIndex: index }); // Set selected index
                  isVideo
                    ? this.handleVideoThumbnailClick(item)
                    : onSelect(item);
                }}
              >
                {isVideo ? (
                  <video controls width="200" height="150" poster={thumbnail}>
                    <source src={thumbnail} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img
                    src={thumbnail}
                    alt=""
                    onError={(e) => {
                      e.target.src = PLACEHOLDER_IMAGE;
                    }}
                    onClick={onSelect(item)}
                  />
                )}

                <Typography
                  id={
                    isVideo
                      ? IMAGE_VIEW_KEYS.VIDEO
                      : IMAGE_VIEW_KEYS[item.image_view] ||
                        IMAGE_VIEW_KEYS[IMAGE_VIEW_CONVERT[item.image_view]] ||
                        item.image_view ||
                        "photoDetail.changeCategory.additional"
                  }
                  className={classes.imageType}
                />

                {isEdit ? (
                  <GridListTileBar
                    classes={{ title: classes.titleText }}
                    title={
                      <Typography
                        id={
                          isVideo
                            ? IMAGE_VIEW_KEYS.VIDEO
                            : IMAGE_VIEW_KEYS[item.image_view] ||
                              IMAGE_VIEW_KEYS[
                                IMAGE_VIEW_CONVERT[item.image_view]
                              ] ||
                              item.image_view ||
                              "photoDetail.changeCategory.additional"
                        }
                      />
                    }
                    titlePosition="top"
                    className={classes.titleBar}
                  />
                ) : (
                  !item.__isPlaceholderImage &&
                  imageViewKey !== "photoDetail.changeCategory.unknown" && (
                    <GridListTileBar
                      titlePosition="top"
                      actionPosition="left"
                      className={classes.moreTitleBar}
                      classes={{
                        actionIcon: classes.actionIconStyle,
                        titleWrap: classes.titleWrap,
                      }}
                      actionIcon={
                        isVideo ? null : (
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                          >
                            <CustomCheckboxSolid
                              key={
                                selected[index]
                                  ? `${index}-select`
                                  : `${index}-not-selected`
                              }
                              checked={selected[index]}
                              style={{ zIndex: 1002 }}
                              onClick={this.handleSelect(index)}
                              icon={<CheckboxBackground />}
                            />
                            <PhotoMoreMenu
                              image={item}
                              onDeletePhoto={() => onDeletePhoto(item)}
                              onMovePhoto={() => onMovePhoto(item)}
                              onRefresh={onRefresh}
                              isFirst={index === 0}
                              isLast={
                                index >=
                                thumnailImages.length + currentVideos.length - 1
                              }
                              onSortAllPhotos={onSortAllPhotos}
                              evalItem={evalItem}
                              index={index}
                            />
                          </Grid>
                        )
                      }
                    />
                  )
                )}
              </GridListTile>
            );
          })}
        </ImageList>
        <Modal
          open={this.state.isVideoModalOpen}
          onClose={this.handleVideoModalClose}
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              maxWidth: "80vw",
              maxHeight: "80vh",
              width: "auto",
              height: "100%",
              overflow: "auto",
            }}
          >
            {this.state.selectedVideo && (
              <video controls style={{ width: "100%", height: "100%" }}>
                <source src={this.state.selectedVideo.url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}

            {/* Close Button */}
            <IconButton
              aria-label="close"
              style={{
                position: "fixed",
                top: "8px",
                right: "8px",
                color: "white",
              }}
              onClick={this.handleVideoModalClose}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </Modal>
      </>
    );
  }
}

export default withRouter(withStyles(styles)(ThumbnailList));
