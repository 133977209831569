import { Auth } from 'aws-amplify';
import { isAdminOnly, isMultipleOrganization } from "../../../../Utils/ServerUtil";

interface FetchDataParams {
  selectedEmployees: string[];
  filter: string[];
  pageNumber: number;
  rowsPerPage: number;
}

const fetchData = async ({
  selectedEmployees,
  filter,
  pageNumber,
  rowsPerPage
}: FetchDataParams): Promise<any> => {
  let jwtToken: string | undefined;
  // let tokenRefreshError = false;

  try {
    const session: any = await Auth.currentSession();
    jwtToken = session.idToken.jwtToken;
  } catch (e) {
    try {
      const session: any = await Auth.currentSession();
      jwtToken = session.idToken.jwtToken;
    } catch (e) {
      // tokenRefreshError = true;
    }
  }

  const headers: Record<string, string> = {
    authorization: jwtToken || '',
    accept: "application/json",
  };

  if (isAdminOnly() || isMultipleOrganization()) {
    const organization = localStorage.getItem("hw." + window.btoa("organization"));
    if (organization && window.atob(organization)) {
      headers["HW-Organization"] = window.atob(organization);
    }
  }

  const options: RequestInit = {
    method: "GET",
    headers: headers,
  };

  const employeesSelected = selectedEmployees?.join(",");
  const mainStatus = filter.join(",");

  const url = `${process.env.REACT_APP_API_ENDPOINT_V3}/packages?page=${
    pageNumber + 1
  }&size=${
    rowsPerPage
  }&by_user=${employeesSelected}&status=${mainStatus}`;

  console.log("=========== Checking FetchData ============", 
   employeesSelected)

  try {
    const response = await fetch(url, options);
    if (response.ok) {
      const evalData = await response.json();
      //console.log("TEST FROM API==============>>>>>>>>>>>>>>",evalData);
      return evalData;
      
      
    } else {
      console.log("Request failed with status:", response.status);
      return null;
    }
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
};


export default fetchData;