import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {get} from 'lodash';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {SEND_TO_PURPLEWAVE} from '../../../Constants';
import ModalDialog from '../../../fhg/components/dialog/ModalDialog';
import Typography from '../../../fhg/components/Typography';
import Feature from '../../../fhg/security/Feature';
import NotFeature from '../../../fhg/security/NotFeature';
import {CUSTOMER_ENV, requestForServer} from '../../../Utils/ServerUtil';
import CollaboratorPopover from './CollaboratorPopover';
import WorkflowStatusSelect from './WorkflowStatusSelect';

const useStyles = makeStyles(theme => ({
   defaultTextStyle: {
      color: theme.palette.text.secondary,
   },
   ownerStyle: {
      composes: 'normal-default-text',
      fontSize: `${theme.size.font['text-small']}rem !important`,
   },
   smallContainer: {
      display: 'flex',
      flexWrap: 'nowrap',
      boxSizing: 'border-box',
   },
   verticalDividerStyle: {
      borderLeft: `1px solid ${theme.palette.environment.light.level0.accent} !important`,
      height: '100%',
      minHeight: 44,
      width: 2,
      margin: `0 ${theme.spacing(1)}px`,
   },
   collaboratorStyle: {
      cursor: 'pointer',
   },
   paper: {
      padding: theme.spacing(1),
   },
   formStyle: {
      padding: 0,
   }
}));

AssetDetailDialog.propTypes = {
   open: PropTypes.bool,               // Indicates if the dialog should be open.
   onClose: PropTypes.func,            // The callback when the dialog is closed.
   evaluation: PropTypes.object,       // The evaluation from which to display the collaborators.
};

/**
 * Dialog component to display the asset details from the header for small layouts.
 */
export default function AssetDetailDialog({open, onClose, evaluation}) {
   const classes = useStyles();

   const [collaboratorMessage, setCollaboratorMessage] = useState();
   const [collaboratorsAnchorEl, setCollaboratorsAnchorEl] = useState();

   /**
    * Create the collaborators message from the evaluation.
    */
   useEffect(() => {
      let collaboratorMessage;
      let collaboratorCount;

      //Get the number of collaborators. Remove the authenticated user if listed as a collaborator.
      const collaborators = get(evaluation, 'evaluation.subscriptions', []);
      const primaryContactId = get(evaluation, 'evaluation.primary_contact_id');
      if (primaryContactId) {
         const indexOfUser = collaborators.indexOf(primaryContactId);
         collaboratorCount = indexOfUser >= 0 ? collaborators.length - 1 : collaborators.length;
         collaboratorMessage = (
            <Typography variant='inherit' className={'subtle-text-small'} display={'inline'}
                        id='equipmentList.primaryContactCollaborators.label'
                        values={{count: collaboratorCount}}
            />
         );
      } else if (collaborators.length > 1) {
         collaboratorMessage = (
            <Typography variant='inherit' className={'subtle-text-small'} display={'inline'}
                        id='equipmentList.primaryContactCollaboratorsOldEval.label'
            />
         );
      }
      setCollaboratorMessage(collaboratorMessage)
   }, []);

   /**
    * Show the collaborators for the catalog.
    *
    * @param event The event that triggers the show collaborators.
    */
   const handleShowCollaborators = event => {
      setCollaboratorsAnchorEl(event.currentTarget);
   };

   /**
    * Close the collaborators popover.
    */
   const handleCloseCollaborators = () => {
      setCollaboratorsAnchorEl(null);
   };

   /**
    * Send the asset to Purple Wave for estimates.
    */
   const handleSendtoPurpleWave = () => {
      const evalId = get(evaluation, 'evaluation.eval_id', get(evaluation, 'evaluation.PK'));
      requestForServer(SEND_TO_PURPLEWAVE.format({evalId}), 'post');
   };

   return (
      <ModalDialog open={open} onClose={onClose}
                   titleKey={'equipmentDetail.assetDetails.button'}
                   maxWidth={'xs'}
                   fullWidth
                   cancelKey={'close.button'}
                   cancelColorStyle={'primary-color-button'}
                   classes={{formStyle: classes.formStyle}}
      >
         <Grid container direction={'row'}>
            <Grid item>
               <Typography variant='inherit' className={classes.ownerStyle}>
                  {get(evaluation, 'evaluation.owner_name', 'N/A')}
               </Typography>
               <Typography variant='inherit' className={'subtle-text-micro'}>
                  {get(evaluation, 'evaluation.owner', '')}
               </Typography>
            </Grid>
            <Grid container item className={classes.smallContainer} style={{width: 'auto'}}>
               <div className={classes.verticalDividerStyle}/>
               <Grid item>
                  <Typography variant='inherit' className={'nonessential-title-caps'}
                              id={'equipmentList.primaryContact.label'} display={'block'}>
                     Primary Contact
                  </Typography>
                  <Typography variant='inherit' className={`${classes.collaboratorStyle} subtle-text-small`}
                              display={'inline'}
                              onClick={handleShowCollaborators}>
                     {get(
                        evaluation, 'evaluation.primary_contact', 'N/A')}
                  </Typography>

                  <Typography variant='inherit' className={`${classes.collaboratorStyle} subtle-text-small`}
                              display={'inline'}
                              onClick={handleShowCollaborators}>
                     {collaboratorMessage}
                  </Typography>
                  <CollaboratorPopover
                     open={!!collaboratorsAnchorEl}
                     classes={{paper: classes.paper}}
                     evaluation={evaluation}
                     anchorEl={collaboratorsAnchorEl}
                     onClose={handleCloseCollaborators}
                  />
               </Grid>
            </Grid>
            <Grid item xs={12}>
               <Feature feature={CUSTOMER_ENV}>
                  {get(evaluation, 'evaluation.workflow_status') === 'capture_in_progress' ? (
                     <Button className={'subtle-button'} style={{width: 'auto'}}
                             onClick={handleSendtoPurpleWave}>
                        Send to PurpleWave</Button>
                  ) : (
                     <Typography id={'customerInventory.sentToPurpleWave.status'}/>
                  )}
               </Feature>
               <NotFeature feature={CUSTOMER_ENV}>
                  <WorkflowStatusSelect classes={{menuText: classes.menuText}} doHandleChanges
                                        evaluation={evaluation}/>
               </NotFeature>
            </Grid>
         </Grid>
      </ModalDialog>
   );
}

