import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import CloseIcon from '@material-ui/icons/Close';
import Search from '@material-ui/icons/Search';

const styles = theme => ({
   searchStyle: {
      marginRight: theme.spacing(3),
      marginLeft: theme.spacing(3),
      marginBottom: theme.spacing(2),
   },
   secondaryColor: {
      color: theme.palette.button.standard.secondary,
   },
   themeUnderline: {
      '&:hover:not(:disabled):before,&:before': {
         borderColor: `${theme.palette.divider} !important`
      },
      '&:after': {
         borderColor: `${theme.palette.environment.dark.divider} !important`
      }
   },
});

/**
 * Text component for entering a search string.
 */
class SearchField extends PureComponent {
   static propTypes = {
      classes: PropTypes.any,
      onChange: PropTypes.func,
      value: PropTypes.any,
   };

   state = {
      search: '',
   };

   componentWillReceiveProps(nextProps, nextContext) {
      if (nextProps.value === undefined) {
         this.setState({search: ''});
      } else if (nextProps.value !== this.props.value && nextProps.value !== this.state.search) {
         this.setState({search: nextProps.value});
      }
   }

   /**
    * When search data has changed.
    * @param target The change event.
    */
   handleChange = ({target}) => {
      this.setState({search: target.value}, this.handleSearchChange);
   };

   /**
    * When the user presses a key, if it is the escape key clear the search.
    * @param event The key down event.
    */
   onKeydown = (event) => {
      if (event.key === 'Escape') {
         event.stopPropagation();
         event.preventDefault();
         this.onCancelSearch();
      }
   };

   /**
    * When the search is canceled, clear the search field.
    */
   onCancelSearch = () => {
      this.setState({search: ''}, this.handleSearchChange);
   };

   /**
    * After debounce, perform the actual search of data for the search string the user entered.
    * @type {Function} the debounced function.
    */
   handleSearchChange = debounce(async () => {
      const {onChange} = this.props;
      onChange && onChange(this.state.search);
   }, 750);

   render() {
      const {classes} = this.props;
      const {showClose, search} = this.state;

      return (
         <TextField
            className={classes.searchStyle}
            onChange={this.handleChange}
            placeholder={'Search users'}
            value={search}
            onKeyDown={this.onKeyDown}
            InputProps={{
               'aria-label': 'Search',
               onFocus: () => this.setState({showClose: true}),
               onBlur: () => this.setState({showClose: false}),
               endAdornment: (
                  <InputAdornment position='end'>
                     {showClose ?
                        <CloseIcon className={classes.secondaryColor} onMouseDown={this.onCancelSearch}/> :
                        <Search className={classes.secondaryColor}/>
                     }
                  </InputAdornment>
               ),
               classes: {
                  input: classes.textFieldInput,
                  underline: classes.themeUnderline,
               },
            }}
         />
      );
   }
}

export default withStyles(styles)(SearchField);