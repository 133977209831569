import {useState, useEffect} from 'react';
import {editChange} from '../../utils/Utils';

/**
 * Hook for handling default and edit values and the handling callbacks.
 *
 * Review: 8/5/2020
 *
 * @param defaultValuesProp The default values to use.
 * @param requiredEditValues The properties from the default values that are required on the edit values (e.g. id or uuid).
 * @return {[*[]|string[], handleChange, {setDefaultValues: React.Dispatch<React.SetStateAction<*|*[]>>, resetValues: resetValues, setIsChanged: React.Dispatch<React.SetStateAction<boolean>>, isChanged: boolean, defaultValues: (*|*[]), setEditValues: React.Dispatch<React.SetStateAction<*[]|string[]>>}]}
 */
export default function useEditData(defaultValuesProp, requiredEditValues) {
   const [isChanged, setIsChanged] = useState(false);
   const [defaultValues, setDefaultValues] = useState(!!defaultValuesProp ? defaultValuesProp : {});
   const [editValues, setEditValues] = useState(!!requiredEditValues ? requiredEditValues : {});

   useEffect(() => {
       if (!!requiredEditValues) {
          setEditValues({ ...requiredEditValues});
       }
   }, [requiredEditValues]);

   /**
    * Handle onChange events for the inputs.
    *
    * NOTE:
    * Input components MUST have their name set to be set in the editValues.
    *
    * @param event The event that changed the input.
    * @param name the name of the component.
    */
   const handleChange = (event, name) => {
      const newValueObject = editChange(event, name);

      setEditValues({...editValues, ...newValueObject});
      setIsChanged(true);
   };

   /**
    * Reset the edit and default values.
    */
   const resetValues = () => {
      setEditValues({});
      setDefaultValues( {});
      setIsChanged(false);
   }

   return [editValues, handleChange, {isChanged, setIsChanged, setEditValues, defaultValues, setDefaultValues, resetValues}];
}