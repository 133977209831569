import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {isFeatureEnabled, isAdminOnly} from '../../Utils/ServerUtil';

/**
 * A component that compares the feature to the feature_flags of the user. If the user has the feature, the children
 * will be displayed and not otherwise.
 **/
export default function Feature({feature, children}) {
   const [hasFeature, setHasFeature] = useState(false);

   /**
    * When the feature changes update whether the children can be displayed.
    */
   useEffect(() => {
      if (feature === 'SEE_ESTIMATES') {
         setHasFeature(isFeatureEnabled(feature) || isAdminOnly())
      } else {
         setHasFeature(isFeatureEnabled(feature));
      }
   }, [feature]);

   return hasFeature ? children : null;
}

Feature.propTypes = {
   feature: PropTypes.string.isRequired,  //Name of the feature to check.
};
