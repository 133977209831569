import React from 'react';
import FilterListIcon from '@material-ui/icons/FilterList';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { HEADER_HEIGHT_EVAL_TABLE, HEADER_ICON_WIDTH_EVAL_TABLE } from '../../Constants';

const useStyles = makeStyles((theme) => ({
  filterIcon: {
    display: 'inline-block',
    marginRight: theme.spacing(0.5),
    height: 22,
    width: 22,
    color: "#4f2682 !important"
  },
  iconRoot: {
    fontSize: 14,
  },
  activeFilterIcon: {
    height: HEADER_HEIGHT_EVAL_TABLE,
    width: HEADER_ICON_WIDTH_EVAL_TABLE,
    marginTop: 0,
    padding: '1px 1px 0 1px',
    paddingRight: 1,
    paddingTop: 1,
    borderRadius: '50%',
    backgroundColor: "#b7b8b7 !important",
    color: "#4f2682 !important"
    //color: "#ffff !important"
 },
}));

interface Props{
    activeFilter: any;
}

const FTFilterIcon: React.FC<Props> = ({activeFilter}) => {
  const classes = useStyles();

  return (
    <FilterListIcon
        className={classNames(classes.filterIcon, {
            [classes.activeFilterIcon]: activeFilter
        })}
        classes={{ root: classes.iconRoot }}
    />
  );
};

export default FTFilterIcon;
