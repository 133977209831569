import React from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

export default function NumberFormatCustom(props) {
   const { inputRef, onChange, value, format, thousandSeparator, ...other } = props;

   let currentValue = value === null ? undefined : value;

   return (
      <NumberFormat
         style={{ textAlign: format === 'currency' ? 'right' : undefined }}
         value={currentValue}
         {...other}
         getInputRef={inputRef}
         onValueChange={(values) => {
            onChange({
               target: {
                  name: props.name,
                  value: values.value,
               },
            });
         }}
         decimalScale={0}
         allowNegative={false}
         fixedDecimalScale={true}
         // Disable thousand separator for 'number' format, and enable it for 'currency'
         thousandSeparator={format === 'currency' ? thousandSeparator : false}
         prefix={format === 'currency' ? '$' : undefined}
      />
   );
}

NumberFormatCustom.propTypes = {
   inputRef: PropTypes.func.isRequired,
   onChange: PropTypes.func.isRequired,
   format: PropTypes.oneOf(['currency', 'number']),
};
