import WarningIcon from '@material-ui/icons/WarningRounded';

import {isNil, isEqual, isArray, compact, forOwn, hasIn, isNumber, toNumber, sortBy} from 'lodash';
import find from 'lodash/find';
import get from 'lodash/get';
import has from 'lodash/has';
import set from 'lodash/set';
import moment from 'moment';
import React from 'react';
import {v4 as uuid} from 'uuid';
import {SuccessCheck} from '../../components/eval/list/UploadPhotoSummary2';
import {
   PLACEHOLDER_IMAGE, IMAGE_STATE_NOT_LOADED, IMAGE_STATE_PLACEHOLDER, PHOTO_STATE_ATTATCHED, PHOTO_STATE_UPLOADED,
   PHOTO_STATE_ERROR, DATE_MISSING, THUMBNAIL_SIZE_LARGE, PDF_IMAGE, MOVIE_MIME_TYPES, VIDEO_ICON, MOVIE_EXTENSIONS,
   PHOTO_STATE_NOT_UPLOADED, EXCEL_ICON, CSV_ICON, CATALOG_ATTACHMENT_MIME_TYPES,
   MOVIE_EXTENSIONS_TEXT, CATALOG_ATTACHMENT_EXTENSIONS_TEXT, STATUS_ORDER
} from '../../Constants';
import Typography from '../components/Typography';

/**
 * Format the message for localization. The default message has the id appended in non-production versions.
 *
 * @param intl             // Intl for localization.
 * @param id               // Message ID from localization file.
 * @param defaultMessage   // Default message to use if id cannot be found in localization file.
 * @param values           // Values to insert in the localized message.
 * @return {string}        // Localized message.
 */
export function formatMessage(intl, id, defaultMessage, values, onError) {
   if (id) {
      const newDefaultMessage = process.env.NODE_ENV === 'production' ? defaultMessage : `${defaultMessage} (${id})`;

      return intl ? intl.formatMessage({id, defaultMessage: newDefaultMessage, onError}, values) : newDefaultMessage;
   } else {
      return defaultMessage;
   }
}

export function isEmailValid(email) {
   if (email) {
      // eslint-disable-next-line
      return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
         email);
   }
   return true;
}

export function getProfileThumbnail(evalItem) {
   let imagePath;

   if (evalItem.image_data && evalItem.image_data.length > 0) {
      const image = find(evalItem.image_data, {image_view: 'AMKT', deleted: false}) ||
         find(evalItem.image_data, {deleted: false});
      if (image) {
         imagePath = get(image, 'sizes.thumbnail');
      }
   }
   if (!imagePath) {
      const thumbnail = get(evalItem, 'item.thumbail');
      if (thumbnail) {
         imagePath = thumbnail.replace('ORIGINAL', 'THUMBNAIL');
         imagePath.replace('original', 'thumbnail');
      } else {
         imagePath = get(evalItem, 'item.item.images[0]');
         if (imagePath) {
            imagePath.replace('ORIGINAL', 'THUMBNAIL');
            imagePath.replace('original', 'thumbnail');
         }
      }
   }

   return imagePath || PLACEHOLDER_IMAGE;
}

export function getProfile(evalItem) {
   let imageObject = {};

   if (evalItem.image_data && evalItem.image_data.length > 0) {
      const image = find(evalItem.image_data, {image_view: 'AMKT', deleted: false}) ||
         find(evalItem.image_data, {deleted: false});
      if (image) {
         imageObject = {...get(image, 'sizes')};
      }
   }
   if (!imageObject) {
      console.log('Image data not found.');
   }

   return imageObject || {thumbnail: PLACEHOLDER_IMAGE, original: PLACEHOLDER_IMAGE};
}

export function getImageObjects(evalItem) {
   let images = [];
   let thumbnails = [];

   if (evalItem) {
      if (evalItem.image_data) {
         //TODO remove when server sorts the image_data by order.
         evalItem.image_data = sortBy(evalItem.image_data, ['order'])

         for (const image of evalItem.image_data) {
            if (!image.deleted) {
               const imageObject = {...image, __state: IMAGE_STATE_NOT_LOADED, __rotation: 0};
               const original = get(image, 'sizes.original', get(image, 'sizes[0].original'));
               // const original = get(image, 'sizes.original');
               if (!original) {
                  set(imageObject, 'sizes.original', PLACEHOLDER_IMAGE);
                  imageObject.__state = IMAGE_STATE_PLACEHOLDER;
               }
               const thumbnail = get(image, 'sizes.thumbnail');
               if (!thumbnail) {
                  set(imageObject, 'sizes.thumbnail', PLACEHOLDER_IMAGE);
               }
               images.push(imageObject);
            }
         }
      } else {
         const images = get(evalItem, 'item.images', []);
         // There may be original, thumbnail or other types.
         let originals = images.filter(image => {
            return image && image.toLowerCase().indexOf('original') >= 0
         });

         // There may be images but not with 'original' in the path.
         if (originals.length === 0 && images.length > 0) {
            originals = images;
         }

         if (originals.length > 0) {
            // The source of truth is the list of originals with ORIGINAL replaced by THUMBNAIL.
            thumbnails = originals.map(image => image.replace('ORIGINAL', 'THUMBNAIL'));
            thumbnails = thumbnails.map(image => image.replace('original', 'thumbnail'));
         }
         for (const [index, original] of originals) {
            const imageObject = {__state: IMAGE_STATE_NOT_LOADED, image_id: uuid(), __rotation: 0};
            set(imageObject, 'sizes.original', original || PLACEHOLDER_IMAGE);
            set(imageObject, 'sizes.thumbnail', thumbnails[index] || PLACEHOLDER_IMAGE);
            imageObject.__state = IMAGE_STATE_PLACEHOLDER;
            images.push(imageObject);
         }
      }
   }
   if (images.length <= 0) {
      const imageObject = {
         __state: IMAGE_STATE_PLACEHOLDER,
         image_id: uuid(),
         __rotation: 0,
         __isPlaceholderImage: true
      };
      set(imageObject, 'sizes.original', PLACEHOLDER_IMAGE);
      set(imageObject, 'sizes.thumbnail', PLACEHOLDER_IMAGE);
      images.push(imageObject);
   }
   return images;
}

export function getImages(evalItem) {
   let originals = [];
   let thumbnails = [];

   if (evalItem) {
      if (evalItem.image_data) {
         //TODO remove when server sorts the image_data by order.
         evalItem.image_data = sortBy(evalItem.image_data, ['order'])
         for (const image of evalItem.image_data) {
            const original = get(image, 'sizes.original');
            if (original) {
               originals.push(original);
            } else {
               originals.push(PLACEHOLDER_IMAGE);
            }
            const thumbnail = get(image, 'sizes.thumbnail');
            if (thumbnail) {
               thumbnails.push(thumbnail);
            } else {
               originals.push(PLACEHOLDER_IMAGE);
            }
         }
      } else {
         const images = get(evalItem, 'item.images', []);
         // There may be original, thumbnail or other types.
         originals = images.filter(image => {
            return image && image.toLowerCase().indexOf('original') >= 0
         });

         // There may be images but not with 'original' in the path.
         if (originals.length === 0 && images.length > 0) {
            originals = images;
         }

         if (originals.length > 0) {
            // The source of truth is the list of originals with ORIGINAL replaced by THUMBNAIL.
            thumbnails = originals.map(image => image.replace('ORIGINAL', 'THUMBNAIL'));
            thumbnails = thumbnails.map(image => image.replace('original', 'thumbnail'));
         }
      }
   }
   return {thumbnails, originals};
}

export const isLocalhost = Boolean(
   window.location.hostname === 'localhost' ||
   // [::1] is the IPv6 localhost address.
   window.location.hostname === '[::1]' ||
   // 127.0.0.1/8 is considered localhost for IPv4.
   window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
   )
);

export function removeOne(array, index) {
   let len = array.length;
   if (!len) {
      return;
   }
   len -= 1;
   while (index < len) {
      array[index] = array[index + 1];
      index++;
   }
   array.length--;
}

/**
 * Determines if the item has a value (i.e. not undefined, not null, nor empty string).
 *
 * @param item The item to check.
 * @return {boolean} True if the item is not undefined, not null, and not the empty string.
 */
export function hasValue(item) {
   return !isNil(item) && item !== '' && (!isArray(item) || item.length > 0);
}

/**
 * If items both have values and they are equal or if they both don'have values, they are equivalent.
 * @param item1 First item to check
 * @param item2 Second item to check.
 * @return {boolean} True if the two items have the same value or both don't have a value.
 */
export function isEquivalent(item1, item2) {
   return (!hasValue(item1) && !hasValue(item2)) || isEqual(item1, item2);
}

/**
 * Get an object with the property set to the changed value or undefined, if the property hasn't changed.
 *
 * @param changedItem The possibly changed object to compare properties.
 * @param originalItem The original object to compare properties.
 * @param changedProperty The property of the changed item to compare.
 * @param originalProperty The property of the original item to compare.
 * @param isCompactArray True to compact the array type properties.
 * @return {boolean|{}} The new object with the property set if changed, or undefined if not changed.
 */
export function getChangedProperty(changedItem, originalItem, changedProperty, originalProperty,
   isCompactArray = false) {
   originalProperty = originalProperty || changedProperty;
   const changedItemProperty = isCompactArray && isArray(changedItem[changedProperty]) ?
      compact(changedItem[changedProperty]) : changedItem[changedProperty];
   return !isEquivalent(changedItemProperty, originalItem[originalProperty]) &&
      {[originalProperty]: changedItemProperty};
}

/**
 * Get an object with the property set to the changed value or undefined, if the property hasn't changed. The changed
 * property is passed directly instead of as part of an object for getChangedProperty.
 *
 * @param changedProperty The possibly changed property to compare.
 * @param originalItem The original object to compare properties.
 * @param originalProperty The property of the original item to compare.
 * @return {boolean|{}} The new object with the property set if changed, or undefined if not changed.
 */
export function getCustomChanged(changedProperty, originalItem, originalProperty) {
   return !isEquivalent(changedProperty, originalItem[originalProperty]) && {[originalProperty]: changedProperty};
}

/**
 * Get an object with all the properties set to the changed properties. Only changed properties will be in the returned
 * object.
 *
 * @param changedItem The possibly changed object to compare properties.
 * @param originalItem The original object to compare properties.
 * @param changedProperties The list of properties of the changed item to compare.
 * @param mapChangedPropertiesToOriginal The mapping from changed property names to original properties. For example:
 *    {
 *       changedItemPropertyName: 'originalProperyName',
 *       changedItemPropertyName2: 'originalProperyName2',
 *    }
 */
export function getChangedObject(changedItem, originalItem, changedProperties, mapChangedPropertiesToOriginal = {}) {
   const changed = {};
   for (const property of changedProperties) {
      const property2 = mapChangedPropertiesToOriginal[property] || property;
      const changedField = getChangedProperty(changedItem, originalItem, property, property2);
      if (changedField) {
         changed[property2] = changedItem[property];
      }
   }
   return changed;
}

/**
 * detect IE
 * returns version of IE or false, if browser is not Internet Explorer
 */
export function detectIE() {
   var ua = window.navigator.userAgent;

   // Test values; Uncomment to check result …

   // IE 10
   // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

   // IE 11
   // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

   // Edge 12 (Spartan)
   // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71
   // Safari/537.36 Edge/12.0';

   // Edge 13
   // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0
   // Safari/537.36 Edge/13.10586';

   var msie = ua.indexOf('MSIE ');
   if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
   }

   var trident = ua.indexOf('Trident/');
   if (trident > 0) {
      // IE 11 => return version number
      var rv = ua.indexOf('rv:');
      return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
   }

   // other browser
   return false;
}

export function fillProperty(array, path, value) {
   if (!array || array.length <= 0) {
      return;
   }

   for (const item of array) {
      set(item, path, value);
   }
}

export function update(func) {
   if (typeof func != 'function') {
      throw new Error('Bad function');
   }
   var updated = function () {
      var args = arguments;

      if (isEqual(args, updated.args)) {
         return updated.result;
      }
      var result = func.apply(this, args);
      updated.args = args;
      updated.result = result;
      return result;
   };
   updated.args = undefined;
   updated.result = undefined;
   return updated;
}

export function removePrivateProperties(item) {
   forOwn(item, (value, key) => {
      if (key.indexOf('__') === 0) {
         delete item[key];
      }
   });
}

export function cloneWithoutPrivateProperties(item) {
   const result = {...item};
   removePrivateProperties(result);
   return result;
}

export class FileSummary {

   static getFileSummary(unknown) {
      if (has(unknown, 'image_id') || has(unknown, 'updated_at')) {
         if (has(unknown, '__isPlaceholderImage') && unknown.__isPlaceholderImage) {
            return new PhotoPlaceHolder();
         }
         return new PhotoDB(unknown);
      }
      if (hasIn(unknown, 'link')) {
         if (unknown.name && unknown.name.indexOf('.pdf') >= 0) {
            return new PdfFile(unknown);
         }
         return new PhotoDropBox(unknown);
      }
      if (hasIn(unknown, 'name')) {
         if (unknown.type === 'application/pdf') {
            return new PdfFile(unknown);
         } else if (MOVIE_MIME_TYPES.indexOf(unknown.type) >= 0) {
            return new VideoFile(unknown);
         } else if (CATALOG_ATTACHMENT_MIME_TYPES.indexOf(unknown.type) >= 0) {
            return new OtherFile(unknown, unknown.type);
         } else {
            return new PhotoFile(unknown);
         }
      }
      if (hasIn(unknown, 'file_type')) {
         // Existing file is known because file_type is field added by the DB.
         unknown.__status = PHOTO_STATE_ATTATCHED;
         if (unknown.file_type === 'pdf') {
            return new PdfFile(unknown);
         } else if (MOVIE_EXTENSIONS_TEXT.indexOf(unknown.file_type) >= 0 || MOVIE_EXTENSIONS.indexOf(unknown.file_type) >= 0) {
            return new VideoFile(unknown);
         } else if (CATALOG_ATTACHMENT_EXTENSIONS_TEXT.indexOf(unknown.file_type) >= 0) {
            return new OtherFile(unknown, unknown.file_type);
         }
      }
      if (hasIn(unknown, 'type')) {
         if (unknown.type === 'application/pdf') {
            return new PdfFile(unknown);
         } else if (MOVIE_MIME_TYPES.indexOf(unknown.type) >= 0) {
            return new VideoFile(unknown);
         } else if (CATALOG_ATTACHMENT_MIME_TYPES.indexOf(unknown.type) >= 0) {
            return new OtherFile(unknown, unknown.type);
         } else {
            return new PhotoFile(unknown);
         }
      } else {
         console.log('Photo type is not recognized');
         return new PhotoFile(unknown);
      }
   }

   getThumbnail() {
      console.log('getThumbnail should be overridden.')
   };

   getMaxWidth() {
      return THUMBNAIL_SIZE_LARGE;
   };

   getMaxHeight() {
      return THUMBNAIL_SIZE_LARGE;
   };

   getName() {
      console.log('getName should be overridden.')
   }

   removeThumbnail() {
      console.log('removeThumbnail should be overridden.')
   }

   getStatus() {
      console.log('getStatus should be overridden.')
   }

   async createThumbnail() {
      console.log('createThumbnail should be overridden.')
   }
}

const IMAGE_TYPE = /image.*/;
const defaultGetStatus = (file) => {
   switch (file.__status) {
      case PHOTO_STATE_ATTATCHED:
         return <SuccessCheck/>;
      case PHOTO_STATE_UPLOADED:
         return <Typography className='uploading'>Processing</Typography>;
      case PHOTO_STATE_ERROR:
         return <WarningIcon className={'warning'}/>;
      case PHOTO_STATE_NOT_UPLOADED:
         return <Typography className='uploading'>Uploading</Typography>;
      default:
         return undefined;
   }
}

class PhotoFile extends FileSummary {
   file;
   thumbnail;

   constructor(file) {
      super();

      this.file = file
   }

   async createThumbnail() {
      this.thumbnail = this.file.type.match(IMAGE_TYPE) ? await createThumbnail(this.file) : PLACEHOLDER_IMAGE;
   }

   getThumbnail() {
      return this.thumbnail;
   }

   removeThumbnail() {
      this.thumbnail = undefined;
   }

   getName() {
      return this.file.name;
   }

   getStatus() {
      return defaultGetStatus(this.file);
   }
}

export class PhotoPlaceHolder extends FileSummary {
   async createThumbnail() {
      // Intentionally left blank.
   }

   getThumbnail() {
      return PLACEHOLDER_IMAGE;
   }

   getName() {
      return 'placeholder';
   }

   removeThumbnail() {
      //Intentionally left blank.
   }

   getStatus() {
      return null;
   }

}

class PhotoDB extends FileSummary {
   photo;
   thumbnail;

   constructor(photo) {
      super();

      this.photo = photo;
      this.thumbnail = get(photo, 'sizes.web', PLACEHOLDER_IMAGE);
   }

   async createThumbnail() {
      // Intentionally left blank.
   }

   getThumbnail() {
      return this.thumbnail;
   }

   getName() {
      return get(this.photo, 'original_filename', '');
   }

   removeThumbnail() {
      //Intentionally left blank.
   }

   getStatus() {
      return (
         <SuccessCheck/>
      );
   }
}

class PhotoDropBox extends FileSummary {
   photo;
   thumbnail;

   constructor(photo) {
      super();

      this.photo = photo;
      const thumbnailLink = get(photo, 'thumbnailLink');
      if (thumbnailLink) {
         const thumbnailLinkArray = thumbnailLink.split('?');
         this.thumbnail = thumbnailLinkArray.length >= 2 ? thumbnailLinkArray[0] + '?bounding_box=800&mode=fit' :
            thumbnailLink;
      } else {
         this.thumbnail = PLACEHOLDER_IMAGE;
      }
   }

   async createThumbnail() {
      // Intentionally left blank.
   }

   getThumbnail() {
      return this.thumbnail;
   }

   getName() {
      return get(this.photo, 'name', '');
   }

   removeThumbnail() {
      //Intentionally left blank.
   }

   getStatus() {
      return defaultGetStatus(this.photo);
   }
}

class PdfFile extends FileSummary {
   file;
   thumbnail;

   constructor(file) {
      super();

      this.file = file;
   }

   async createThumbnail() {
      // Intentionally left blank.
   }

   getMaxWidth() {
      return 200;
   };

   getMaxHeight() {
      return 200;
   };

   getThumbnail() {
      return PDF_IMAGE;
   }

   getName() {
      return get(this.file, 'name', get(this.file, 'original_file_name', ''));
   }

   removeThumbnail() {
      //Intentionally left blank.
   }

   getStatus() {
      return defaultGetStatus(this.file);

   }
}

class VideoFile extends FileSummary {
   file;
   thumbnail;

   constructor(file) {
      super();

      this.file = file;
   }

   async createThumbnail() {
      // Intentionally left blank.
   }

   getMaxWidth() {
      return 300;
   };

   getMaxHeight() {
      return 300;
   };

   getThumbnail() {
      return VIDEO_ICON;
   }

   getName() {
      return get(this.file, 'name', get(this.file, 'original_file_name', ''));
   }

   removeThumbnail() {
      //Intentionally left blank.
   }

   getStatus() {
      return defaultGetStatus(this.file);
   }
}

class OtherFile extends FileSummary {
   constructor(file, type) {
      super();
      this.file = file;
      switch (type) {
         case 'xlsx':
         case 'application/vnd.ms-excel':
         case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            this.icon = EXCEL_ICON;
            break;
         case 'text/csv':
         case 'csv':
            this.icon = CSV_ICON;
            break;
         default:
            this.icon = undefined;
      }
   }

   async createThumbnail() {
      // Intentionally left blank.
   }

   getMaxWidth() {
      return 300;
   };

   getMaxHeight() {
      return 300;
   };

   getThumbnail() {
      return this.icon;
   }

   getName() {
      return get(this.file, 'name', get(this.file, 'original_file_name', ''));
   }

   removeThumbnail() {
      //Intentionally left blank.
   }

   getStatus() {
      return defaultGetStatus(this.file);
   }
}

/**
 * Remove the thumbnails for the photo files.
 * @param newFiles The new photo files.
 */
export function removeThumbnails(newFiles) {
   for (const file of newFiles) {
      file.removeThumbnail();
   }
}

/**
 * Create the thumbnails for the photo files.
 * @param newFiles The new photo files.
 */
export function createThumbnails(newFiles) {
   return new Promise(async (resolve, reject) => {
      try {
         for (const file of newFiles) {
            await file.createThumbnail();
         }
         resolve();
      } catch (e) {
         reject(e);
      }
   });
}

function createThumbnail(file) {
   return new Promise((resolve, reject) => {
      setTimeout(() => {
         let reader = new FileReader();

         if (reader != null) {

            reader.onload = (e) => {
               let myCan = document.createElement('canvas');
               let img = new Image();
               img.src = e.target.result;
               img.onload = () => {

                  myCan.id = 'myTempCanvas';
                  myCan.width = THUMBNAIL_SIZE_LARGE;
                  myCan.height = ((img.naturalHeight || 1) / (img.naturalWidth || 1)) * THUMBNAIL_SIZE_LARGE;
                  if (myCan.getContext) {
                     let context = myCan.getContext('2d');
                     context.drawImage(img, 0, 0, myCan.width, myCan.height);
                     let dataURL = myCan.toDataURL();

                     if (dataURL != null) {
                        resolve(dataURL);
                     } else {
                        console.log('unable to get context');
                        reject('unable to get context');
                     }
                  }
               }
            };

            // read the image file as a data URL.
            reader.readAsDataURL(file);
         } else {
            console.log('unable to get context');
            reject('unable to create FileReader');
         }
      }, 4);
   });
}

export const sortDate = (a, b) => {
   if (a === b) {
      return 0;
   }
   if (a === DATE_MISSING || b === DATE_MISSING) {
      return (a === DATE_MISSING) ? -1 : 1;
   }
   const aMoment = moment(a);
   const bMoment = moment(b);

   if (aMoment.isSame(bMoment)) {
      return 0;
   }
   return aMoment.isAfter(bMoment) ? 1 : -1;
};


export const sortMethod = (a, b, desc, adjust = false) => {

   // force null and undefined to the bottom
   if (isNumber(a) || isNumber(b)) {
      a = a === null || a === undefined || a === '' ? (desc ? Number.MIN_SAFE_INTEGER : Number.MAX_SAFE_INTEGER) : a;
      b = b === null || b === undefined || b === '' ? (desc ? Number.MIN_SAFE_INTEGER : Number.MAX_SAFE_INTEGER) : b;
   } else {
      a = a === null || a === undefined || a === '' ? (desc ? '' : '~~~~~~$&') : a;
      b = b === null || b === undefined || b === '' ? (desc ? '' : '~~~~~~$&') : b;
   }

   // force any string values to lowercase
   a = typeof a === 'string' ? a.toLowerCase() : a;
   b = typeof b === 'string' ? b.toLowerCase() : b;
   // Return either 1 or -1 to indicate a sort priority
   if (a > b) {
      return desc && adjust ? -1 : 1;
   }
   if (a < b) {
      return desc && adjust ? 1 : -1;
   }
   // returning 0, undefined or any falsey value will use subsequent sorts or
   // the index as a tiebreaker
   return 0;
};

/**
 * Sort the status values. The order is defined by STATUS_ORDER.
 *
 * @param a The first status.
 * @param b The second status.
 * @return {number} 0, 1, -1 for equal, greater than, and less than respectively.
 */
export const sortStatus = (a, b) => {
   if (a === b) {
      return 0;
   }

   if (!a || !b) {
      return !a ? -1 : 1;
   }
   const aIndex = STATUS_ORDER.indexOf(a);
   const bIndex = STATUS_ORDER.indexOf(b);

   return aIndex > bIndex ? 1 : -1;
};
/**
 * Handle edit changes from all UI components.
 *
 * @param event The event causing the change.
 * @param value The value of the component if already retrieved from the event.
 *    the name and value are separate.
 * @param name The name of the UI component changed.
 * @return {*|{newValue: *, componentName: *}|{}}
 */
export const editChange = (event, name) => {
   let nextValue;
   let componentName = name;

   //Normal events have a target. ReactSelect events have a value.
   if (event.target) {
      switch (event.target.type) {
         case 'number':
            nextValue = event.target.valueAsNumber;
            break;
         case 'checkbox':
            nextValue = event.target.checked;
            break;
         default:
            const type = get(event, 'target.dataset.type');
            if (type === 'number') {
               nextValue = toNumber(event.target.value);
            } else {
               nextValue = event.target.value;
            }
            break;
      }
      componentName = event.target.name;
   } else if (event.value) {
      //Set values from ReactSelect.
      nextValue = event.value;
      componentName = name;
   } else {
      console.log('Unknown type of event to process.');
   }

   return {[componentName]: nextValue};
};
