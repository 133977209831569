import PropTypes from 'prop-types';
import React, {Component} from 'react';

// import './Form.css';

class Form extends Component {
   formEl = React.createRef();
   static propTypes = {
      id: PropTypes.any,
      validate: PropTypes.bool,
      reportInvalid: PropTypes.bool,
      onValidate: PropTypes.func,
      submit: PropTypes.func.isRequired,
      forceValidate: PropTypes.bool,
      shouldSubmit: PropTypes.bool,
   };
   static defaultProps = {
      validate: false,
      reportInvalid: true,
      forceValidate: false,
      shouldSubmit: false,
   };
   state = {};

   componentDidUpdate(prevProps, prevState, snapshot) {
      if (prevProps.validate !== this.props.validate && this.props.validate) {
         this.validate();
      }

      if (prevProps.shouldSubmit !== this.props.shouldSubmit && this.props.shouldSubmit) {
         this.submitHandler();
      }
   }

   validate = (isReport = true) => {
      const {customValidity} = this.props;

      let isValid;
      let form = this.formEl.current;

      if (form) {
         isValid = form.checkValidity();
         if (isValid && customValidity) {
            isValid = customValidity();
         }
         this.props.onValidate && this.props.onValidate(isValid);
         if (!isValid && isReport) {
            return form.reportValidity();
         }

         return isValid
      }
      return true;
   };

   submitHandler = (event) => {
      const {submit, forceValidate} = this.props;
      event && event.preventDefault();

      if (!forceValidate || this.validate()) {
         submit && submit();
      }
   };

   render() {
      const props = {...this.props};

      let classNames = [];
      if (props.className) {
         classNames = [...props.className];
         delete props.className;
      }
      return (
         <form ref={this.formEl} onSubmit={this.submitHandler} {...props} className={classNames}>
            {this.props.children}
         </form>
      );
   }
}

Form.propTypes = {
   children: PropTypes.node,
   className: PropTypes.string,
   submit: PropTypes.func.isRequired,
   customValidity: PropTypes.func,
};

export default Form;