import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '../../fhg/components/Typography';
import { makeStyles,Theme } from '@material-ui/core/styles';

interface Props {
  id?:string;
  title: string;
  icon: React.ReactNode;
  iconLocation?: 'start' | 'end';
  disabled?: boolean;
  buttonAction: () => void;
  color?: string;
}

const useStyles = makeStyles<Theme, {color?:string}>((theme) => ({
  button: {
    display:'flex',
    alignItems:'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(2),
    color: (props) => props.color || "#4f2682"
    ,
  },
}));

const FTButtonIconText: React.FC<Props> = ({
  id,
  title,
  icon,
  iconLocation = 'start',
  disabled,
  color,
  buttonAction
}) => {
  const classes:any = useStyles({color});

  return(
    <Button
      id={id}
      variant="text"
      className={`${classes.button} ' no-print'`}
      onClick={buttonAction}
      disabled={disabled}
      startIcon={iconLocation === 'start' ? icon : undefined}
      endIcon={iconLocation === 'end' ? icon : undefined}
    >
      <Typography color="inherit" variant="button" id={title}>{title}</Typography>
    </Button>
  )
};

export default FTButtonIconText;
