import React from "react";
import { Link } from "react-router-dom";
import { Grid } from "@material-ui/core";
import classNames from "classnames";
import { get } from "lodash";
import { formatMessage, sortStatus } from "../../../../fhg/utils/Utils";
import { posthogCapture } from "../../../../PostHogFunction";
import eventCapture from "../../../../eventList";
import { SHARE_LIVE, SHARED } from "../../../../Constants";
import moment from "moment";
import { DATE_FORMAT } from "../../../../Constants";
import Typography from "../../../../fhg/components/Typography";
import { CUSTOMER_ENV, isFeatureEnabled } from "../../../../Utils/ServerUtil";

export interface EvalDashboardTableProps {
  classes: any;
  intl: any;
}

export interface EvalDashboardTableState {
  sort: { id: string; desc: boolean }[];
}

export interface EvalDashboardTableColumn {
  id: string;
  Header: React.ReactNode;
  accessor: string | ((row: any) => any);
  Cell?: (row: any) => React.ReactNode;
  searchText?: (value: any) => string;
  sortMethod?: (a: any, b: any) => number;
  width?: number;
  minWidth?: number;
  maxWidth?: number;
}

const getEvalStatusCell = (
  row: any,
  classes: {
    [x: string]:
      | string
      | number
      | boolean
      | classNames.ArgumentArray
      | classNames.Mapping
      | null
      | undefined;
    dot:
      | string
      | number
      | boolean
      | classNames.ArgumentArray
      | classNames.Mapping
      | null
      | undefined;
    menuText: any;
  },
  isFeatureEnabled: { (feature: any): boolean; (arg0: any): any },
  CUSTOMER_ENV: string
) => {
  const status = get(row, "original.evaluation.workflow_status");

  if (!isFeatureEnabled(CUSTOMER_ENV)) {
    return (
      <Grid
        container
        direction={"row"}
        alignItems={"center"}
        style={{ height: "100%" }}
      >
        <span className={classNames(classes.dot, classes[status])} />
        <Typography
          className={classes.menuText}
          color="inherit"
          variant="body1"
          id={status}
        >
          {status}
        </Typography>
      </Grid>
    );
  } else {
    return (
      <Typography
        id={
          status !== "capture_in_progress"
            ? "customerInventory.sentToPurpleWave.status"
            : "customerInventory.notSentToPurpleWave.status"
        }
      >
        {status !== "capture_in_progress"
          ? "Sent to Purple Wave"
          : "Not Sent to Purple Wave"}
      </Typography>
    );
  }
};

const getColumns = (
  defaultSort: EvalDashboardTableColumn[],
  props: EvalDashboardTableProps,
  state: EvalDashboardTableState
): EvalDashboardTableColumn[] => {
  const { classes, intl } = props;

  const sortColumnId = get(state, "sort[0].id", get(defaultSort, "[0].id"));

  return [
    {
      id: "sharedStatus",
      Header: (
        <Grid
          container
          className={classNames("offset", "sortContent", {
            [classes.notSort]: sortColumnId !== "sharedStatus",
          })}
          wrap={"nowrap"}
          alignItems={"center"}
        >
          <Typography
            className={classes.columnTitle}
            id={"evalDashboard.share.column"}
          >
            Shared
          </Typography>
        </Grid>
      ),
      accessor: "evaluation.evaluation.shared_with_users",
      searchText: (value: any) =>
        formatMessage(
          intl,
          "evaluation.sharedStatus",
          "",
          { value },
          undefined
        ),
      Cell: (row: any) => {
        const isShared =
          row.original.evaluation?.shared_with_users?.length !== 0;
        return (
          <Grid container alignItems="center">
            {isShared ? (
              <img src={SHARED} alt="Shared" className={classes.icon} />
            ) : (
              <img src={SHARE_LIVE} alt="Not Shared" className={classes.icon} />
            )}
            <Typography className={classes.defaultTextStyle} noWrap>
              {row.value}
            </Typography>
          </Grid>
        );
      },
      sortMethod: sortStatus,
      width: 80,
    },
    {
      id: "evalUser",
      Header: (
        <Typography
          className={classNames("sortContent", classes.columnTitle, {
            [classes.notSort]: sortColumnId !== "evalUser",
          })}
          id={"evalDashboard.primaryContact.column"}
        >
          Primary Contact
        </Typography>
      ),
      accessor: "evaluation.primary_contact",
      minWidth: 100,
      Cell: (row: any) => (
        <Link
          className={classes.linkStyle}
          to={row.original.uri}
          target="_blank"
        >
          <Typography className={classes.defaultTextStyle} noWrap>
            {row.value}
          </Typography>
        </Link>
      ),
    },
    {
      id: "evalOwner",
      Header: (
        <Typography
          className={classNames("sortContent", classes.columnTitle, {
            [classes.notSort]: sortColumnId !== "evalOwner",
          })}
          id={"evalDashboard.equipmentOwner.column"}
        />
      ),
      accessor: "evaluation.owner_name",
      minWidth: 120,
      Cell: (row: any) => (
        <Link
          className={classes.linkStyle}
          to={row.original.uri}
          target="_blank"
        >
          <Typography className={classes.defaultTextStyle} noWrap>
            {row.value}
          </Typography>
        </Link>
      ),
    },
    {
      id: "evalOwnerID",
      Header: (
        <Typography
          className={classNames("sortContent", classes.columnTitle, {
            [classes.notSort]: sortColumnId !== "evalOwner",
          })}
          id={"evalDashboard.equipmentOwnerId.column"}
        />
      ),
      accessor: "evaluation.owner_id",
      minWidth: 80,
      Cell: (row: any) => (
        <a
          className={classes.linkStyleTwo}
          href={`https://cliq.purplewave.com/c/cus.php?c=${row.value}`}
          target="_blank"
          onClick={(e) => {
            e.stopPropagation();
            posthogCapture(eventCapture.CUSTOMER_NUMBER_CLICKED);
          }}
        >
          <Typography className={classes.defaultTextStyleTwo} noWrap>
            {row.value}
          </Typography>
        </a>
      ),
    },
    {
      id: "evalItems",
      Header: (
        <Typography
          className={classNames("sortContent", classes.columnTitle, {
            [classes.notSort]: sortColumnId !== "evalItems",
          })}
          id={"evalDashboard.item.column"}
        >
          Items
        </Typography>
      ),
      accessor: "item_count",
      width: 70,
      Cell: (row: any) => (
        <Link
          className={classes.linkStyle}
          to={row.original.uri}
          target="_blank"
        >
          <Typography className={classes.defaultTextStyle} noWrap>
            {row.value}
          </Typography>
        </Link>
      ),
    },
    {
      id: "evalPhotos",
      Header: (
        <Typography
          className={classNames("sortContent", classes.columnTitle, {
            [classes.notSort]: sortColumnId !== "evalPhotos",
          })}
          id={"evalDashboard.photos.column"}
        >
          Photos
        </Typography>
      ),
      accessor: "image_count",
      width: 80,
      maxWidth: 100,
      Cell: (row: any) => (
        <Link
          className={classes.linkStyle}
          to={row.original.uri}
          target="_blank"
        >
          <Typography className={classes.defaultTextStyle} noWrap>
            {row.value}
          </Typography>
        </Link>
      ),
    },
    {
      id: "evalVideos",
      Header: (
        <Typography
          className={classNames("sortContent", classes.columnTitle, {
            [classes.notSort]: sortColumnId !== "evalVideos",
          })}
          id={"evalDashboard.videos.column"}
        >
          Videos
        </Typography>
      ),
      accessor: "evaluation.video_count",
      width: 80,
      maxWidth: 100,
      Cell: (row: any) => (
        <Link
          className={classes.linkStyle}
          to={row.original.uri}
          target="_blank"
        >
          <Typography className={classes.defaultTextStyle} noWrap>
            {row.value}
          </Typography>
        </Link>
      ),
    },
    {
      id: "IMS",
      Header: (
        <Typography
          className={classNames("sortContent", classes.columnTitle, {
            [classes.notSort]: sortColumnId !== "evalVideos",
          })}
          id={"evalDashboard.ims.count.column"}
        >
          IMS
        </Typography>
      ),
      accessor: "evaluation.item_form_count",
      width: 100,
      maxWidth: 100,
      Cell: (row: any) => (
        <Link
          className={classes.linkStyle}
          to={row.original.uri}
          target="_blank"
        >
          <Typography className={classes.defaultTextStyle} noWrap>
            {row.value}
          </Typography>
        </Link>
      ),
    },
    {
      Header: (
        <Typography
          className={classNames("sortContent", classes.columnTitle, {
            [classes.notSort]: sortColumnId !== "evalDate",
          })}
          id={"evalDashboard.dateCreated.column"}
        >
          Date Created
        </Typography>
      ),
      accessor: (row) => {
        if (row.evaluation && row.evaluation.created) {
          return moment(row.evaluation.created).format(DATE_FORMAT);
        } else {
          return "N/A";
        }
      },
      id: "dateCreated",
      sortMethod: (a, b) => {
        if (a === b) return 0;
        const aMoment = moment(a, DATE_FORMAT);
        const bMoment = moment(b, DATE_FORMAT);
        return aMoment.isAfter(bMoment) ? 1 : -1;
      },
      minWidth: 120,
    },
    {
      Header: (
        <Typography
          className={classNames("sortContent", classes.columnTitle, {
            [classes.notSort]: sortColumnId !== "evalUpdate",
          })}
        >
          Date Updated
        </Typography>
      ),
      accessor: (row) => {
        if (row.updated) {
          return moment(row.updated).format(DATE_FORMAT);
        } else {
          return "N/A";
        }
      },
      id: "dateUpdated",
      sortMethod: (a, b) => {
        if (a === b) return 0;
        const aMoment = moment(a, DATE_FORMAT);
        const bMoment = moment(b, DATE_FORMAT);
        return aMoment.isAfter(bMoment) ? 1 : -1;
      },
      minWidth: 120,
    },
    {
      Header: (
        <Grid
          container
          className={classNames("offset", "sortContent", {
            [classes.notSort]: sortColumnId !== "evalStatus",
          })}
          wrap={"nowrap"}
          alignItems={"center"}
        >
          <Typography
            className={classes.columnTitle}
            id={"evalDashboard.status.column"}
          >
            Status
          </Typography>
        </Grid>
      ),
      accessor: (row) => {
        const status = row.evaluation && row.evaluation.workflow_status;
        return status || "Unknown";
      },
      id: "evaluationStatus",
      Cell: (row: any) =>
        getEvalStatusCell(row, classes, isFeatureEnabled, CUSTOMER_ENV),
      width: 180,
    },
  ];
};

export default getColumns;
