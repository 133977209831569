import {useState, useCallback} from 'react';
import {requestForServer} from './ServerUtil';

/**
 * Hook to handle requests to the server. The query function will be called to send the changes to the server.
 *
 * Reviewed: 8/5/2020
 *
 * @param responseType The type of data for the response.
 * @param api The api to use from the Amplify.configure API endpoints.
 * @return {[function(*=, *=, *=, *=): Promise<unknown>, {data: {}, loading: boolean, error: undefined}]}
 */
export default function useLazyRequestForServer(responseType = 'json', api, test) {

   const [error, setError] = useState();
   const [loading, setLoading] = useState(false);
   const [data, setData] = useState({});

   /**
    * Calls requestForServer with the parameters. The errors and progress are updated.
    * @type {function(*=, *=, *=, *=): Promise<unknown>} The promise resolves when the call is made successfully. The
    *    promise is rejected on errors in the results or from the call.
    */
   const queryFunction = useCallback((uri, method, body, newResponseType) => {
      setLoading(true);
      return new Promise(async (resolve, reject) => {
         try {
            const result = await requestForServer(uri, method, body, newResponseType || responseType, api);
            if (result.error) {
               if (test) {
                  const data = JSON.parse(test);
                  setData(data);
                  resolve(data);
               } else {
                  setError(result.error);
                  reject(result.error);
               }
            } else {
               setData(result.data);
               resolve(result.data);
            }
         } catch (error) {
            console.log('error', error);
            setError(error);
            setLoading(false);
            reject(error);
         } finally {
            setLoading(false);
         }
      });
   }, [responseType, api]);

   return [queryFunction, {loading, error, data}];
}