import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import React, { ReactNode } from 'react';
import { APP_BAR_HEIGHT } from '../Constants';
import { LARGE_LOGO } from '../LogoConstants';

interface Props{
   isSignIn?: boolean;
   children?: ReactNode;
   display?: string;
}
const useStyles = makeStyles(theme =>({
   appBar: {
      flexDirection: 'row',
      height: APP_BAR_HEIGHT,
      alignItems: 'center',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      boxShadow: 'none',
      [theme.breakpoints.down('xs')]: {
         paddingLeft: theme.spacing(1),
         paddingRight: theme.spacing(1),
      },
      backgroundColor:'#4f2682',
      'display':(props:any) => props.display
   },
   image: {
      height: 40,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(4),
      [theme.breakpoints.down('xs')]: {
         marginRight: theme.spacing(1),
      },
   },
   signInImage: {
      height: 22,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(5),
      [theme.breakpoints.down('xs')]: {
         marginRight: theme.spacing(1),
      },
   }
}));

/**
 * The App Bar with a logo, title and log out.
 */

const MainAppBar: React.FC<Props> = ({isSignIn,display,children}) => {
   const classes = useStyles({display});
   const logo = LARGE_LOGO();
   return(
      <AppBar className={classes.appBar} position='static'>
         {logo && (
            <img className={isSignIn ? classes.signInImage : classes.image} src={logo} alt=''/>
         )}
         {children}
      </AppBar>
   );
}

export {MainAppBar};
