import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import {withStyles} from '@material-ui/core/styles';
import React, {PureComponent} from 'react';
import {injectIntl} from 'react-intl';
import {MAILBOX_ICON} from '../../Constants';
import Typography from '../../fhg/components/Typography';
import {formatMessage} from '../../fhg/utils/Utils';
import FormattedTextField from '../eval/components/FormattedTextField';
import PropTypes from 'prop-types';

const styles = theme => ({
   mainGrid: {
      height: 'calc(100% - 80px)',
      width: '100%',
      minHeight: 780,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.brand.gray,//'#5D788F',
      overflow: 'hidden',
   },
   contentStyle: {
      width: 436,
      padding: theme.spacing(3, 7),
      overflow: 'auto',
      backgroundColor: theme.palette.environment.light.level1.base,
   },
   mainButtonStyle: {
      color: theme.palette.common.white,
      textTransform: 'none',
      borderRadius: 4,
      backgroundColor: theme.palette.button.standard.primary,
      marginTop: `${theme.spacing(2)}px !important`,
      padding: '14px 0',
      lineHeight: '20px !important',
      height: '43px !important',
      width: '100%',
      '&:disabled': {
         color: `${theme.palette.button.standard.label.disabled} !important`,
         backgroundColor: `${theme.palette.button.standard.disabled} !important`,
      },
      '&:hover': {
         backgroundColor: theme.palette.button.standard.primary,
      },
   },
   buttonStyle: {
      textTransform: 'none',
      margin: `${theme.spacing(7)}px ${theme.spacing(3)}px ${theme.spacing(0)} !important`,
   },
   firstTitleStyle: {
      marginBottom: theme.spacing(3),
      textAlign: 'center',
   },
   titleStyle: {
      margin: theme.spacing(4, 5, 2),
      textAlign: 'center',
   },
   subtitleStyle: {
      textAlign: 'center',
   },
   gapStyle: {
      color: 'white',
      fontWeight: 'bold',
      marginTop: theme.spacing(2),
   },
   linkOuterStyle: {
      marginTop: theme.spacing(1.5),
      textAlign: 'center',
   },
   linkStyle: {
      color: 'white',
      display: 'contents',
      textAlign: 'center',
      fontWeight: 'bold',
      // color: '#5D788F !important',
   },
   labelStyle: {
      alignSelf: 'flex-start',
      marginTop: theme.spacing(4),
   },
   spinnerMargin: {
      marginLeft: theme.spacing(0.5),
   },
});

/**
 * The component for the Custom Authorization workflow to check the email for the authorization code and enter it.
 */
class CheckEmailPanel extends PureComponent {

   static propTypes = {
      classes: PropTypes.object.isRequired,        //Styles for the component.
      intl: PropTypes.any.isRequired,           // Localization object.
      email: PropTypes.string.isRequired,          // The email used to get the authorization code.
      onManualSignIn: PropTypes.func.isRequired,   // Called when the user selects to use manual sign in.
      onSubmit: PropTypes.func.isRequired,         // Called when the user selects to sign in with the code.
   };

   constructor(props) {
      super(props);

      this.state = {
         code: undefined,
         isChanged: false,
         isSaving: false,
         showError: undefined,
      };

      document.addEventListener('keydown', this.handleKey, false);
   }

   componentWillUnmount() {
      document.removeEventListener('keydown', this.handleKey, false);
   }

   handleKey = (event) => {
      if (!event.defaultPrevented) {
         if (event.key === 'Enter') {
            this.handleSignInSubmit(event);
         }
      }
   };

   /**
    * Handle changes to the item.
    * @param target The target of the event that triggered the change.
    */
   handleChange = ({target}) => {
      this.setState({[target.name]: target.value, isChanged: true, showError: undefined});
   };

   handleSignInSubmit = async event => {
      const {onSubmit} = this.props;
      const {code, isChanged} = this.state;

      event.preventDefault();
      try {
         this.setState({isSaving: true, showError: undefined});
         if (code !== undefined && isChanged) {
            const trimmedCode = code.trim();
            if (trimmedCode.length === 6 && !isNaN(Number(trimmedCode))) {
               onSubmit && await onSubmit(trimmedCode);
               this.setState({isChanged: false});
            } else {
               this.setState({showError: 'checkEmail.code.error'});
            }
         } else {
            this.setState({showError: 'checkEmail.code.error'});
         }
      } catch (error) {
         if (error === 'not authenticated' || error.message === 'not authenticated') {
            this.setState({showError: 'checkEmail.invalidCode.error'});
         } else {
            this.setState({showError: 'checkEmail.code.error'});
         }
      } finally {
         this.setState({isSaving: false});
      }
   };

   render() {
      const {classes, intl, email, onManualSignIn} = this.props;
      const {isSaving, showError} = this.state;

      return (
         <div className={classes.mainGrid}>
            <form onSubmit={this.handleSignInSubmit}>
               <Grid container direction={'column'} className={classes.contentStyle} wrap={'nowrap'}
                     alignItems={'center'}>
                  <Typography className={`${classes.firstTitleStyle} level-2-heading`} id={'login.title'}/>
                  <img src={MAILBOX_ICON} alt=''/>
                  <Typography className={`${classes.titleStyle} level-3-heading`} id={'checkEmail.title'}/>
                  <div className={`${classes.subtitleStyle} tight-default-text`}>
                     {formatMessage(intl, 'checkEmail.subtitle')}
                     <b>{email}</b>
                     {formatMessage(intl, 'checkEmail.subtitle2')}
                  </div>

                  <Typography className={`label-text ${classes.labelStyle}`} id={'checkEmail.code.label'}/>
                  <FormattedTextField
                     name='code'
                     required
                     autoFocus
                     fullWidth
                     value={this.state.code}
                     onChange={this.handleChange}
                     error={!!showError}
                     helperText={showError && formatMessage(intl, showError)}
                     margin='none'
                     placeholder={formatMessage(intl, 'checkEmail.code.placeholder')}
                     inputProps={{className: classes.fullWidthInput}}
                     disabled={isSaving}
                  />

                  <Button className={classes.mainButtonStyle} disabled={isSaving} onClick={this.handleSignInSubmit}>
                     <Typography color='inherit' className={'large-button-text'} id={'login.signIn.button'}/>
                     {isSaving && <CircularProgress className={classes.spinnerMargin} size={15} thickness={2.5}/>}
                  </Button>
                  <Button variant={'text'} color='secondary' className={`signin-minimal-button ${classes.buttonStyle}`}
                          onClick={onManualSignIn} disableRipple={true} type='submit'>
                     <Typography color='inherit' className={'large-button-text'} id={'checkEmail.signin.label'}/>
                  </Button>
               </Grid>
               <Grid container direction={'column'}>
                  <Typography className={`${classes.gapStyle} ${classes.subtitleStyle} text-small`}
                              id={'checkEmail.noEmail.text'}/>
                  <Typography className={`${classes.linkOuterStyle} text-small`}>
                     <Link href={'mailto:support@heavyworth.com'} underline='always'
                           className={` ${classes.linkStyle}`}>
                        support@heavyworth.com
                     </Link>
                  </Typography>
                  <Typography className={`${classes.linkOuterStyle} default-text-small`}>
                     <Link href={'tel:1-833-444-3289'}
                           className={`${classes.linkStyle} text-small`}>
                        1-833-444-3289
                     </Link>
                  </Typography>
               </Grid>
            </form>
         </div>
      );
   }
}

export default withStyles(styles)(injectIntl(CheckEmailPanel));
