import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import withStyles from '@material-ui/core/styles/withStyles';
import {castArray} from 'lodash';
import React, {PureComponent} from 'react';
import {RESUBMIT_IMAGE_TO_IMS, IMAGE_VIEW_KEYS, IMAGE_VIEW_CONVERT} from '../../../Constants';
import ModalDialog from '../../../fhg/components/dialog/ModalDialog';
import DisplayError from '../../../fhg/components/DisplayError';
import Typography from '../../../fhg/components/Typography';
import {cloneWithoutPrivateProperties} from '../../../fhg/utils/Utils';
import {requestForServer} from '../../../Utils/ServerUtil';
import PropTypes from 'prop-types';

const styles = theme => ({
   radioDense: {
      padding: theme.spacing(0.5),
      color: theme.palette.content.dark, //'rgba(104,91,66,0.8)',
   },
   radioColor: {
      color: `${theme.palette.button.standard.primary} !important`,
   },
});

/**
 * Component to show the Category selection dialog for a photo.
 */
class PhotoCategorySelect extends PureComponent {

   static propTypes = {
      images: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]).isRequired,  // One or more
                                                                                                        // images to
                                                                                                        // categorize.
      onRefresh: PropTypes.func, //Called when the parent items need to refresh based on changes.
   };

   constructor(props, context) {
      super(props, context);

      const images = castArray(props.images);
      this.state = {
         photoCategory: (images.length === 1) &&
            (IMAGE_VIEW_CONVERT[images[0].image_view] || images[0].image_view || 'BEXT'),
      }

   }

   onSubmit = () => {
      const {images, onClose, onRefresh} = this.props;
      const imageArray = castArray(images);

      if (imageArray.length > 0) {
         this.setState({isSaving: true, error: undefined});
         const update = [];
         for (const image of imageArray) {
            update.push(cloneWithoutPrivateProperties({...image, image_view: this.state.photoCategory}));
         }
         // console.log(imageArray, imageArray[0])
         requestForServer(RESUBMIT_IMAGE_TO_IMS.format({itemId: imageArray[0].item_id, imageId: imageArray[0].image_id}), 'patch', update).then((data) => {

            // let stateImages = updatePhotos(imageArray, itemId);
            // console.log('Done', data)
            for (const image of imageArray) {
               image.image_view = this.state.photoCategory;
            }
         })
         .catch(error => {
            this.setState({error});
         }).finally(() => {
            this.setState({isSaving: false});
            onRefresh && onRefresh();
            onClose && onClose();
         });
      }
   };

   handleRadioChange = ({target}) => {
      this.setState({[target.name]: target.value, isChanged: true});
   };

   render() {
      const {classes, onClose, open = true, images = []} = this.props;
      const {isSaving, error} = this.state;
      const countImages = castArray(images).length;


      console.log('<------------IMAGES------------->', images)

      return (
         <ModalDialog
            open={open}
            onClose={onClose}
            onSubmit={this.onSubmit}
            titleKey={'photoDetail.changeCategory.title'}
            isSaving={isSaving}
            submitKey={'photoDetail.changeCategory.submit'}
            maxWidth={'xs'}
            isForm
         >
            <DisplayError error={error} errorId='photoDetail.changeCategory.error'/>
            <FormControl component='fieldset' disabled={isSaving} margin={'dense'}>
               <RadioGroup
                  aria-label='PhotoCategory'
                  // row
                  name='photoCategory'
                  value={this.state.photoCategory}
                  onChange={this.handleRadioChange}
               >
                  {Object.entries(IMAGE_VIEW_KEYS).map(([key, value]) => {
                     if (countImages <= 1 || key !== 'AMKT') {
                        return (
                           <FormControlLabel
                              key={key}
                              value={key}
                              control={<Radio className={classes.radioDense} classes={{checked: classes.radioColor}}/>}
                              label={<Typography color='textSecondary' variant={'body1'}
                                                 id={value}/>}
                           />
                        )
                     } else {
                        return null;
                     }
                  })}
               </RadioGroup>
            </FormControl>
         </ModalDialog>
      );
   }
}

export default withStyles(styles)(PhotoCategorySelect);