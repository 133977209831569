import Grid from '@material-ui/core/Grid';
import Search from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import {parse} from 'query-string';
import React, {useEffect, useRef} from 'react';
import useKeyDown from '../useKeyDown';
import useEditData from './edit/useEditData';
import {useLocation} from 'react-router-dom';
import { FTButtonIcon } from '../../components/buttons';
import { FTRadioGroup } from '../../components/radio/FTRadioGroup';
import { FTSearchInput } from '../../components/inputs';

SearchWithButton.propTypes = {
   search: PropTypes.string,
   placeholder: PropTypes.string,
   onChange: PropTypes.func,
};

/**
 * Text component for entering a search string.
 *
 * @param search The search string to search for the initial search
 * @param placeholder The placeholder text for the search input.
 * @param onChange Callback when the search string is submitted.
 * @return {JSX.Element}
 * @constructor
 */
export default function SearchWithButton({search:searchProp, onChange, placeholder}) {
   const location = useLocation();

   const searchParams = useRef(parse(location.search, {parseBooleans: false, parseNumbers: false})).current;
   const search = searchProp || !!searchParams.search ? searchParams.search : '';

   const [editValues, handleChange, {setDefaultValues, defaultValues, resetValues}] = useEditData({search, searchType: searchParams.type || 'Catalog'});

   /**
    * Update the default search string when the search property changes.
    */
   useEffect(() => {
      setDefaultValues({search, searchType: searchParams.type || 'Catalog'});
   }, [search, searchParams, setDefaultValues]);

   /**
    * Submit the current search text. If the search text hasn't changes, the search is ignored.
    */
   const handleSubmit = () => {
      onChange && onChange(editValues.search || defaultValues.search, editValues.searchType || defaultValues.searchType);
   };

   //Handle escape and enter keys.
   const [handleKeyDown] = useKeyDown(resetValues, handleSubmit, true);

   return (
      <Grid container alignItems="center" style={{ marginTop: 32 }}>
         <FTSearchInput 
            name={'search'}
            onChange={handleChange}
            defaultValue={defaultValues.search}
            value={editValues.search}
            onKeyDown={handleKeyDown}
         />
         <FTRadioGroup 
            name='searchType'
            defaultValue={defaultValues.searchType}
            value={editValues.searchType}
            onChange={handleChange}
            items={[
               {
                  value:'Catalog',
                  title:'evalDashboard.catalog.label',
                  tooltipTitle:'Use any catalog sort field for searching (i.e. Customer Name, primary contact, catalog status, etc.)'
               },
               {
                  value:'Asset',
                  title:'evalDashboard.asset.label',
                  tooltipTitle:'Use item sort field for searching (i.e. make, model, item type, miles, etc.)'
               }
            ]}
         />
         <Grid item>
            <FTButtonIcon
               title='evalDashboard.search.label'
               buttonAction={handleSubmit}
               disabled={!editValues.search && !defaultValues.search}
               icon={<Search />}
            />
         </Grid>
      </Grid>
   );
}
