import {compact, sumBy, toString, isNil, toNumber} from 'lodash';
import get from 'lodash/get';
import moment from 'moment';
import {getConsensusEstimate} from '../components/eval/EquipmentUtil';
import googleConfig from '../config/google';
import {DATE_ONLY_FORMAT, GOOGLE_INIT_TIMEOUT, GOOGLE_SHEET_ROW_HEIGHT} from '../Constants';
import ExportCsv from '../fhg/utils/ExportUtils';
import {getProfile} from '../fhg/utils/Utils';
import {isFeatureEnabled, SUB_PACKAGES, SEE_ESTIMATES, getUserData} from './ServerUtil';

const gapi = window.gapi;
const googleSheetsSingleton = new GoogleSheets();
let isInitialized = false;

GoogleSheets.isSignedIn = false;

/**
 * GoogleSheets is a class to export a list of assets to a Google Sheets spreadsheet. The main entry points are:
 * 1. exportSheets - Export the list of assets to a Google Sheets spreadsheet.
 * 2. signOut - Sign the user out of Google.
 */
export default function GoogleSheets() {
   if (googleSheetsSingleton) {
      return googleSheetsSingleton;
   }

   /**
    *  On load, called to load the auth2 library and API client library.
    */
   const handleClientLoad = async () => {
      return new Promise(async (resolve, reject) => {
         if (!isInitialized) {
            // Load the auth library for authentication and client for the sheets API.
            await gapi.load('client:auth2', {
               callback: async () => {
                  await initClient();
                  initAuthListener();
                  resolve();
               },
               onError: error => reject(error),
               timeout: GOOGLE_INIT_TIMEOUT,
               ontimeout: () => reject('Timeout')
            });
         } else {
            initAuthListener();
            resolve();
         }
      })
   }

   /**
    *  Initializes the API client library.
    *
    *  @return The promise for the completion of the initialization.
    */
   const initClient = () => {
      return gapi.client.init(googleConfig).then(function () {
         isInitialized = true;
      }, function (error) {
         console.log('Google Sheets error', error);
         isInitialized = false;
      });
   }

   /**
    * Setup a listener for when the user is signed in or out and set the initial status.
    */
   const initAuthListener = () => {
      gapi.auth2.getAuthInstance().isSignedIn.listen(updateSignInStatus);

      // Handle the initial sign-in state.
      updateSignInStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
   }

   /**
    *  Sign in the user upon button click.
    *
    *  @return The promise for the completion of the signin.
    */
   const signIn = () => {
      return gapi.auth2.getAuthInstance().signIn().catch(error => {
         if (error.error === 'popup_closed_by_user') {
            return new Error('Sign in canceled by user.');
         }
         console.log('Error signing in to google sheets.', error);
         return new Error('Error signing in to google sheets.');
      });
   }

   /**
    *  Callback for when the signed in status changes, to update the UI appropriately.
    */
   const updateSignInStatus = async (isSignedInProp) => {
      GoogleSheets.isSignedIn = isSignedInProp;
   }

   /**
    *  Sign out the user from Google.
    */
   const signOut = () => {
      if (GoogleSheets.isSignedIn) {
         return gapi.auth2.getAuthInstance().signOut();
      }
   }

   /**
    * Export the assets to Google Sheets. A new Google Sheets spreadsheet is created on each invocation.
    *
    * If the library hasn't been initialized and/or the user isn't logged into google, those are done before exporting
    * the spreadsheet.
    *
    * @param title The title of the Google Sheets spreadsheet.
    * @param assets The assets to be written to Google Sheets.
    * @param includeEstimates Should individual estimates be included.
    * @return {Promise<void>} The promise for the completed export.
    */
   const exportSheets = async (title, assets, includeEstimates) => {

      if (!isInitialized) {
         await handleClientLoad();
      }
      if (!GoogleSheets.isSignedIn) {
         await signIn();
      }

      const response = await createSpreadSheet(title);
      const spreadSheetId = get(response, 'result.spreadsheetId');
      const sheetId = get(response, 'result.sheets[0].properties.sheetId');
      await writeSheet(spreadSheetId, sheetId, assets, includeEstimates);

      return spreadSheetId;
   }

   /**
    * Create a new Google Sheet spreadsheet document with the given title.
    *
    * @param title the title of the document.
    * @return {Promise<*>} The promise for when the document is created.
    */
   const createSpreadSheet = async (title) => {
      return await gapi.client.sheets.spreadsheets.create({properties: {title}});
   }

   /**
    * Create the Google Sheet spreadsheet body of the document. Each asset is added as a row in the spreadsheet.
    *
    * @param array The array of assets to be included.
    * @param showEstimates Indicates if the individual estimates are included.
    * @return {[]} The Google Sheets format for the body, which is an array of rows. Each row is an array of cells.
    */
   const createSheetBody = (array, showEstimates) => {
      let body = [];
      let totalStartIndex;
      const estimateTypes = getUserData().estimateTypes;

      for (let i = 0, length = array.length; i < length; i++) {
         let cells = [];
         let evaluation = array[i];
         let item = evaluation.item;
         if (item) {
            const {thumbnail, original} = getProfile(evaluation);
            cells.push(`=HYPERLINK("${original}", IMAGE("${thumbnail}"))`);
            cells.push(item.item_id);
            cells.push(moment(item.added || item.updated).format(DATE_ONLY_FORMAT));
            cells.push(item.valuation_type);
            if (isFeatureEnabled(SUB_PACKAGES)) {
               cells.push(item.subpackage);
            }
            cells.push(item.year);
            cells.push(item.make);
            cells.push(item.model);
            cells.push(item.description);
            cells.push(item.serial);
            cells.push(item.vin);
            cells.push(item.unique_id);
            cells.push(item.personal_id);
            cells.push(item.sticker_id);

            const meters = (typeof item.meters === 'string' ? JSON.parse(item.meters) : item.meters) || [];
            let validMeters = meters.map(meter => {
               if (meter.value) {
                  return (meter.name || meter.units) + '-' + meter.value;
               }
               return undefined;
            });
            validMeters = compact(validMeters);
            cells.push(validMeters.join(' / '));
            cells.push(item.notes);
            cells.push(`${window.location.protocol}//${window.location.host}${evaluation.uri}`);
            cells.push(evaluation.notes);

            //cache the starting column for the estimates for the totals row.
            totalStartIndex = cells.length;

            if (estimateTypes && estimateTypes.length > 0) {
               for (const estimateType of estimateTypes) {
                  if (estimateType.active) {
                     if (showEstimates && isFeatureEnabled(SEE_ESTIMATES) && estimateType.enable_workflow_columns) {
                        cells.push(ExportCsv.getEstimatesCsv(evaluation, estimateType));
                     }
                     cells.push(toString(getConsensusEstimate(evaluation, estimateType).value));
                  }
               }
            }
            body.push(cells);
         }
      }

      // Insert an empty row.
      body.push([]);

      let sumRow = ['Total Value'];
      let index = totalStartIndex;

      // Sum the estimates for the totals row.
      if (estimateTypes && estimateTypes.length > 0) {
         for (const estimateType of estimateTypes) {
            if (estimateType.active) {
               if (showEstimates && isFeatureEnabled(SEE_ESTIMATES) && estimateType.enable_workflow_columns) {
                  index++;
               }
               let char = String.fromCharCode(65 + (index % 26));
               if (index >= 26) {
                  char += char;
               }
               // Insert the sum operation in the cell (e.g. =Sum(S2:S4)). The calc starts from the second row to avoid the
               // header. body is an array of rows and the length is the number of rows.
               sumRow[index++] = `=Sum(${char}2:${char}${body.length})`;
            }
         }
      }

      body.push(sumRow);
      return body;
   }

   /**
    * Write the items to an existing spreadsheet.
    *
    * @param spreadsheetId The spreadsheet document ID to write the data to.
    * @param sheetId The ID of the sheet in the spreadsheet document.
    * @param assets The list of assets to write to the speadsheet.
    * @param includeEstimates Indicates if the individual estimates should be added.
    * @param valueInputOption The type of values the data contains. Defaults to "RAW" which means the spreadsheet enters
    *             the data as-is.
    * @return {*} The promise for the completion of the write to the spreadsheet.
    */
   const writeSheet = (spreadsheetId, sheetId, assets, includeEstimates, valueInputOption = 'USER_ENTERED') => {
      let columnTitles = [
         'Profile Photo', 'Item ID', 'Capture Date', 'Valuation Type', 'Subpackage',
         'Year', 'Make', 'Model', 'Item Type',
         'Serial', 'VIN', 'Unique ID #','Inventory ID', 'Sticker ID',
         'Meter Readings', 'Notes',
         'Items URL', 'Estimate Notes',
      ];
      const estimateTypes = getUserData().estimateTypes;
      if (estimateTypes && estimateTypes.length > 0) {
         for (const estimateType of estimateTypes) {
            if (estimateType.active) {
               if (includeEstimates && isFeatureEnabled(SEE_ESTIMATES) && estimateType.enable_workflow_columns) {
                  columnTitles.push(`${estimateType.display_name} Estimates`);
               }
               columnTitles.push(estimateType.display_name);
            }
         }
      }
      if (!isFeatureEnabled(SUB_PACKAGES)) {
         columnTitles = columnTitles.filter((value, index, arr) => { return value !== 'Subpackage';})
      }
      const bodyRows = createSheetBody(assets, includeEstimates);

      const body = {values: [columnTitles, ...bodyRows]};

      //Set the contents of the spreadsheet to the body.
      return gapi.client.sheets.spreadsheets.values.update({
         spreadsheetId: spreadsheetId,
         range: 'Sheet1',
         valueInputOption: valueInputOption,
         resource: body
      }).then(async () => {
         // Set the columns to auto resize based on the contents.
         const requests = [
            {
               'autoResizeDimensions': {
                  'dimensions': {
                     'sheetId': sheetId,
                     'dimension': 'COLUMNS',
                     'startIndex': 0,
                     'endIndex': get(bodyRows, '[0].length', 20),
                  }
               }
            },
            {
               'updateDimensionProperties': {
                  'range': {
                     'sheetId': sheetId,
                     'dimension': 'ROWS',
                     'startIndex': 1,
                     'endIndex': bodyRows.length,
                  },
                  'properties': {
                     'pixelSize': GOOGLE_SHEET_ROW_HEIGHT
                  },
                  'fields': 'pixelSize'
               }
            }
         ];
         return gapi.client.sheets.spreadsheets.batchUpdate({spreadsheetId: spreadsheetId, resource: {requests}});
      });
   }

   // Expose only the exportSheets and signOut, methods for public use.
   return {
      exportSheets,
      signOut
   }
}
