import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import withStyles from '@material-ui/core/styles/withStyles';
import {uniq} from 'lodash';
import React, {Component, Fragment} from 'react';
import FHGTypography from '../../../fhg/components/Typography';
import FormattedTextField from './FormattedTextField';

const styles = theme => ({
   titleStyle: {
      margin: theme.spacing(1, 2, 0.5, 2),
   },
   inputStyle: {
      margin: theme.spacing(0, 0, 2, 1),
   },
   selectStyle: {
      '& svg': {
         fill: theme.palette.environment.light.level3.base, //'#E6E3DC'
      },
   },
   itemStyle: {
      color: `${theme.palette.content.default} !important`
   },
   linkButton: {
      composes: 'no-print',
      zIndex: 1001,
      marginLeft: theme.spacing(1),
      color: "#4f2682 !important",
      [theme.breakpoints.down('xs')]: {
         padding: theme.spacing(0.5, 0.5),
      }
   },
   menuItemStyle: {
      paddingLeft: 16,
   },
   dividerStyle: {
      backgroundColor: theme.palette.environment.light.level0.accent,
   },
});

/**
 * SubpackageMenu component to show the existing subpackages for the catalog..
 */
class SubPackageMenu extends Component {

   constructor(props, context) {
      super(props, context);

      this.state = {
         isMenuOpen: false,
         isEdit: false,
         subpackages: (props.subpackages && props.subpackages.slice(0)) || [],
      };

      if (this.state.subpackages.length > 0) {
         this.state.subpackages.sort();
      }
   }

   /**
    * Close the menu.
    */
   handleClose = () => {
      this.setState({anchorEl: null, isMenuOpen: false});
   };

   /**
    * Show edit to create a new subpackage.
    */
   handleCreateNew = () => {
      this.setState({isEdit: true});
   };

   /**
    * Close the edit for creating a new subpackage.
    */
   handleCloseCreateNew = () => {
      this.setState({isEdit: false, newSubpackage: undefined});
   };

   /**
    * Called when the user presses a key. If the escape key is pressed, close the edit for subpackage. If the enter key
    * is pressed enter the newly created supbackage.
    *
    * @param event The key down event.
    */
   handleKeydown = (event) => {
      event.stopPropagation();

      if (event.key === 'Enter') {
         event.preventDefault();

         const modifiedSubpackages = uniq([...this.state.subpackages, this.state.newSubpackage]);
         this.setState({isEdit: false, newSubpackage: undefined, subpackages: modifiedSubpackages});

      } else if (event.key === 'Escape') {
         event.preventDefault();
         this.setState({isEdit: false, newSubpackage: undefined});
      }
   };

   /**
    * Handle changes to the edit.
    * @param target The target of the event that triggered the change.
    */
   handleNewChange = ({target}) => {
      this.setState({[target.name]: target.value});
   };

   /**
    * Handle the change to the selected menu item.
    *
    * @param subpackage The subpackage for the menu item.
    * @return {function(...[*]=)} The function for the menu item.
    */
   handleChange = subpackage => event => {
      const {onAddSubpackages} = this.props;
      this.setState({anchorEl: null, isMenuOpen: false}, () => {
         onAddSubpackages && onAddSubpackages(subpackage);
      });
   };

   /**
    * When the Subpackage menu button is clicked, open the menu.
    *
    * @param event The click event.
    */
   onClick = event => {
      event.preventDefault();
      event.stopPropagation();
      this.setState({isMenuOpen: true, anchorEl: event.currentTarget});
   };

   render() {
      const {classes} = this.props;
      const {isMenuOpen, anchorEl, isEdit, subpackages} = this.state;

      return (
         <Fragment>
            <Button className={classes.linkButton} variant={'text'} onClick={this.onClick} disableRipple>
               <FHGTypography className={classes.linkLabel} variant='button' color={'inherit'}
                              id={'subpackage.add.button'}/>
            </Button>
            <Menu
               open={isMenuOpen}
               className={classes.selectStyle}
               onClose={this.handleClose}
               anchorEl={anchorEl}
               anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
               transformOrigin={{vertical: 'top', horizontal: 'center'}}
               PaperProps={{style: {width: 210}}}
               MenuListProps={{disablePadding: true}}
            >
               <FHGTypography className={`nonessential-title-caps ${classes.titleStyle}`} id={'subpackage.title'}/>
               {subpackages.map(subpackage => (
                  <MenuItem className={classes.menuItemStyle} value={subpackage}
                            onClick={this.handleChange(subpackage)}>
                     <FHGTypography className={`label-text ${classes.itemStyle}`}>
                        {subpackage}
                     </FHGTypography>
                  </MenuItem>
               ))}
               {isEdit ? (
                  <FormattedTextField
                     name='newSubpackage'
                     className={classes.inputStyle}
                     onKeyDown={this.handleKeydown}
                     autoFocus
                     onBlur={this.handleCloseCreateNew}
                     value={this.state.newSubpackage}
                     onChange={this.handleNewChange}
                     margin='none'
                  />
               ) : <>
                  <Divider className={classes.dividerStyle}/>
                  <MenuItem className={classes.menuItemStyle} onClick={this.handleCreateNew}>
                     <FHGTypography className='secondary-minimal-button-small' id='subpackage.new.button'/>
                  </MenuItem>
               </>}
            </Menu>
         </Fragment>
      );
   }
}

export default withStyles(styles)(SubPackageMenu);