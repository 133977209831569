import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '../../fhg/components/Typography';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

interface Props {
  title: string;
  icon: React.ReactElement<SvgIconProps>;
  iconLocation?: 'start' | 'end';
  disabled?: boolean;
  buttonAction: () => void;
}

const FTButtonIcon: React.FC<Props> = ({
  title,
  icon,
  iconLocation = 'start',
  disabled,
  buttonAction,
}) => {

  return (
    <Button
      variant="text"
      className={'primary-button'}
      onClick={buttonAction}
      disabled={disabled}
      startIcon={iconLocation === 'start' ? icon : undefined}
      endIcon={iconLocation === 'end' ? icon : undefined}
    >
      <Typography color="inherit" variant="button" id={title} />
    </Button>
  );
};

export default FTButtonIcon;
