import { castArray, difference } from "lodash";
import compact from "lodash/compact";
// noinspection ES6CheckImport
import get from "lodash/get";
// eslint-disable-next-line
import { v4 as uuid } from "uuid";
import {
  UPDATE_ITEM,
  DELETE_ITEM,
  SAVE_ITEMS_TO_EVALUATION,
  ROTATE_IMAGE_URI,
  IMAGE_STATE_NOT_LOADED,
  RESUBMIT_ASSET_TO_IMS,
  SAVE_CATALOG_SHARING,
  GET_CATALOG_PDF,
  SUBSCRIPTIONS,
  SAVE_EVALUATIONS,
  GET_ASSET_PDF,
  GET_IMAGES_ZIP,
  SUBMIT_TO_IMS,
  SAVE_ESTIMATE,
  RESUBMIT_CATALOG_TO_IMS,
  RESUBMIT_IMAGE_TO_IMS,
  SAVE_IMS_FORM,
  SUBMIT_FORM_TO_IMS,
  DELETE_IMG,
} from "../Constants";
import { cloneWithoutPrivateProperties } from "../fhg/utils/Utils";
import {
  getUserData,
  requestForServer,
  showFile,
  setSendEmails,
} from "./ServerUtil";
//import {getYourEstimate} from "../components/eval/EquipmentUtil";
import eventCapture from "../eventList";
import {
  posthogCapture,
  trackIMSFormCreation,
  trackIMSFormChanged,
  trackIMSFormEdited,
} from "../PostHogFunction";

// eslint-disable-next-lineç
export const submitItem = (
  uuid = uuid(),
  saveItem,
  owner,
  ownerName,
  originalItem,
  evaluation
) => {
  //window?.posthog?.capture(eventCapture.SAVE_NEW_ITEM);
  posthogCapture(eventCapture.SAVE_NEW_ITEM);

  return new Promise(async (resolve, reject) => {
    let item = originalItem || {};
    item.item_id = uuid;
    item.owner = owner || undefined;
    item.owner_name = ownerName || undefined;
    item.year = saveItem.year || undefined;
    item.make = saveItem.make || undefined;
    item.model = saveItem.model || undefined;
    item.description = saveItem.description || undefined;
    item.location = saveItem.location || undefined;
    item.serial = saveItem.serial || undefined;
    item.vin = saveItem.vin || undefined;
    item.unique_id = saveItem.unique_id || undefined;
    item.personal_id = saveItem.personal_id || undefined;
    item.sticker_id = saveItem.sticker_id || undefined;
    item.lot_number = saveItem.lot_number || undefined;
    item.meters = saveItem.meters || undefined;
    item.features =
      saveItem.features && saveItem.features.length > 0
        ? compact(saveItem.features)
        : undefined;
    item.problems =
      saveItem.problems && saveItem.problems.length > 0
        ? compact(saveItem.problems)
        : undefined;
    item.notes = saveItem.notes || undefined;
    item.subpackage = saveItem.subpackage || undefined;
    item.valuation_type = saveItem.valuation_type || undefined;
    const userData = getUserData();
    item.user = userData.username;
    item.user_name = userData.name;
    item.user_email = userData.email;
    item.create_date = new Date().toISOString();
    if (evaluation && !originalItem) {
      item.initial_eval = evaluation.initial_eval || evaluation.eval_id;
    }

    try {
      const result = await requestForServer(
        UPDATE_ITEM.format({ itemId: item.item_id }),
        "put",
        item
      );
      if (result.status === 200) {
        // Add new items to the evaluation. Check to ensure evaluation exists and original item doesn't.
        if (!!evaluation && !originalItem) {
          const result2 = await requestForServer(
            SAVE_ITEMS_TO_EVALUATION.format({
              evalId: item.initial_eval,
              itemId: item.item_id,
            }),
            "put"
          );
          if (result2.status === 200) {
            resolve({ item: get(result, "data.item"), result });
          } else {
            reject(result2.errorValues);
          }
        } else {
          resolve({ item: get(result, "data.item"), result });
        }
      } else {
        reject(result.errorValues);
      }
    } catch (e) {
      reject(e);
    }
  });
};

export const deleteItem = (itemId) => {
  if (itemId) {
    //TODO turn on real code when available.
    return requestForServer(DELETE_ITEM.format({ itemId }), "del");
  } else {
    console.log(`Must supply item ID to delete an item. item ID = ${itemId}`);
  }
};

/**
 * Save the rotation degrees to the server for the image that was rotated.
 *
 * Note: The image __rotation stays in the image with the same rotation value. When the image data is refreshed, the new
 * image will be rotated and the __rotation value will be gone.
 *
 * @param image The image is being rotated.
 * @param newRotation The new rotation for the image.
 * @return {Promise<void>}
 */
export const saveRotation = async (image, newRotation) => {
  //window?.posthog?.capture(eventCapture.ROTATE_IMAGE);
  posthogCapture(eventCapture.ROTATE_IMAGE);
  let uri = encodeURIComponent(get(image, "sizes.original"));

  // Normalize rotations so they are all between 0 and 359.
  let rotation = newRotation % 360;
  // If the rotation went counter clockwise (i.e. negative degrees), change to positive degrees.
  if (rotation < 0) {
    rotation += 360;
  }
  const result = await requestForServer(
    ROTATE_IMAGE_URI.format({ uri, rotation }),
    "put"
  );
  image.sizes = result.data.sizes;
  image.__rotation = 0;
  image.__state = IMAGE_STATE_NOT_LOADED;
};

export const deletePhotos = (images, deleteImages = [], itemId) => {
  const imageArray = castArray(deleteImages);

  return new Promise((resolve, reject) => {
    if (deleteImages.length > 0) {
      if (itemId) {
        const deletePromises = [];

        for (const image of imageArray) {
          const { image_id, item_id } = image;

          // Assuming we have a format function for the URL template
          const deleteUrl = DELETE_IMG.format({
            itemId: item_id,
            imageId: image_id,
          });

          // Add each DELETE request to the promises array
          deletePromises.push(requestForServer(deleteUrl, "del", {}));
        }

        // Wait for all delete requests to complete
        Promise.all(deletePromises)
          .then((results) => {
            const hasErrors = results.some((result) => result.error);
            if (!hasErrors) {
              // Remove deleted images from the original array
              let updatedImages = difference(images, imageArray);
              resolve(updatedImages); // Return the updated image list
              //reload the page to get new data
              window.location.reload()
            } else {
              reject(new Error("Some images could not be deleted."));
            }
          })
          .catch((error) => {
            console.log(`Delete photo failed on server.`);
            console.log(error);
            reject(error);
          });
      } else {
        reject(new Error(`The item ID for the eval item doesn't exist`));
      }
    } else {
      resolve(images); // If no images to delete, resolve with the original array
    }
  });
};

/**
 * Update the photos in list on the server.
 * @param updatePhotos The photo or array of photos to update.
 * @param itemId The itemId of the asset to update with the photos.
 * @return {Promise<unknown>} The reject will have the error. The resolve has no return.
 */
export const updatePhotos = (updatePhotos = [], itemId) => {
  const photoArray = castArray(updatePhotos);

  return new Promise((resolve, reject) => {
    if (updatePhotos.length > 0) {
      if (itemId) {
        const updates = [];

        for (const photo of photoArray) {
          updates.push(cloneWithoutPrivateProperties(photo));
        }
        requestForServer(UPDATE_ITEM.format({ itemId }), "patch", updates)
          .then((result) => {
            if (!result.error) {
              resolve();
            } else {
              reject(result);
            }
          })
          .catch((error) => {
            console.log(`Update photo failed on server.`);
            console.log(error);
            reject(error);
          });
      } else {
        // console.log(`The item ID for the eval item doesn't exist`);
        // console.log(get(this.props, 'evalItem', 'The item does not exist'));
        reject(new Error(`The item ID for the item doesn't exist`));
      }
    } else {
      resolve();
    }
  });
};

export const downloadPdf = (uri, owner) => {
  return new Promise((resolve, reject) => {
    requestForServer(uri, undefined, undefined, "blob")
      .then((result) => {
        if (result.error) {
          reject(result.error);
        } else {
          //window?.posthog?.capture(eventCapture.PDF_DOWNLOAD);
          posthogCapture(eventCapture.PDF_DOWNLOAD);
          if (result.data && result.data.size > 0) {
            showFile(
              `${owner}_valuation_${new Date().toLocaleDateString()}`,
              result.data
            );
          }
          resolve(result.data && result.data.size > 0);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Download the catalog as a PDF.
 *
 * @param evaluationId The catalog ID of the catalog for the PDF
 * @param owner The catalog owner.
 * @param isIncludePhotos Indicates that all photos should be included in the PDF.
 * @param currentSortKey Current sorting keys - defaults to empty
 * @return {Promise<unknown>}
 */
export const downloadCatalogPdf = (
  evaluationId,
  owner,
  isIncludePhotos = false,
  currentSortKey = []
) => {
  let sorting = "";
  currentSortKey.forEach(function (value, index) {
    sorting += `&sorting[]=${index}|${value["id"]}|${value["desc"]}`;
  });
  const uri = GET_CATALOG_PDF.format({
    evaluationId,
    isIncludePhotos,
    sorting,
  });
  return downloadPdf(uri, owner);
};

/**
 * Download the asset as a PDF.
 *
 * @param itemId The asset ID of the asset for the PDF
 * @param owner The catalog owner.
 * @param isIncludePhotos Indicates that all photos should be included in the PDF.
 * @param currentSortKey Current sorting keys - defaults to empty
 * @return {Promise<unknown>}
 */
export const downloadAssetPdf = (
  itemId,
  owner,
  isIncludePhotos = false,
  currentSortKey = []
) => {
  let sorting = "";
  currentSortKey.forEach(function (value, index) {
    sorting += `&sorting[]=${index}|${value["id"]}|${value["desc"]}`;
  });
  const uri = GET_ASSET_PDF.format({ itemId, isIncludePhotos, sorting });
  return downloadPdf(uri, owner);
};

export const downloadAssetPdfSpecial = (evalId, itemId, owner, pdfType) => {
  const uri = `/catalogs/${evalId}/assets/${itemId}/${pdfType}.pdf`;
  return downloadPdf(uri, owner);
};

/**
 * Export the asset photos to a zip file.
 *
 * @param assetId The asset ID of the asset.
 * @param owner The catalog owner.
 * @return {Promise<unknown>}
 */
export const exportAssetPhotosToZip = (catalogId, assetId) => {
  const uri = GET_IMAGES_ZIP.format({ catalogId, assetId });
  return new Promise((resolve, reject) => {
    requestForServer(uri)
      .then((result) => {
        if (result.error) {
          reject(result.error);
        } else {
          //window?.posthog?.capture(eventCapture.EXPORT_PHOTOS_TO_ZIP_FILE);
          posthogCapture(eventCapture.EXPORT_PHOTOS_TO_ZIP_FILE);
          resolve();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const submitCatalogToIms = (catalogId) => {
  const uri = SUBMIT_TO_IMS.format({ catalogId });
  console.log(uri);

  return new Promise((resolve, reject) => {
    requestForServer(uri, "post", {})
      .then((result) => {
        if (result.error) {
          reject(result.error);
        } else {
          //window?.posthog?.capture(eventCapture.SUBMIT_CATALOG_TO_IMS);
          posthogCapture(eventCapture.SUBMIT_CATALOG_TO_IMS);
          resolve();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const reSumbmitCatalogToIms = (catalogId) => {
  const uri = RESUBMIT_CATALOG_TO_IMS.format({ catalogId });
  console.log(uri);

  return new Promise((resolve, reject) => {
    requestForServer(uri, "post", {})
      .then((result) => {
        if (result.error) {
          reject(result.error);
        } else {
          resolve();
        }
      })
      .catch((error) => {
        console.log("CATALOG SUBMIT ERROR", error);
        reject(error);
      });
  });
};

export const reSumbmitItemToIms = (itemId) => {
  const uri = RESUBMIT_ASSET_TO_IMS.format({ itemId });
  console.log("myURI", uri);

  return new Promise((resolve, reject) => {
    requestForServer(uri, "put", {})
      .then((result) => {
        if (result.error) {
          reject(result.error);
        } else {
          //window?.posthog?.capture(eventCapture.SUBMIT_CATALOG_TO_IMS);
          posthogCapture(eventCapture.SUBMIT_CATALOG_TO_IMS);
          resolve();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const submitFormToIms = (itemId) => {
  const uri = SUBMIT_FORM_TO_IMS.format({ itemId });
  console.log("myURI", uri);

  return new Promise((resolve, reject) => {
    requestForServer(uri, "post", {})
      .then((result) => {
        if (result.error) {
          reject(result.error);
        } else {
          //window?.posthog?.capture(eventCapture.SUBMIT_CATALOG_TO_IMS);
          //posthogCapture(eventCapture.SUBMIT_CATALOG_TO_IMS);
          resolve();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const saveIMSForms = (
  id,
  evalItem,
  prevEvalItem,
  setSaveForm,
  saveForm,
  successAlert,
  handleSaveSuccess
) => {
  const uri = SAVE_IMS_FORM.format({ itemId: id });

  return new Promise((resolve, reject) => {
    requestForServer(uri, "put", evalItem)
      .then((result) => {
        if (result.error) {
          reject(result.error);
        } else {
          resolve();

          if (evalItem) {
            handleSaveSuccess();
          }
          console.log("SUCCESSFULLY DATA SENT 2------------>>>>>", evalItem);
          console.log("SET Alert++++++++======>>>>>", successAlert);

          const fieldValuesChanged =
            JSON.stringify(prevEvalItem.ims_forms_data.groups) !==
            JSON.stringify(evalItem.ims_forms_data.groups);
          const idOrCategoryChanged =
            prevEvalItem.ims_forms_data.id !== evalItem.ims_forms_data.id ||
            prevEvalItem.ims_forms_data.category !==
              evalItem.ims_forms_data.category;

          if (
            !prevEvalItem ||
            !prevEvalItem.ims_forms_data ||
            Object.keys(prevEvalItem.ims_forms_data).length === 0
          ) {
            trackIMSFormCreation(
              evalItem.ims_forms_data.id,
              evalItem.item_id,
              evalItem.ims_forms_data.category,
              new Date().toISOString()
            );
          }

          if (fieldValuesChanged) {
            // Only field values changed
            trackIMSFormEdited(
              evalItem.ims_forms_data.id,
              evalItem.item_id,
              new Date().toISOString()
            );
          }

          if (idOrCategoryChanged) {
            // Only id/category changed
            trackIMSFormChanged(
              evalItem.ims_forms_data.id,
              evalItem?.item_id,
              new Date().toISOString()
            );
          }
        }
      })
      .catch((error) => {
        reject(error);
      })
      .finally(setSaveForm(false));
    console.log("SET SAVE FORM TO FALSE++++++++======>>>>>", saveForm);
  });
};

export const saveCatalogSharing = (employeeData, evalId) => {
  const uri = SAVE_CATALOG_SHARING.format({ evalId: evalId }); // Assuming itemId is replaced with the actual item ID
  // const requestBody = JSON.stringify(evalItem); // Convert evalItem to JSON

  return new Promise((resolve, reject) => {
    requestForServer(uri, "post", employeeData)
      .then((result) => {
        if (result.error) {
          reject(result.error); // Reject the promise with the error message
        } else {
          resolve(); // Resolve the promise if the request is successful
          console.log("SHARING WORKED PERFECTLY WELL========>>>>>>>>>>>>");
        }
      })
      .catch((error) => {
        reject(error); // Catch any other errors and reject the promise
      });
  });
};

export const reSumbmitImageToIms = (itemId, imageId) => {
  const uri = RESUBMIT_IMAGE_TO_IMS.replace("{itemId}", itemId).replace(
    "{imageId}",
    imageId
  );
  // const uri = RESUBMIT_IMAGE_TO_IMS.format({itemId/});
  //console.log("myURI", uri) // /items/{itemId}/submit_to_ims
  // console.log("ITEMS", itemId, "IMAGE", imageId )

  return new Promise((resolve, reject) => {
    requestForServer(uri, "put", {})
      .then((result) => {
        if (result.error) {
          reject(result.error);
        } else {
          resolve();
        }
      })
      .catch((error) => {
        reject(error);
        alert("IMAGE RESUBMIT ERROR ERROR");
      });
  });
};

/**
 * Submit the change to send emails. Sets the global property for other components.
 * @param isSendEmails True if the emails should be sent.
 * @return {Promise<void>} The promise for the completion of the submit request.
 */
export const submitSendEmails = async (isSendEmails) => {
  try {
    setSendEmails(isSendEmails);
    await requestForServer(SUBSCRIPTIONS, "post", { emails: isSendEmails });
  } catch (error) {
    console.log("Could not save subscriptions.", error);
  }
};

/**
 * Submits existing evaluations for changes.
 *
 * CAVEAT: This will not work for a new evaluation.
 *
 * @param evaluation The changed evaluation. The object needs only the changed properties.
 * @param originalEvaluation The original evaluation before changes that contains all the properties.
 * @return {Promise<unknown>} The promise for the successful submission of the evaluation changes.
 */
export const submitEvaluation = async (evaluation, originalEvaluation) => {
  posthogCapture(eventCapture.CURRENT_SAVE_EVALUATION);

  return new Promise(async (resolve, reject) => {
    try {
      const modifiedEvaluation = { ...originalEvaluation, ...evaluation };
      const evalId = encodeURIComponent(originalEvaluation.PK);

      const result = await requestForServer(
        SAVE_EVALUATIONS.format({ evalId }),
        "put",
        modifiedEvaluation
      );
      if (!result.error) {
        //window?.posthog?.capture(eventCapture.SAVE_EVALUATION);
        posthogCapture(eventCapture.CURRENT_SAVE_EVALUATION);

        originalEvaluation.owner_name = modifiedEvaluation.owner_name;
        originalEvaluation.owner = modifiedEvaluation.owner;
        originalEvaluation.location = modifiedEvaluation.location;
        originalEvaluation.notes = modifiedEvaluation.notes;
        originalEvaluation.primary_contact = modifiedEvaluation.primary_contact;
        originalEvaluation.primary_contact_id =
          modifiedEvaluation.primary_contact_id;
        originalEvaluation.subscriptions = modifiedEvaluation.subscriptions;
        originalEvaluation.subpackages = modifiedEvaluation.subpackages;
        resolve(originalEvaluation);
      } else {
        reject(result.error);
      }
    } catch (error) {
      reject(error);
    }
  });
};
