import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '../../fhg/components/Typography';

interface Props {
  title: string;
  disabled?: boolean;
  buttonAction: () => void;
}

const FTButton: React.FC<Props> = ({
  title,
  disabled,
  buttonAction
}) => {

  return (
    <Button
      variant='text'
      className={'primary-button'}
      onClick={buttonAction}
      disabled={disabled}
      style={{width:'100%'}}
    >
      <Typography color='inherit' variant='button' id={title} />
    </Button>
  );
};

export default FTButton ;
