import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import {GET_FILES_ONLY} from '../../../Constants';
import DisplayErrorAndProgress from '../../../fhg/components/DisplayErrorAndProgress';
import {withRequest} from '../../../Utils/ServerUtil';
import Typography from '../../../fhg/components/Typography';
import {downloadPdf} from '../../../Utils/SubmitUtil';
import get from 'lodash/get';

const styles = theme => ({
   root: {
      width: '100%',
      height: '100%',
      margin: 0,
      '@media print': {
         height: '100%',
      },
      backgroundColor: theme.palette.environment.light.level1.base,
   },
   headingStyle: {
      margin: theme.spacing(4, 4, 4, 4),
   },
   titleStyle: {
      textTransform: 'none',
      whiteSpace: 'nowrap',
      paddingLeft: theme.spacing(1),
   },
   ownerNameStyle: {
      padding: theme.spacing(1.5, 3, 1.5, 1),
      [theme.breakpoints.down('xs')]: {
         padding: theme.spacing(.5, 1),
      },
   },
   rowStyle: {
      borderBottom: `1px solid ${theme.palette.environment.light.level1.accent}`,
   },
   paperStyle: {
      maxWidth: 594,
      margin: theme.spacing(3, 4, 4, 4),
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      overflow: 'hidden',
   },
   paperStyleCatalogs: {
      maxHeight: `calc(100% - 210px)`,
      '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
         height: '100%',
      },
      minHeight: 42,
      padding: theme.spacing(3, 3, 3, 3),
      [theme.breakpoints.down('xs')]: {
         padding: theme.spacing(2, 3, 3, 3),
      },
   },
   paperStyleNoCatalogs: {
      height: `calc(100% - 260px)`,
      maxHeight: 219,
      minHeight: 52,
      padding: theme.spacing(3, 3, 6, 3),
   },
   progressStyle: {
      top: 110,
   },
   emptyTitleStyle: {
      textAlign: 'center',
      marginBottom: theme.spacing(2),
   },
   emptyMessageStyle: {
      textAlign: 'center',
      display: 'contents',
   },
   linkStyle: {
      display: 'contents',
   },
   outerFrame: {
      maxWidth: 569,
      maxHeight: '100%',
      overflow: 'hidden',
   },
   rowFrame: {
      overflow: 'auto',
      paddingBottom: theme.spacing(3),
   },
   emptyFrame: {
      flexGrow: 1,
      flexShrink: 1
   },
   alignTextCenter: {
      textAlign: 'center',
   },
});

const dataStyles = {
   progress: {
      position: 'absolute',
      left: 'calc(50% - 20px)',
      top: 140,
      zIndex: 1001,
   },
};

/**
 * Component to display the list of valuations being done for assets in a catalog.
 */
class ValuationMain extends React.Component {
   static propTypes = {
      classes: PropTypes.object.isRequired,  // The styles for the component.
      data: PropTypes.object,                // The valuations for the assets in a catalog for the logged in user.
      isLoading: PropTypes.bool,             // Indicates if the data is still loading from the server.
   };

   state = {
      isExporting: false,
      showError: false,
   };

   downloadPdf = (evaluationId, owner) => async () => {
      try {
         this.setState({isExporting: true, showError: false});
         await downloadPdf(evaluationId, owner);
      } catch (error) {
         this.setState({showError: true, error});
      } finally {
         this.setState({isExporting: false});
      }
   };

   render() {
      const {classes, data, isLoading} = this.props;
      const {showError, isExporting, error} = this.state;

      const hasCatalogs = get(data, 'catalogs.length', 0) > 0;
      return (
         <Grid container className={classes.root} direction={'row'} justify={'center'}>
            {(showError || isExporting) && (
               <DisplayErrorAndProgress classes={{progressStyle: classes.progressStyle}}
                                        errorId={'valuation.download.error'} error={error} showProgress={isExporting}
                                        showOnProgress enableRefresh={false}/>
            )}
            {!isLoading && (
               <Grid item xs={12} className={classes.outerFrame}>
                  <Typography variant='h3' className={classes.headingStyle}>
                     {data && data.organization}
                  </Typography>
                  <Paper square className={`${classes.paperStyle} ${hasCatalogs ? classes.paperStyleCatalogs :
                     classes.paperStyleNoCatalogs}`}>
                     {hasCatalogs ? (
                        <Fragment>
                           <Typography variant='inherit' className={`${classes.titleStyle} nonessential-title-caps`}
                                       display={'block'} id={'valuation.assetOwner.title'}/>
                           <Grid item className={classes.rowFrame}>
                              {data.catalogs.map(asset => (
                                    <Grid container className={classes.rowStyle} direction={'row'}
                                          justify={'space-between'} alignItems={'center'}
                                    >
                                       <Typography className={`${classes.ownerNameStyle} label-text`}>
                                          {asset.owner_name}
                                       </Typography>
                                       <Button className={'minimal-secondary-button no-print'} variant={'text'}
                                               disableRipple={true}
                                               onClick={this.downloadPdf(asset.eval_id, asset.owner_name)}>
                                          <Typography color='inherit' variant='button' id={'valuation.download.button'}/>
                                       </Button>

                                    </Grid>
                                 )
                              )}
                           </Grid>
                        </Fragment>
                     ) : (
                        <Grid container className={classes.emptyFrame} alignItems={'center'} justify={'center'}>
                           <Grid container justify={'center'} className={classes.alignTextCenter}>
                              <Typography className={`${classes.emptyTitleStyle} level-3-heading`}
                                          id={'valuation.noCatalogs.title'}/>
                              <Typography className={`${classes.emptyMessageStyle} normal-default-text`}
                                          id={'valuation.noCatalogs.message'}>
                              </Typography>
                              <Link href={'mailto:support@heavyworth.com'} className={classes.linkStyle}>
                                 support@heavyworth.com
                              </Link>

                           </Grid>
                        </Grid>
                     )}
                  </Paper>
               </Grid>
            )}
         </Grid>
      );
   }
}

export default withStyles(dataStyles)(withRequest({
   uri: GET_FILES_ONLY,
   showProgress: true,
   showOnLoad: true,
   errorMessageId: 'valuation.fetch.error',
})(withStyles(styles)(ValuationMain)));
