import {Typography} from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {injectIntl} from 'react-intl';
import {formatMessage} from '../utils/Utils';

const styles = theme => ({
   popover: {
      pointerEvents: 'none',
   },
   paper: {
      padding: theme.spacing(1),
      margin: theme.spacing(-1, 0, 0, -1),
      backgroundColor: theme.palette.environment.light.level4.base, //'#DAE4EB',
   },
});

function isEllipsisActiveFunction(element) {
   return (element && element.offsetWidth < element.scrollWidth);
}

/**
 * The Typography component that supports intl. The default value is the child element.
 *
 * Example:
 * <Typography id='path.suppliers' variant='button'>Suppliers</Typography>
 */
class FHGTypography extends Component {

   state = {
      anchorEl: undefined,
      isEllipsisActive: false,
   };

   handlePopoverOpen = event => {
      const isEllipsisActive = isEllipsisActiveFunction(event.currentTarget);
      this.setState({isEllipsisActive, open: true, anchorEl: event.currentTarget});
   };

   handlePopoverClose = () => {
      this.setState({isEllipsisActive: false, open: false});
   };

   handleError = (e) => {
      console.log(e);
   };

   render() {
      // backgroundColor is used in the styles, but must removed from "otherProps".
      const {id, children, values, hasLineBreaks = false, intl, showFullOnHover, classes, backgroundColor, ...otherProps} = this.props;
      const message = id ? formatMessage(intl, id, children, values, this.handleError) : children;

      const isEllipsisActive = showFullOnHover && this.state.isEllipsisActive && this.state.open;


      return (
         <Fragment>
            <Typography id={id} {...otherProps}
                        onMouseEnter={showFullOnHover ? this.handlePopoverOpen : otherProps.onMouseEnter}
                        onMouseLeave={showFullOnHover ? this.handlePopoverClose : otherProps.onMouseLeave}
            >
               {hasLineBreaks ? linesToParagraphs(message) : message}
            </Typography>
            {isEllipsisActive && (
               <Popover
                  className={this.props.classes.popover}
                  classes={{
                     paper: this.props.classes.paper,
                  }}
                  open={isEllipsisActive}
                  anchorEl={this.state.anchorEl}
                  anchorOrigin={{
                     vertical: 'top',
                     horizontal: 'left',
                  }}
                  transformOrigin={{
                     vertical: 'top',
                     horizontal: 'left',
                  }}
                  onClose={this.handlePopoverClose}
                  disableRestoreFocus
               >
                  <Typography id={id} {...otherProps} >{hasLineBreaks ? linesToParagraphs(message) :
                     message}</Typography>
               </Popover>
            )}
         </Fragment>
      )
   }
}

function linesToParagraphs(...nodes) {
   return nodes
      .map(node => typeof node === 'string' ?
         node.split('\n').map(text => <p key={text}>{text}</p>) : node)
      .reduce((nodes, node) => nodes.concat(node), []);
}


FHGTypography.propTypes = {
   intl: PropTypes.object,
   id: PropTypes.string,                  // Key to message in the localization file.
   values: PropTypes.object,              // Values to use to fill parameters in the localized message.
   hasLineBreaks: PropTypes.bool,         // Indicates if the string could have line breaks.
   showFullOnHover: PropTypes.bool,       // Show full text on hover, when truncated. Property noWrap=true is required.
   ...Typography.propTypes,               // Supports all the properties from Typography.
};

FHGTypography.defaultProps = {
   variant: 'inherit',
   display: 'block',
   showFullOnHover: false,
};
FHGTypography.displayName = 'FHGTypography';

export default injectIntl(withStyles(styles)(FHGTypography));
