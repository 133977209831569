import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { isAdminOnly, isMultipleOrganization } from "../../../Utils/ServerUtil";
import { useEffect } from "react";
import { FTCheckbox } from "../../FTCheckbox";
import Menu from "@material-ui/core/Menu";
import { List, Divider } from "@mui/material";
import eventCapture from "../../../eventList";
import { posthogCapture } from "../../../PostHogFunction";
import { makeStyles } from "@material-ui/core/styles";
import NotFeature from "../../../fhg/security/NotFeature";
import { CUSTOMER_ENV } from "../../../Utils/ServerUtil";
import { FTButtonIconText } from "../../buttons";
import { FTFilterIcon } from "../../icons";
import { useEmployeesStore } from "../../zustandState/ZustandStore";
import { useMenuStore } from "../../zustandState/ZustandStore";

const useStyles = makeStyles((theme) => ({
  mouseTracker: {
    "&:focus": {
      outline: "none",
    },
  },
}));

function EmployeesFilterMenu() {
  const classes = useStyles();
  const { SelectedEmployeesState, setSelectedEmployeesState } =
    useEmployeesStore();
  const { MenuClosedState, setMenuClosedState } = useMenuStore();
  const [selectAllEmployees, setSelectAllEmployees] = useState(false);
  const [EMPLOYEES_NAMES, setEMPLOYEES_NAMES] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuClosed, setMenuClosed] = useState(false);

  useEffect(() => {
    fetchEmployeesData();

    const savedEmployees = localStorage.getItem("selectedEmployees");
    if (savedEmployees) {
      setSelectedEmployees(JSON.parse(savedEmployees));
    }
  }, []);

  useEffect(() => {
    if (!menuOpen) {
      setMenuClosed(true);
    } else {
      setMenuClosed(false);
    }
  }, [menuOpen]);

  // Sync local state with Zustand when local state changes
  useEffect(() => {
    setSelectedEmployeesState(selectedEmployees);
  }, [selectedEmployees, setSelectedEmployeesState]);

  useEffect(() => {
    setMenuClosedState(menuClosed);
  }, [menuClosed, setMenuClosed]);

  const handleEmployeeMenuToggle = (event) => {
    if (menuOpen) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
    setMenuOpen(!menuOpen);
  };

  const handleEmployeesSelect = (employeeName) => {
    posthogCapture(eventCapture.FILTERED_BY_EMPLOYEES);

    setSelectedEmployees((prevSelectedEmployees) => {
      const updatedEmployees = prevSelectedEmployees.includes(employeeName)
        ? prevSelectedEmployees.filter((name) => name !== employeeName)
        : [...prevSelectedEmployees, employeeName];

      // Store updated employees in localStorage
      localStorage.setItem(
        "selectedEmployees",
        JSON.stringify(updatedEmployees)
      );

      return updatedEmployees;
    });
  };

  useEffect(() => {
    console.log("Global State ========>", SelectedEmployeesState);
  }, [SelectedEmployeesState]);

  const handleSelectAllChange = () => {
    setSelectAllEmployees((prevSelectAll) => {
      const newSelectAllEmployees = !prevSelectAll;
      const updatedSelectedEmployees = newSelectAllEmployees
        ? [...EMPLOYEES_NAMES]
        : [];

      setSelectedEmployees(updatedSelectedEmployees);

      return newSelectAllEmployees;
    });
  };

  // <====== Call Employees name list =====>
  const fetchEmployeesData = async () => {
    let jwtToken;
    let tokenRefreshError = false;

    try {
      const session = await Auth.currentSession();
      jwtToken = session.idToken.jwtToken;
    } catch (e) {
      try {
        const session = await Auth.currentSession();
        jwtToken = session.idToken.jwtToken;
      } catch (e) {
        console.log("Error refreshing token", e);
        tokenRefreshError = true;
        // Allow to go through without jwtToken for server requests not requiring an authenticated user.
      }
    }

    const headers = {
      authorization: jwtToken,
      accept: "application/json",
    };
    // This adds a header for selected organization_ID or _Key which is stored in local storage
    if (isAdminOnly() || isMultipleOrganization()) {
      const organization = localStorage["hw." + window.btoa("organization")];
      if (organization && window.atob(organization)) {
        headers["HW-Organization"] = window.atob(organization);
      }
    }
    const options = {
      method: "GET",
      headers: headers,
    };

    const url = `${process.env.REACT_APP_API_ENDPOINT_V3}/employees`;

    try {
      // Make the API call
      const response = await fetch(url, options);

      if (response.ok) {
        // Parse the JSON response
        const employeeData = await response.json();
        const EMPLOYEE_NAME = [];

        // Loop through the employeesData and extract names
        employeeData.forEach((employee) => {
          console.log("EMP :", employee);
          EMPLOYEE_NAME.push(employee.name);
        });

        // Set the EMPLOYEES_NAMES filter in the state
        setEMPLOYEES_NAMES(EMPLOYEE_NAME);
      } else {
        console.error("API request failed with status:", response.status);
        // Handle the error as needed
      }
    } catch (error) {
      console.error("Error occurred during API request:", error);
      // Handle the error as needed
    }
  };

  return (
    <div>
      <NotFeature feature={CUSTOMER_ENV}>
        <FTButtonIconText
          id="employee-filter-button"
          title="evalDashboard.employees.column"
          buttonAction={handleEmployeeMenuToggle}
          icon={
            <FTFilterIcon
              activeFilter={selectedEmployees.length < EMPLOYEES_NAMES.length}
            />
          }
        />
        <Menu
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={handleEmployeeMenuToggle}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          getContentAnchorEl={null}
        >
          <List className={classes.menuTracker}>
            {EMPLOYEES_NAMES.map((employee) => (
              <FTCheckbox
                key={employee}
                value={employee}
                onClick={() => handleEmployeesSelect(employee)}
                checked={selectedEmployees.includes(employee)}
              />
            ))}
          </List>
          <Divider />
          <FTCheckbox
            key="Select All"
            value={"Select All"}
            onClick={handleSelectAllChange}
            checked={selectAllEmployees}
          />
        </Menu>
      </NotFeature>
    </div>
  );
}

export default EmployeesFilterMenu;
