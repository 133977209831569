import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import FormattedTextField from './FormattedTextField';
import get from 'lodash/get';

const styles = theme => ({
   textFieldInput: {
      padding: '7px !important',
      backgroundColor: 'inherit',
      // border: `1px solid ${theme.palette.divider} !important`,
      borderRadius: 4,
      color: `${theme.palette.text.primary} !important`, //#423313
      '&:focus': {
         padding: '6px !important',
         backgroundColor: theme.palette.common.white,
         border: `1px solid ${theme.palette.environment.dark.divider} !important`,
      },
      '&:disabled': {
         padding: '6px !important',
         color: `${theme.palette.text.disabled} !important`,
         border: `1px solid ${theme.palette.text.disabled} !important`,
      },
      '&:hover': {
         padding: '6px !important',
         border: `1px solid ${theme.palette.divider} !important`,
         backgroundColor: theme.palette.common.white,
      },
   },
   readOnly: {
      backgroundColor: 'transparent',
      border: `none !important`,
      color: `${theme.palette.text.primary} !important`, //#423313
      '&:focus': {
         border: `none !important`,
      },
      '&:disabled': {
         color: `${theme.palette.text.primary} !important`, //#423313
         border: `none !important`,
      },
   },
});

/**
 * The Component that handles special styling for ReadyOnly TextField with preset formats.
 */
class FormattedTextFieldXL extends Component {

   static propTypes = {
      isReadOnly: PropTypes.bool,                              // Indicates that the field cannot be editable.
      disabled: PropTypes.bool,                                // Indicates, editable but currently disabled.
   };

   static defaultProps = {
      isReadOnly: false,
      disabled: false,
   };

   render() {
      const { classes, isReadOnly, disabled, InputProps, inputProps = {}, ...textFieldProps } = this.props;

      if (!inputProps.name) {
         inputProps.name = 'tempName';
      }
      return (
         <FormattedTextField
            {...textFieldProps}
            disabled={disabled || isReadOnly}
            inputProps={inputProps}
            // eslint-disable-next-line
            InputProps={{
               ...InputProps,
               classes: {
                  root: get(InputProps, 'classes.root'),
                  input: classNames(get(InputProps, 'classes.input'), { [ classes.textFieldInput ]: !isReadOnly }, { [ classes.readOnly ]: isReadOnly }),
               },
            }}
         />
      );
   }
}

export default withStyles(styles)(FormattedTextFieldXL);
