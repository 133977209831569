import { Hidden } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import { withStyles } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Storage } from "aws-amplify";
import classNames from "classnames";
import { fromEvent } from "file-selector";
import { sortBy, maxBy, countBy, forEach, map, forOwn, delay } from "lodash";
import get from "lodash/get";
import PropTypes from "prop-types";
import React, { Component } from "react";
import DocumentTitle from "react-document-title";
import * as ReactDOM from "react-dom";
import Dropzone from "react-dropzone";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import "react-table/react-table.css";
// noinspection ES6CheckImport
import { v4 as uuid } from "uuid";
// noinspection ES6CheckImport
import {
  UPLOAD_IMAGE,
  EQUIPMENT_DETAIL_PROPERTY_WIDTH,
  MAX_PHOTO_SIZE,
  MAX_PHOTO_LIMIT,
  PHOTO_STATE_NOT_UPLOADED,
  PHOTO_STATE_ERROR,
  PHOTO_STATE_UPLOADED,
  PHOTO_STATE_ATTATCHED,
  DEFAULT_LOCATION,
  DROPBOX_SAVE,
  MAP_MIME_EXT,
  MAP_REJECTED_MIME_TYPE,
  MOVIE_MIME_TYPES,
} from "../../../Constants";
import ModalDialog from "../../../fhg/components/dialog/ModalDialog";
import Form from "../../../fhg/components/Form";
import Typography from "../../../fhg/components/Typography";
import { getImageObjects, formatMessage } from "../../../fhg/utils/Utils";
import { requestForServer } from "../../../Utils/ServerUtil";
import {
  submitItem,
  deleteItem,
  deletePhotos,
} from "../../../Utils/SubmitUtil";
import ErrorSnackbar, { SlideTransition } from "../components/ErrorSnackbar";
import { PromiseCollection } from "../components/PromiseCollection";
import AssetDetail from "../detail/AssetDetail";
import EquipmentEditForm from "./EquipmentEditForm";
import { sortedItems } from "./EquipmentList";
import PhotosDropZone from "./PhotosDropZone";

export const BUTTON_PANEL_HEIGHT = 60;
export const MAX_NEW_PHOTO_UPLOAD = 1000;

export function CloudUploadIcon2(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M20.55175,10.442 C20.55575,10.34975 20.55875,10.257 20.55875,10.1635 C20.55875,6.7595 17.797,4 14.39,4 C11.92275,4 9.79475,5.44775 8.80825,7.53875 C8.26025,7.148 7.58325,6.9165 6.85025,6.9165 C5.0265,6.9165 3.54825,8.34575 3.54825,10.1085 C3.54825,10.37025 3.5815,10.62425 3.643,10.8675 C1.56275,11.3105 0,13.192 0,15.4465 C0,18.03 2.052,20 4.58325,20 L11,20 L11,15 L8.8405,15 C8.62375,15 8.51,14.74225 8.65625,14.58225 L11.82825,11.111 C11.9245,11.00575 12.08175,11.00575 12.17775,11.11125 L15.34325,14.5845 C15.4885,14.744 15.3755,15 15.16,15 L13,15 L13,20 L19.614,20 C22.03625,20 24,17.9315 24,15.2265 C24,12.881 22.52325,10.92175 20.55175,10.442 Z"
        id="path-1"
      />
    </SvgIcon>
  );
}

const styles = (theme) => ({
  root: {
    // width: '100%',
    margin: "0 0",
    overflow: "hidden",
    backgroundColor: theme.palette.environment.light.level0.base,
    height: `100%`,
    flex: "1 1",
  },
  innerFrameStyle: {
    margin: "0 0",
    overflow: "hidden",
    backgroundColor: theme.palette.environment.light.level0.base,
    height: `100%`,
    flex: "1 1",
    width: "100%",
    minWidth: 260,
    minHeight: 300,
    [theme.breakpoints.down("xs")]: {
      height: "calc(100% - 170px)",
    },
  },
  frameGrid: {
    width: "100%",
    margin: "0 0",
    height: `calc(100% - ${80}px)`,
    overflow: "hidden",
    backgroundColor: theme.palette.environment.light.level0.base,
    flex: "1 1",
    [theme.breakpoints.down("xs")]: {
      height: "100%",
    },
  },
  baseStyle: {
    width: "100%",
    minWidth: 360,
    userSelect: "none",
    height: "100%",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    outline: "none",
  },
  activeStyle: {
    top: 45,
    width: "100%",
    height: "calc(100% - 85px)",
    backgroundColor: `${theme.palette.button.standard.success} !important`,
    zIndex: 3001,
    padding: 20,
    position: "absolute",
  },
  activeStyleInner: {
    border: `2px dashed white !important`,
    zIndex: 3001,
    width: "calc(100% - 50px)",
    height: "100%",
  },
  rejectStyle: {},
  editFormGrid: {
    width: "100%",
    height: `calc(100% - ${theme.spacing(2) + 5}px)`,
    overflow: "auto",
    backgroundColor: theme.palette.environment.light.level0.base,
    marginTop: theme.spacing(2) + 5,
    flex: "0 0 auto",
    padding: theme.spacing(0, 2),
    [theme.breakpoints.down("xs")]: {
      minWidth: 300,
      overflow: "hidden",
    },
  },
  buttonGrid: {
    flex: "0 0 auto",
    backgroundColor: theme.palette.environment.light.level2.base,
    margin: theme.spacing(0, -2),
    height: BUTTON_PANEL_HEIGHT,
  },
  spinnerMargin: {
    marginLeft: theme.spacing(0.5),
  },
  dropZoneStyle: {
    borderRight: `1px solid ${theme.palette.divider} !important`,
    padding: "16px",
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 210,
      height: 170,
    },
  },
  detailFrameStyle: {
    border: `1px solid ${theme.palette.environment.light.level1.accent}`,
    // 2 px for the border so that it shows all the way around.
    width: EQUIPMENT_DETAIL_PROPERTY_WIDTH + 2,
    flex: "0 0 auto",
    display: "flex",
    backgroundColor: theme.palette.environment.light.level1.base, //'#FAF9F5',
    position: "absolute",
    padding: theme.spacing(1, 2, 2),
    top: theme.spacing(2),
    right: 32,
    bottom: 83,
    zIndex: 5,
  },
  cancelStyle: {
    marginLeft: `${theme.spacing(2)}px !important`,
    marginRight: `0 !important`,
  },
  primaryButtonStyle: {
    width: 77,
  },
  noteStyle: {
    backgroundColor: theme.palette.environment.light.level1.base,
    color: theme.palette.text.primary,
    paddingRight: theme.spacing(6),
  },
});

/**
 * The Estimates notes dialog.
 */
class NewItemDialog extends Component {
  photoUpdates = [];

  static propTypes = {
    evalItem: PropTypes.object, //The evaluation item being edited. Undefined for a new item.
  };

  constructor(props) {
    super(props);

    this.state = NewItemDialog.getInitialState(get(props, "evalItem"));
    const locationState = get(props, "location.state", {});
    if (
      (locationState.isNew !== true && locationState.isEdit !== true) ||
      !props.evaluation
    ) {
      props.history.replace(DEFAULT_LOCATION);
    }
    this.state.status = get(props.evaluation, "evaluation.workflow_status");
    document.addEventListener("keydown", this.handleKey);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKey);
  }

  handleKey = (event) => {
    const form = get(event, "target.form");
    const isThisForm = form && form.id === "NewItemDialogId";
    if (isThisForm && !event.defaultPrevented) {
      if (event.key === "Escape") {
        event.preventDefault();
        event.stopPropagation();
        this.onConfirmCancel(event);
      } else if (
        event.target.name !== "notes" &&
        event.target.name !== "features"
      ) {
        if (event.key === "Enter") {
          event.preventDefault();
          event.stopPropagation();
          //*** NOTE: if an input has focus, it needs a blur event to force the item change. When Enter key is
          // pressed no blur event is given. We force the blur event to update the item before submit.
          event.target.blur();
          //Put on the bottom of the stack so the blur event takes effect.
          delay(() => {
            this.shouldSubmit(false)();
          }, 5);
        } else if (event.keyCode === 32 || event.keyCode === 13) {
          event.stopPropagation();
        }
      }
    }
  };

  static getInitialState = (evalItem) => {
    const theUuid = get(evalItem, "item.item_id", uuid());
    return {
      showConfirmCancel: false,
      isChanged: false,
      uuid: theUuid,
      showError: false,
      errorValues: undefined,
      errorId: undefined,
      isSaving: false,
      doCreate: false,
      isMoveNext: false,
      shouldSubmit: false,
      changedEvalItem: { links: [], ...evalItem, item_id: theUuid },
      item: (evalItem && evalItem.item) || {},
      photos: evalItem ? getImageObjects(evalItem) : [],
      uploadedPhotos: [],
      refresh: Date.now(),
      photoCategory: "BEXT",
    };
  };

  /**
   * Place the focus on the first input after mounting.
   */
  componentDidMount() {
    const thisNode = ReactDOM.findDOMNode(this);
    const elements = thisNode.querySelector('[name="year"]');
    if (elements) {
      elements.focus();
      elements.select();
    }
  }

  /**
   * Reinitialize the state when there is a new evalItem prop.
   *
   * @param prevProps The previous props.
   */
  componentDidUpdate(prevProps) {
    const currentUri = get(this.props, "evalItem.uri");
    const previousUri = get(prevProps, "evalItem.uri");

    if (currentUri !== previousUri) {
      this.setState(NewItemDialog.getInitialState(this.props.evalItem));
    }
  }

  /**
   * Close the error snackbar.
   */
  handleErrorClose = () => {
    this.setState({ errorId: undefined });
  };

  /**
   * Handle changes to the item.
   * @param item The item that has changed.
   */
  handleChange = (item) => {
    this.state.changedEvalItem.item = item;
    this.setState({
      item,
      changedEvalItem: this.state.changedEvalItem,
      isChanged: true,
    });
  };

  // onDrop = (newPhotos, rejectedPhotos) => {

  //    console.log("New Category photo Select", this.state.photoCategory);

  //    const {intl} = this.props;
  //    const {item, photos = [], uploadedPhotos = []} = this.state;
  //    let isError = false;

  //    if (newPhotos && newPhotos.length > 0) {
  //       if (newPhotos && (newPhotos.length + photos.length + uploadedPhotos.length) <= MAX_NEW_PHOTO_UPLOAD) {
  //          const maxSizePhoto = maxBy(newPhotos, 'size');
  //          if (maxSizePhoto && maxSizePhoto.size < MAX_PHOTO_SIZE) {
  //             const sortedNewPhotos = sortBy(newPhotos, ['name']);
  //             forEach(sortedNewPhotos, photo => {
  //                photo.__status = PHOTO_STATE_NOT_UPLOADED;
  //                photo.__category = this.state.photoCategory;
  //             });
  //             const sortedAllUploadedPhotos = uploadedPhotos.length > 0 ?
  //                sortBy(newPhotos.concat(uploadedPhotos), ['name']) : sortedNewPhotos;
  //             this.setState(
  //                {
  //                   uploadedPhotos: sortedAllUploadedPhotos,
  //                   showPhotoError: false,
  //                   errorId: undefined,
  //                   isChanged: true
  //                });

  //             const newUploadPromise = this.uploadPhotos(sortedNewPhotos, this.state.uuid, item, true);
  //             if (newUploadPromise) {
  //                if (!this.uploadPhotosPromise) {
  //                   this.uploadPhotosPromise = new PromiseCollection(newUploadPromise);
  //                   this.uploadPhotosPromise.all().finally(() => {
  //                      this.uploadPhotosPromise = undefined;
  //                   });
  //                } else {
  //                   this.uploadPhotosPromise.add(newUploadPromise);
  //                }
  //             }
  //          } else {
  //             isError = true;
  //             this.setState(
  //                {errorId: 'equipmentDetail.photo.error', errorValues: {maxSize: MAX_PHOTO_SIZE / 1024 / 1024}})
  //          }
  //       } else {
  //          isError = true;
  //          this.setState({errorId: 'newItem.maxPhotos.error', errorValues: {maxPhotos: MAX_NEW_PHOTO_UPLOAD}})
  //       }
  //    }
  //    if (!isError && rejectedPhotos && rejectedPhotos.length > 0) {
  //       const countBy1 = countBy(rejectedPhotos, 'type');
  //       const values = [];

  //       forOwn(countBy1, function (value, key) {

  //          values.push(formatMessage(intl, 'equipmentDetail.photoType2.error', 'Serial:',
  //             {count: value, type: MAP_REJECTED_MIME_TYPE[key] || key}));
  //       });
  //       let errorValues = '';
  //       if (values.length > 2) {
  //          for (let i = 0; i < values.length - 1; i++) {
  //             errorValues += values[i] + ', ';
  //          }
  //       } else {
  //          errorValues += values[0];
  //       }
  //       if (values.length >= 2) {
  //          errorValues += ' and ' + values[values.length - 1];
  //       }
  //       this.setState(
  //          {errorId: 'equipmentDetail.photoType.error', errorValues: {types: errorValues}});
  //    }
  // };

  onDrop = (newPhotos, rejectedPhotos) => {
    console.log("New Category photo Select", this.state.photoCategory); // Logs the correct category

    const { intl } = this.props;
    const { item, photos = [], uploadedPhotos = [] } = this.state;
    let isError = false;

    if (newPhotos && newPhotos.length > 0) {
      if (
        newPhotos &&
        newPhotos.length + photos.length + uploadedPhotos.length <=
          MAX_NEW_PHOTO_UPLOAD
      ) {
        const maxSizePhoto = maxBy(newPhotos, "size");
        if (maxSizePhoto && maxSizePhoto.size < MAX_PHOTO_SIZE) {
          const sortedNewPhotos = sortBy(newPhotos, ["name"]);
          forEach(sortedNewPhotos, (photo) => {
            photo.__status = PHOTO_STATE_NOT_UPLOADED;
            photo.__category = this.state.photoCategory; // Properly setting the category here
          });
          const sortedAllUploadedPhotos =
            uploadedPhotos.length > 0
              ? sortBy(newPhotos.concat(uploadedPhotos), ["name"])
              : sortedNewPhotos;
          this.setState(
            {
              uploadedPhotos: sortedAllUploadedPhotos,
              showPhotoError: false,
              errorId: undefined,
              isChanged: true,
            },
            () => {
              console.log(
                "After setting state, category is:",
                this.state.photoCategory
              ); // Double-checking
            }
          );

          const newUploadPromise = this.uploadPhotos(
            sortedNewPhotos,
            this.state.uuid,
            item,
            true
          );
          if (newUploadPromise) {
            if (!this.uploadPhotosPromise) {
              this.uploadPhotosPromise = new PromiseCollection(
                newUploadPromise
              );
              this.uploadPhotosPromise.all().finally(() => {
                this.uploadPhotosPromise = undefined;
              });
            } else {
              this.uploadPhotosPromise.add(newUploadPromise);
            }
          }
        } else {
          isError = true;
          this.setState({
            errorId: "equipmentDetail.photo.error",
            errorValues: { maxSize: MAX_PHOTO_SIZE / 1024 / 1024 },
          });
        }
      } else {
        isError = true;
        this.setState({
          errorId: "newItem.maxPhotos.error",
          errorValues: { maxPhotos: MAX_NEW_PHOTO_UPLOAD },
        });
      }
    }
  };

  onDropboxDrop = (newPhotos) => {
    const { uploadedPhotos = [] } = this.state;
    const sortedNewPhotos = sortBy(newPhotos, ["name"]);
    forEach(sortedNewPhotos, (photo) => {
      photo.__status = PHOTO_STATE_NOT_UPLOADED;
      photo.__category = this.state.photoCategory;
    });
    const sortedAllUploadedPhotos =
      uploadedPhotos.length > 0
        ? sortBy(newPhotos.concat(uploadedPhotos), ["name"])
        : sortedNewPhotos;
    this.setState({
      uploadedPhotos: sortedAllUploadedPhotos,
      showPhotoError: false,
      errorId: undefined,
      isChanged: true,
    });

    const newUploadPromise = this.uploadDropboxPhotos(
      sortedNewPhotos,
      this.state.uuid,
      this.state.photoCategory
    );
    if (newUploadPromise) {
      if (!this.uploadPhotosPromise) {
        this.uploadPhotosPromise = new PromiseCollection(newUploadPromise);
        this.uploadPhotosPromise.all().finally(() => {
          this.uploadPhotosPromise = undefined;
        });
      } else {
        this.uploadPhotosPromise.add(newUploadPromise);
      }
    }
  };

  /**
   * Indicates that the form should submit the form data.
   *
   * @param doCreate True if the Save and Create button was selected.
   * @param isMoveNext True if the Save and Move Next button was selected.
   * @return {function(...[*]=)} The callback function.
   */
  shouldSubmit =
    (doCreate = false, isMoveNext = false) =>
    () => {
      this.setState({ shouldSubmit: true, doCreate, isMoveNext });
    };

  /**
   * Callback for onValidate. If the value is not valid, it removes shouldSubmit so the next time the user submits, it
   * will validate again.
   * @param isValid
   */
  handleValidate = (isValid) => {
    if (!isValid) {
      this.setState({
        shouldSubmit: false,
        doCreate: undefined,
        isMoveNext: false,
      });
    }
  };

  /**
   * Submits the changes to the item and photos.
   */
  onSubmit = async () => {
    const { location, onUpdate, evaluation, evalItem } = this.props;
    const {
      isChanged,
      isSaving,
      item: changedItem,
      uuid,
      doCreate,
      isMoveNext,
    } = this.state;

    if (isChanged && !isSaving) {
      this.setState({ isSaving: true, shouldSubmit: false });
      try {
        const { item } = await submitItem(
          uuid,
          changedItem,
          location.state.owner,
          location.state.ownerName,
          evalItem && evalItem.item,
          !evalItem && evaluation.evaluation
        );

        // If photos are still uploading, close after the photos have finished uploading.
        if (this.uploadPhotosPromise) {
          this.setState({ isUploadingPhotos: true });
          this.uploadPhotosPromise
            .all()
            .then(async () => {
              if (!doCreate) {
                onUpdate && (await onUpdate(item, true));
                this.onClose(undefined, uuid);
              } else {
                this.clearFields();
                if (isMoveNext) {
                  this.handleMoveNext();
                }
              }
            })
            .finally(() => {
              this.setState({ isUploadingPhotos: false });
            });
        } else {
          if (!doCreate) {
            onUpdate && (await onUpdate(item, true));
            this.onClose(undefined, uuid);
          } else {
            this.clearFields();
            if (isMoveNext) {
              this.handleMoveNext();
            }
          }
        }
      } catch (error) {
        this.setState({
          isSaving: false,
          doCreate: false,
          isMoveNext: false,
          showError: true,
          errorId: "equipmentDetail.save.error",
          errorValues: error,
        });

        error && console.log(error);
      }
    } else {
      if (!doCreate) {
        this.onClose(undefined, uuid);
      } else {
        this.clearFields();
        if (isMoveNext) {
          this.handleMoveNext();
        }
      }
    }
  };

  /**
   * Upload all the photos to the server.
   *
   * @param photos Photos to upload
   * @param itemId The itemId of the capture to load the photos to.
   * @param item The equipment item True if the photos are being added to a new item.
   * @return {Promise<void>} The promise for the photos being uploaded.
   */
  // uploadPhotos = (photos = [], itemId, item) => {

  //    const updates = [];
  //    const current = {
  //       count: photos.length,
  //       lastError: undefined,
  //    };
  //    return new Promise(async (resolve, reject) => {
  //       const startingIndex = get(item, 'images.length', 0);
  //       for (const [index, file] of photos.entries()) {
  //          const imageUuid = uuid();
  //          const imageKey = file.type === 'application/pdf' || MOVIE_MIME_TYPES.indexOf(file.type) >= 0 ?
  //             `${itemId}/${file.name}` :
  //             `${itemId}/${imageUuid}${MAP_MIME_EXT[file.type]}`;

  //          if (!this.isCanceling) {
  //             Storage.put(imageKey, file, {level: 'public', contentType: file.type})
  //                .then(this.getOnFulfilled(file, updates, imageUuid, index, startingIndex, imageKey))
  //                .catch(this.getOnRejected(file, current))
  //                .finally(this.getOnFinally(current, itemId, updates, photos, reject, resolve));
  //          } else {
  //             this.savePhotosOnItem(itemId, updates, photos, reject, current.lastError, resolve);
  //          }
  //       }
  //    });
  // };

  uploadPhotos = (photos = [], itemId, item) => {
    const updates = [];
    const current = {
      count: photos.length,
      lastError: undefined,
    };
    return new Promise(async (resolve, reject) => {
      const startingIndex = get(item, "images.length", 0);
      for (const [index, file] of photos.entries()) {
        const imageUuid = uuid();
        const imageKey =
          file.type === "application/pdf" ||
          MOVIE_MIME_TYPES.indexOf(file.type) >= 0
            ? `${itemId}/${file.name}`
            : `${itemId}/${imageUuid}${MAP_MIME_EXT[file.type]}`;

        if (!this.isCanceling) {
          Storage.put(imageKey, file, {
            level: "public",
            contentType: file.type,
          })
            .then(
              this.getOnFulfilled(
                file,
                updates,
                imageUuid,
                index,
                startingIndex,
                imageKey
              )
            )
            .catch(this.getOnRejected(file, current))
            .finally(
              this.getOnFinally(
                current,
                itemId,
                updates,
                photos,
                reject,
                resolve
              )
            );
        } else {
          this.savePhotosOnItem(
            itemId,
            updates,
            photos,
            reject,
            current.lastError,
            resolve
          );
        }
      }
    });
  };

  /**
   * Upload all the dropbox photos to the server.
   *
   * @param photos Photos to upload
   * @param itemId The itemId of the capture to load the photos to.
   * @return {Promise<void>} The promise for the photos being uploaded.
   */
  uploadDropboxPhotos = (photos = [], itemId, photoCategory) => {
    return new Promise(async (resolve, reject) => {
      const photoLinksArray = map(photos, "link");
      if (!this.isCanceling) {
        try {
          await requestForServer(
            DROPBOX_SAVE.format({ itemId, category: photoCategory }),
            "post",
            photoLinksArray
          );
          for (const photo of photos) {
            photo.__status = PHOTO_STATE_ATTATCHED;
          }
          if (!this.isCanceling) {
            this.setState({ showPhotoError: false });
          }
          resolve();
        } catch (error) {
          console.log(error);
          for (const photo of photos) {
            photo.__status = PHOTO_STATE_ERROR;
          }
          if (!this.isCanceling) {
            this.setState({ showPhotoError: true });
          }
          reject(error);
        }
      }
    });
  };

  getOnFulfilled(file, updates, imageUuid, index, startingIndex, imageKey) {
    return (result) => {
      // noinspection EqualityComparisonWithCoercionJS
      // eslint-disable-next-line
      if (result && result.status === 400) {
        file.__status = PHOTO_STATE_ERROR;
        console.log("Failed - " + result.key);
      } else if (
        file.type !== "application/pdf" &&
        MOVIE_MIME_TYPES.indexOf(file.type) < 0
      ) {
        updates.push({
          image_id: imageUuid,
          order: index + startingIndex,
          image_view: file.__category,
          original_filename: file.name,
        });
        file.imageKey = imageKey;
        file.__status = PHOTO_STATE_UPLOADED;
      }
    };
  }

  getOnFinally(current, itemId, updates, photos, reject, resolve) {
    return () => {
      current.count--;
      if (current.count % 5 === 0 && !this.isCanceling) {
        this.setState({ refresh: Date.now() });
      }

      if (current.count <= 0) {
        this.savePhotosOnItem(
          itemId,
          updates,
          photos,
          reject,
          current.lastError,
          resolve
        );
      }
    };
  }

  getOnRejected(file, current) {
    return (error) => {
      file.__status = PHOTO_STATE_ERROR;
      current.lastError = error;
      console.log("Failed image upload - " + error);
    };
  }

  savePhotosOnItem(itemId, updates, photos, reject, lastError, resolve) {
    let isMarkedDeleted = false;
    if (this.isCanceling) {
      updates.forEach((update) => (update.deleted = true));
      isMarkedDeleted = true;
    }
    requestForServer(UPLOAD_IMAGE.format({ itemId }), "patch", updates)
      .then(() => {
        // Only keep track of updates for deleting from existing evalItem.
        if (this.props.evalItem && !isMarkedDeleted) {
          this.photoUpdates = this.photoUpdates.concat(updates);
        }

        for (const photo of photos) {
          photo.__status = PHOTO_STATE_ATTATCHED;
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        if (lastError && !this.isCanceling) {
          this.setState({ showPhotoError: true });
          reject(lastError);
        } else {
          if (!this.isCanceling) {
            this.setState({ showPhotoError: false });
          }
          resolve();
        }
      });
  }

  /**
   * Close the edit view.
   */
  onClose = (event, itemId) => {
    const { history, location, onClose } = this.props;
    this.setState({ isChanged: false }, () => {
      onClose && onClose();
      const backPath = get(location, "state.backPath");
      if (backPath) {
        if (backPath.indexOf("evaluation") >= 0) {
          history.replace(backPath);
        } else {
          history.replace(location.pathname, { isEdit: false });
        }
      } else {
        const previousLocation = history.getPreviousLocation();
        if (previousLocation) {
          history.replace(previousLocation);
        } else {
          if (location.pathname !== "/new") {
            history.replace(location.pathname);
          } else if (itemId) {
            history.replace(`/items/${itemId}`);
          } else {
            history.goBack();
          }
        }
      }
    });
  };

  onConfirmCancel = (event) => {
    const { isChanged } = this.state;
    if (isChanged) {
      this.setState({ showConfirmCancel: true });
    } else {
      this.onCancel(event);
    }
  };

  onCancelConfirm = () => {
    this.setState({ showConfirmCancel: false });
  };

  /**
   * Cancel the editing. Delete the auto created item that is holding photos.
   */
  onCancel = async (event) => {
    const { onUpdate, evalItem } = this.props;

    this.setState({ showConfirmCancel: false });

    //Immediately close the dialog and delete the item or photos in the background.
    this.onClose();

    // If photos are still being uploaded, they will be uploaded as marked deleted, but need to wait for call that
    // sets them on the item to know if they are all marked.
    if (this.uploadPhotosPromise) {
      this.isCanceling = true;
      await this.uploadPhotosPromise.all().finally(() => {
        this.isCanceling = false;
      });
    }
    // If creating a new item delete the item. Otherwise, delete photos that already have been added to the item that
    // aren't marked deleted.
    if (!evalItem) {
      deleteItem(this.state.uuid);
    } else {
      deletePhotos(
        getImageObjects(evalItem),
        this.photoUpdates,
        this.state.uuid
      ).finally(() => {
        this.photoUpdates = [];
      });
    }

    onUpdate && (await onUpdate(null, true));
  };

  /**
   * Move to edit the next item in the sortedItems list.
   */
  handleMoveNext = () => {
    const { evaluation, match, history, location } = this.props;
    const list =
      sortedItems && sortedItems.length > 0
        ? sortedItems
        : (evaluation && evaluation.items) || [];

    if (list) {
      let selectedIndex =
        list.findIndex((item1) => match.url.indexOf(item1.uri) >= 0) || 0;
      history.push(list[(selectedIndex + 1) % list.length].uri, {
        ...location.state,
        isEdit: true,
      });
    } else {
      console.log("The sortedItems list and evaluations list didn't exist");
    }
  };

  /**
   * Clear existing values to create another new item.
   */
  clearFields = () => {
    this.setState(NewItemDialog.getInitialState());
  };

  /**
   * Set the category based on the user selection.
   * @param photoCategory The new photo category.
   */
  // handlePhotoCategoryChange = (photoCategory) => {
  //     this.setState({photoCategory});
  // };

  handlePhotoCategoryChange = (photoCategory) => {
    this.setState({ photoCategory }, () => {
      console.log("Updated photoCategory:", this.state.photoCategory); // Log after updating state
    });
  };

  render() {
    const { classes, evaluation, evalItem } = this.props;
    const {
      photos = [],
      errorValues,
      errorId,
      isSaving,
      doCreate,
      shouldSubmit,
      uuid,
      isUploadingPhotos,
      showPhotoError,
      showConfirmCancel,
      refresh,
      uploadedPhotos = [],
    } = this.state;
    const title = `${process.env.REACT_APP_SITE_TITLE} - New Item`;
    const hasNext =
      (sortedItems && sortedItems.length > 1) ||
      (evaluation && evaluation.items && evaluation.items.length > 1);
    const isNew = !evalItem || !evalItem.item;
    const { processing = 0, uploading = 0 } = countBy(
      uploadedPhotos,
      "__status"
    );
    const total = processing + uploading;
    const remaining = total - uploading;

    return (
      <DocumentTitle title={title}>
        <React.Fragment>
          <ModalDialog
            open={showConfirmCancel}
            onClose={this.onCancelConfirm}
            onSubmit={this.onCancel}
            messageKey={
              evalItem
                ? "newItem.cancelConfirmPhotos.text"
                : "newItem.cancelConfirm.text"
            }
            titleKey={
              evalItem
                ? "newItem.cancelConfirmPhotos.title"
                : "newItem.cancelConfirm.title"
            }
            submitKey={
              evalItem
                ? "newItem.cancelDeletePhotos.label"
                : "newItem.cancelDelete.label"
            }
            cancelKey={"newItem.cancelConfirm.label"}
            submitColorStyle={"destroy-button"}
            maxWidth={"xs"}
            cancelColorStyle={"secondary-color"}
          />
          <Dropzone
            getDataTransferItems={(evt) => fromEvent(evt)}
            onDrop={this.onDrop}
            noClick={true}
            noKeyboard={true}
            accept={[
              "image/jpeg",
              "image/png",
              "image/webp",
              "application/pdf",
              ...MOVIE_MIME_TYPES,
            ]}
            disabled={isSaving}
          >
            {({
              getRootProps,
              getInputProps,
              isDragActive,
              isDragReject,
              open,
            }) => (
              <div
                {...getRootProps({ onKeyDown: this.handleKey })}
                className={classes.baseStyle}
              >
                <input {...getInputProps()} />
                <Grid
                  container
                  className={classes.frameGrid}
                  direction={"column"}
                  wrap={"nowrap"}
                  onKeyDown={this.handleKey}
                >
                  {isDragActive && (
                    <div className={classes.activeStyle}>
                      <div className={classes.activeStyleInner}>
                        <div
                          style={{
                            zIndex: 4000,
                            margin: "auto",
                            color: "white",
                            transform: "translate(-50%, -50%)",
                            left: "50%",
                            top: "50%",
                            position: "absolute",
                          }}
                        >
                          <CloudUploadIcon2
                            style={{
                              zIndex: 3002,
                              fontSize: 80,
                              color: "white",
                              marginLeft: "31%",
                            }}
                          />
                          <p style={{ margin: 0 }}>Drop to start uploading</p>
                        </div>
                      </div>
                    </div>
                  )}
                  <Grid
                    container
                    item
                    className={classNames(
                      classes.root,
                      // {[classes.activeStyle]: true || isDragActive},
                      { [classes.rejectStyle]: isDragReject }
                    )}
                    direction={"row"}
                    wrap={"wrap"}
                  >
                    {!!errorId && (
                      <ErrorSnackbar
                        open={!!errorId}
                        onClose={this.handleErrorClose}
                        errorId={errorId}
                        values={errorValues}
                        enableRefresh={false}
                      />
                    )}
                    <Snackbar
                      open={isUploadingPhotos}
                      anchorOrigin={{ vertical: "top", horizontal: "center" }}
                      ContentProps={{
                        "aria-describedby": "message-id",
                        classes: {
                          root: classes.noteStyle,
                        },
                      }}
                      TransitionComponent={SlideTransition}
                      message={
                        <Typography
                          id={"equipmentDetail.saveWhileUploading.info"}
                          color={"inherit"}
                        />
                      }
                    />
                    <Grid
                      container
                      item
                      xs={12}
                      sm={4}
                      className={classes.dropZoneStyle}
                      direction={"column"}
                      wrap={"nowrap"}
                    >
                      <PhotosDropZone
                        key={"upload" + uuid}
                        onDrop={this.onDrop}
                        onDropboxDrop={this.onDropboxDrop}
                        inputOpen={() => open()}
                        photos={photos}
                        uploadedPhotos={uploadedPhotos}
                        isDisabled={isSaving}
                        showError={showPhotoError}
                        refresh={refresh}
                        total={total}
                        remaining={remaining}
                        onPhotoCategoryChange={this.handlePhotoCategoryChange}
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      sm={8}
                      className={classes.innerFrameStyle}
                      direction={"column"}
                      wrap={"nowrap"}
                    >
                      <Form
                        id={"NewItemDialogId"}
                        forceValidate
                        shouldSubmit={shouldSubmit}
                        submit={this.onSubmit}
                        style={{ height: "100%" }}
                        onValidate={this.handleValidate}
                      >
                        <Grid
                          container
                          direction={"row"}
                          wrap={"nowrap"}
                          justify={"space-between"}
                          style={{
                            height: `calc(100% - ${BUTTON_PANEL_HEIGHT}px)`,
                            overflow: "hidden",
                            position: "relative",
                          }}
                        >
                          <Grid item xs={12}>
                            <Grid
                              container
                              className={classes.editFormGrid}
                              direction={"column"}
                              wrap={"nowrap"}
                              spacing={0}
                            >
                              <EquipmentEditForm
                                key={uuid}
                                onChange={this.handleChange}
                                item={evalItem && evalItem.item}
                                isNew={isNew}
                                defaultLocation={get(
                                  evaluation,
                                  "evaluation.location"
                                )}
                                evalItem={evalItem}
                              />
                            </Grid>
                          </Grid>
                          <Hidden smDown>
                            <Grid item className={classes.detailFrameStyle}>
                              <AssetDetail
                                evalItem={this.state.changedEvalItem}
                                showBackground
                                showSummaryTitle
                                showEquipmentTitle
                                showEditButton={false}
                                width={EQUIPMENT_DETAIL_PROPERTY_WIDTH + 18}
                              />
                            </Grid>
                          </Hidden>
                        </Grid>
                        <Grid
                          className={classes.buttonGrid}
                          container
                          spacing={2}
                          justify={"flex-start"}
                          alignItems={"center"}
                        >
                          <Grid item>
                            <Button
                              className={`${classes.cancelStyle} minimal-cancel-button`}
                              disabled={isSaving}
                              onClick={this.onConfirmCancel}
                            >
                              Cancel
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              className={`${classes.primaryButtonStyle} primary-button`}
                              onClick={this.shouldSubmit(false)}
                              disabled={isSaving || total > MAX_PHOTO_LIMIT}
                              color={"secondary"}
                              style={{ padding: "8px 4px !important" }}
                            >
                              <Typography color="inherit" variant="button">
                                Save
                              </Typography>
                              {isSaving && !doCreate && (
                                <CircularProgress
                                  className={classes.spinnerMargin}
                                  size={15}
                                  thickness={2.5}
                                />
                              )}
                            </Button>
                          </Grid>
                          {(isNew || hasNext) && (
                            <Grid item>
                              <Button
                                className={"secondary-button"}
                                style={{ width: 196 }}
                                onClick={this.shouldSubmit(true, !isNew)}
                                disabled={isSaving || total > MAX_PHOTO_LIMIT}
                                color={"secondary"}
                              >
                                <Typography
                                  color="inherit"
                                  variant="button"
                                  id={
                                    !evalItem || !evalItem.item
                                      ? "saveCreate.button"
                                      : "saveEditNext.button"
                                  }
                                />
                                {isSaving && doCreate && (
                                  <CircularProgress
                                    className={classes.spinnerMargin}
                                    size={15}
                                    thickness={2.5}
                                  />
                                )}
                              </Button>
                            </Grid>
                          )}
                        </Grid>
                      </Form>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            )}
          </Dropzone>
        </React.Fragment>
      </DocumentTitle>
    );
  }
}

export default withRouter(injectIntl(withStyles(styles)(NewItemDialog)));
