import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';

/**
 * The component to handle masked input (e.g. '(   )   -    ', for phone numbers).
 * @param props
 * @return {*}
 * @constructor
 */
export default class TextMaskCustom extends PureComponent {
   static propTypes = {
      inputRef: PropTypes.func.isRequired,
      ...MaskedInput.propTypes,
   };

   render() {
      const {inputRef, mask, showMask = true, ...other} = this.props;

      return (
         <MaskedInput
            {...other}
            ref={inputRef}
            mask={mask}
            placeholderChar={'\u2000'}
            showMask={showMask}
         />
      );
   }
}

