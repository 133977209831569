import React, { useState } from "react";
import { Auth } from "aws-amplify";
import Typography from '../../fhg/components/Typography';
import { makeStyles } from "@material-ui/core/styles";
import { FTInput } from "../inputs";
import Box from "@material-ui/core/Box";
import { FTButton } from "../buttons";

const useStyles = makeStyles((theme:any) => ({
    mainContainer:{
        width: '100%',
        height:'50%',
        backgroundColor: theme.palette.environment.light.level1.base,
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        justifyContent:'center',
        borderRadius:'8px'
    },
    success: {
        width: '100%',
        marginTop: '32px !important',
        color: theme.palette.button.minimal.success,
     },
     error: {
        width: '100%',
        marginTop: `&{theme.spacing(4)}px !important`,
     },
     buttonBox:{
        marginTop:'16px',
     },
     headingText:{
        marginBottom:'70px',
        textAlign:'center'
     }
}));

const FTSignInCodeForm: React.FC= () => {
    const classes = useStyles();
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [error, setError] = useState<string|null>();
    const [email, setEmail] = useState<string>("");

    const handleSubmit = async() => {
        try {
            setIsSaving(true);
            const cognitoUser = await Auth.signIn(email);
            window.location.reload();
         } catch (e) {
            setError(error);
            setIsSaving(false);
         }
     };

    return (
        <Box className={classes.mainContainer}>
            <form onSubmit={handleSubmit}>
            <Typography className={`${classes.headingText} level-2-heading`}
                                 id={'login.title'}/>
            <Typography className={`${classes.headingText} level-3-heading`} id={'signInEmail.title'}/>
                
                {error && (
                    <Typography className={classes.error} color={'error'} variant={'body1'} id={error}>
                       Error
                       Message.
                    </Typography>
                )}
                <FTInput 
                    label="signInEmail.email.label"
                    required
                    value={email}
                    onChange={(event)=>setEmail(event.target.value)}
                    disabled={isSaving}
                    name="email" 
                    type="email"           
                />
                <Box className={classes.buttonBox}>
                    <FTButton 
                        title={"signInEmail.email.button"} 
                        buttonAction={handleSubmit}   
                        disabled={isSaving}   
                    />
                </Box>
            </form>
        </Box>
    )
}

export default FTSignInCodeForm;