import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import MenuIcon from '@material-ui/icons/Menu';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import 'react-table/react-table.css';
import {CATALOG_ICON, SECONDARY_HEADER_HEIGHT} from '../../../Constants';
import Typography from '../../../fhg/components/Typography';
import AssetDetailDialog from '../components/AssetDetailDialog';

const useStyles = makeStyles(theme => ({
   banner: {
      width: '100%',

      '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
         display: '-ms-flexbox',
      },
      '@supports not (-ms-high-contrast: none)': {
         display: 'flex',
      },
      position: 'relative',
      backgroundColor: theme.palette.environment.light.level0.base,
      justifyContent: 'space-between',
      alignItems: 'center',
      height: SECONDARY_HEADER_HEIGHT,
      borderBottom: `1px solid ${theme.palette.environment.light.level2.accent}`
   },
   title: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
   },
   linkButton: {
      composes: 'no-print',
      zIndex: 1001,
      margin: theme.spacing(1),
      color: "#4f2682 !important", //'#5D788F',
      [theme.breakpoints.down('xs')]: {
         padding: theme.spacing(0.5, 0.5),
      }
   },
   linkLabel: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
   },
   middleSection: {
      position: 'absolute',
      transform: 'translateX(-50%)',
      left: '50%',
      '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
         top: 0,
      }
   },
   imageStyle: {
      '@supports not (-ms-high-contrast: none)': {
         mask: `url(${CATALOG_ICON}) no-repeat`,
         maskSize: 'cover',
         backgroundColor: "#4f2682 !important",
      },
      '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
         opacity: 0.5,
         background: `url(${CATALOG_ICON}) no-repeat`,
         backgroundSize: 'cover',
      },
      width: 14,
      height: 16,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
   }
}), {name: 'AssetNavigationHeaderStyles'});

/**
 * Navigation header for assets.
 */
export default function AssetNavigationHeader({zoomed, evaluation, selectedIndex, isNew, onPrevious, onBack, onNext, isDrawerOpen, onDrawerOpen, total}) {
   const classes = useStyles();
   const [showAssetDetail, setShowAssetDetail] = useState(false);

   const handleAssetDetailClick = (event) => {
      event.stopPropagation();
      event.preventDefault();
      setShowAssetDetail(event.currentTarget);
   };

   const closeAssetDetail = (event) => {
      setShowAssetDetail(null);
   };

   return (
      <Grid item className={classes.banner} style={{display: zoomed ? 'none' : undefined}}>
         <AssetDetailDialog open={!!showAssetDetail} evaluation={evaluation} onClose={closeAssetDetail}
                            anchorEl={showAssetDetail}/>
         <Button className={classes.linkButton} variant={'text'}
                 onClick={onBack}
                 disabled={!evaluation || !evaluation.uri}
                 disableRipple>
            <span className={classes.imageStyle}/>
            <Typography className={classes.linkLabel} variant='button' color={'inherit'}
                        id={'equipmentDetail.back.button'}/>
         </Button>
         <Hidden smUp>
            <Button className={classes.linkButton} variant={'text'}
                    onClick={handleAssetDetailClick}
                    disabled={!evaluation || !evaluation.uri}
                    disableRipple>
               <Typography className={classes.linkLabel} variant='button' color={'inherit'}
                           id={'equipmentDetail.assetDetails.button'}/>
            </Button>
         </Hidden>
         <Hidden mdUp>
            <IconButton color='inherit' aria-label='Open drawer'
                        style={{display: !isDrawerOpen ? undefined : 'none',}}
                        onClick={onDrawerOpen}>
               <MenuIcon/>
            </IconButton>
         </Hidden>
         <Hidden xsDown>
            <Grid container className={classes.middleSection} direction={'row'} wrap={'nowrap'}
                  style={{width: 'auto'}}>
               <Button className={classNames(classes.linkButton, classes.linkLabel)}
                       disabled={selectedIndex <= 0 || isNew} variant={'text'}
                       onClick={onPrevious} disableRipple={true}>
                  <ArrowLeftIcon/>
                  <Typography className={classes.linkLabel} variant='button' color={'inherit'}
                              id={'equipmentDetail.previous.button'}>
                     Previous Asset
                  </Typography>
               </Button>

               <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  {!isNew &&
                  <Typography className={classNames(classes.title, 'contrast-text-small')}
                              id={'equipmentDetail.main.title'}
                              values={{
                                 index: selectedIndex + 1,
                                 total,
                              }}/>}
               </div>
               <Button className={classNames(classes.linkButton, classes.linkLabel)}
                       disabled={selectedIndex >= total - 1 || isNew} variant={'text'}
                       onClick={onNext} disableRipple={true}>
                  <Typography className={classes.linkLabel} variant='button' color={'inherit'}
                              id={'equipmentDetail.next.button'}>
                     Next Item
                  </Typography>
                  <ArrowRightIcon/>
               </Button>
            </Grid>
         </Hidden>
      </Grid>
   );
}

AssetNavigationHeader.propTypes = {
   zoomed: PropTypes.any,
   onBack: PropTypes.func,
   evaluation: PropTypes.any,
   selectedIndex: PropTypes.any,
   isNew: PropTypes.any,
   onPrevious: PropTypes.func,
   item: PropTypes.shape({}),
   total: PropTypes.any,
   onNext: PropTypes.func,
   isDrawerOpen: PropTypes.bool,
   onDrawerOpen: PropTypes.func,
};

