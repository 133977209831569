import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import CheckEmailPanel from './CheckEmailPanel';
import EmailPanel from './EmailPanel';
import SignInOptionsPanel from './SignInOptionsPanel';
import SignInPanel from './SignInPanel';
import get from 'lodash/get';
import { posthogCapture } from '../../PostHogFunction';
import eventCapture from '../../eventList';
import { MainAppBar } from '../MainAppBar';

export const OPTIONS_STEP = 'options';
export const EMAIL_STEP = 'email';
const CHECK_STEP = 'symbolConfirm';
export const SIGN_IN_STEP = 'signIn';

const useStyles = makeStyles((theme:any) => ({
   root: {
      height: '100vh',
      backgroundColor: theme.palette.brand.gray,
   },
}));

interface MySignInProps {
   authState: string;
   authData?: any;
   hide?: any[];
   onAuthEvent?: (state: string, event: any) => void;
   onStateChange?: (state: string, data?: any) => void;
}

const MySignIn: React.FC<MySignInProps> = ({ authState, authData, hide, onAuthEvent, onStateChange }) => {
   const classes = useStyles();
   const history = useHistory();
   const location = useLocation();
   const [email, setEmail] = useState<string>('');
   const [signInTime] = useState<number>(Date.now());
   const [cognitoUser, setCognitoUser] = useState<any>(null); 

   useEffect(() => {
      document.title = `${process.env.REACT_APP_SITE_TITLE} Sign In`; 
   }, []);

   useEffect(() => {
      const signInTimeFromLocation = get(location, 'state.signInTime');
      const stepFromLocation = get(location, 'state.step', OPTIONS_STEP);
      if (stepFromLocation !== OPTIONS_STEP && signInTimeFromLocation !== signInTime) {
         history.replace(location.pathname, { step: undefined });
      }
   }, [location, history, signInTime]);

   const onResetPassword = () => {
      if (onStateChange) {
         onStateChange('forgotPassword');
      }
      posthogCapture(eventCapture.PASSWORD_RESET);
   };

   const triggerAuthEvent = (event: any) => {
      const state = authState;

      if (onAuthEvent) {
         onAuthEvent(state, event);
      }
   };

   const optionsSubmit = (option: string) => {
      history.push(location.pathname, { step: option, signInTime });
   };

   const handleManualSignin = () => {
      history.replace(location.pathname, { step: SIGN_IN_STEP, signInTime });
   };

   const handleEmailSignin = () => {
      history.replace(location.pathname, { step: EMAIL_STEP, signInTime });
   };

   const emailSubmit = (email: string, cognitoUser: any) => {
      history.push(location.pathname, { step: CHECK_STEP, signInTime });
      setEmail(email);
      setCognitoUser(cognitoUser);
   };

   const symbolSubmit = async (code: string) => {
      if (code && cognitoUser) {
         try {
            await Auth.sendCustomChallengeAnswer(cognitoUser, code);
            await Auth.currentAuthenticatedUser();
            window.location.reload();
         } catch (e) {
            console.log(e);
            throw e;
         }
      } else {
         console.log('Invalid authentication');
         throw new Error('Invalid authentication');
      }
   };

   if (authState !== 'signIn') {
      return null;
   }

   if (hide && hide.includes(MySignIn)) {
      return null;
   }

   const stepFromLocation = get(location, 'state.step', OPTIONS_STEP);

   const showSuccess = authData && authData.showSuccess;
   const title = `${process.env.REACT_APP_SITE_TITLE} Sign In`;

   return (
      <div title={title}>
         <Grid container direction={'column'} className={classes.root} wrap={'nowrap'}>
            <MainAppBar />
            {{
               [OPTIONS_STEP]: <SignInOptionsPanel onSubmit={optionsSubmit} />,
               [EMAIL_STEP]: <EmailPanel onManualSignIn={handleManualSignin} />,
               [CHECK_STEP]: (
                  <CheckEmailPanel
                     email={email}
                     onSubmit={symbolSubmit}
                     onManualSignIn={handleManualSignin}
                     onSubmitEmail={emailSubmit}
                  />
               ),
               [SIGN_IN_STEP]: (
                  <SignInPanel
                     showSuccess={showSuccess}
                     onResetPassword={onResetPassword}
                     onEmailSignIn={handleEmailSignin}
                  />
               ),
            }[stepFromLocation]}
         </Grid>
      </div>
   );
};

export default MySignIn;
